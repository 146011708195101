import * as yup from 'yup'
import {
  maxString200Message,
  maxString200Value,
  requiredFieldMessage
} from 'constantValues'
import { AddressTypeDto } from './addressType'

type AddressesWithAddressType = {}

export class AddressDto {
  id?: string = undefined
  // addressTypeId: string = ''
  address1: string = '' // TODO:: Should be required...
  address2?: string = undefined
  address3?: string = undefined
  city: string = ''
  state: string = ''
  postalCode: string = ''
  country: string = ''
  email?: string = undefined
  phone?: string = undefined
  notes?: string = undefined
  isActive: boolean = true

  addressType?: AddressTypeDto = undefined

  constructor(model?: any) {}
}

export const addressValidationSchema = yup.object({
  addressType: yup.object().required(requiredFieldMessage),
  address1: yup
    .string()
    .max(maxString200Value, maxString200Message)
    .required(requiredFieldMessage),
  address2: yup.string().max(maxString200Value, maxString200Message).nullable(),
  address3: yup.string().max(maxString200Value, maxString200Message).nullable(),
  city: yup
    .string()
    .max(maxString200Value, maxString200Message)
    .required(requiredFieldMessage),
  state: yup
    .string()
    .max(maxString200Value, maxString200Message)
    .required(requiredFieldMessage),
  postalCode: yup
    .string()
    .max(maxString200Value, maxString200Message)
    .required(requiredFieldMessage),
  country: yup
    .string()
    .max(maxString200Value, maxString200Message)
    .required(requiredFieldMessage),
  email: yup.string().max(maxString200Value, maxString200Message).nullable(),
  phone: yup.string().max(maxString200Value, maxString200Message).nullable(),
  notes: yup.string().max(maxString200Value, maxString200Message).nullable(),
  isActive: yup.boolean()
})
