import { ReadMoreOutlined, SearchOutlined } from '@mui/icons-material'
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material'
import * as api from 'api'
import { Layout } from 'components/_template'
import { ExplanationAccordion } from 'components/_template/accordion'
import { List, ListHeaderCard, ListItemCard } from 'components/_template/list'
import { VendorOrderDto } from 'dtos'
import { RawInventoryParameters } from 'parameters'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDebounce } from 'utils'
import { errorHandling } from 'constantValues'

const sessionStorageKey = 'rawInventory:parameters'

export default function RawInventory() {
  const navigate = useNavigate()

  const defaultParameters: RawInventoryParameters = {
    page: 0,
    pageSize: 10,
    search: ''
  }

  const [count, setCount] = useState<number>(0)
  const [isRawInventoryLoading, setIsRawInventoryLoading] = useState<boolean>(false)
  const [parameters, setParameters] = useState<RawInventoryParameters>(
    sessionStorage.getItem(sessionStorageKey)
      ? JSON.parse(sessionStorage.getItem(sessionStorageKey)!)
      : defaultParameters
  )
  const [vendorOrders, setVendorOrders] = useState<VendorOrderDto[]>([])

  const getRawInventory = useDebounce((parameters: RawInventoryParameters) => {
    setIsRawInventoryLoading(true)
    sessionStorage.setItem(sessionStorageKey, JSON.stringify(parameters))
    api
      .getRawInventory(parameters)
      .then(res => {
        setVendorOrders(res.value)
        setCount(res.totalCount!)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsRawInventoryLoading(false)
      })
  }, 300)

  useEffect(() => {
    getRawInventory(parameters)
  }, [parameters])

  return (
    <Layout title='Raw Inventory' isLoading={isRawInventoryLoading}>
      <List
        pagination={{
          page: parameters.page,
          pageSize: parameters.pageSize,
          count,
          onPageChange: page => setParameters({ ...parameters, page }),
          onPageSizeChange: pageSize => setParameters({ ...parameters, pageSize })
        }}
        header={<ListHeaderCard title='Raw Inventory' actions={[]} />}
      >
        <ListItemCard>
          <Grid container spacing={2} alignItems='center' justifyContent='space-between'>
            <Grid item xs={12} sm={3}>
              <TextField
                data-search
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <SearchOutlined fontSize='small' />
                    </InputAdornment>
                  )
                }}
                label='Search'
                onChange={e => {
                  setParameters({ ...parameters, search: e.target.value })
                }}
                size='small'
                type='search'
                value={parameters.search}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Button
                color='primary'
                size='medium'
                variant='text'
                onClick={() => {
                  setParameters(defaultParameters)
                }}
              >
                RESET FILTERS
              </Button>
            </Grid>
          </Grid>
        </ListItemCard>

        {vendorOrders.map((vendorOrder, index) => (
          <ListItemCard
            key={vendorOrder.id}
            title={vendorOrder?.company?.name}
            actions={[
              <IconButton
                onClick={() => navigate(`/raw-inventory/${vendorOrder.id}`)}
                color='primary'
              >
                <ReadMoreOutlined fontSize='large' />
              </IconButton>
            ]}
          >
            <Typography variant='body2' sx={{ color: '#7F7F7F', fontWeight: 600 }}>
              Vendor Item #: {vendorOrder?.vendorItemNumber}
            </Typography>
          </ListItemCard>
        ))}

        <Grid item xs={12}>
          <ExplanationAccordion>
            Search checks if the search term is contained in any of the individual data
            values shown on the list card. It does not enable searching by labels, chips,
            or special characters.||A card is shown for each vendor order with received
            pallets (pallets that have been scanned in Receving).||Cards are listed in
            order by Company Name then by Vendor Item #.
          </ExplanationAccordion>
        </Grid>
      </List>
    </Layout>
  )
}
