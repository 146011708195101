import { Card, CardContent, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import { DetailsTab } from 'components/_template/details'
import { RatesEndCostAdderDto } from 'dtos'
import { format, formats } from 'theme'

interface RatesEndCostAddersProps {
  ratesEndCostAddersList: RatesEndCostAdderDto[] | undefined
}

export default function RatesEndCostAddersTab({
  ratesEndCostAddersList
}: RatesEndCostAddersProps) {
  return (
    <DetailsTab value='tab7'>
      <Grid container spacing={2}>
        {ratesEndCostAddersList?.map(ratesEndCostAdder => (
          <Grid item xs={12}>
            <Card variant='outlined'>
              <CardContent>
                <Typography variant='body1' sx={{ color: '#2780E3', fontWeight: 600 }}>
                  {ratesEndCostAdder.adder}
                </Typography>
                <Grid container spacing={2} alignItems='center'>
                  <Grid item xs={12} sm={6}>
                    <Typography>
                      Adder Setup:{' '}
                      {format(ratesEndCostAdder?.adderSetup, formats.currency)}
                    </Typography>
                  </Grid>
                  {ratesEndCostAdder?.adderPerMsf && (
                    <Grid item xs={12} sm={6}>
                      <Typography>
                        Adder Per MSF:{' '}
                        {format(ratesEndCostAdder?.adderPerMsf, formats.currency)}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body1'>{ratesEndCostAdder.description}</Typography>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </DetailsTab>
  )
}
