import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField
} from '@mui/material'
import { ExplanationAccordion } from 'components/_template/accordion'
import { showFormErrorsPrompt } from 'components/_template/form/FormErrorsPrompt'
import { BandingDto, bandingScanBarcodeValidationSchema } from 'dtos'
import { Formik, setNestedObjectValues } from 'formik'
import { useRef } from 'react'
import { IntFormat } from './NumberFields'

interface BandingScanBarcodeDialogProps {
  onClose: () => void
  open: boolean
  onSave: (values: BandingDto) => void
}

export default function BandingScanBarcodeDialog({
  onClose,
  open,
  onSave
}: BandingScanBarcodeDialogProps) {
  // These refs need to be cast to type any because TypeScript doesn't like them being initialized to null
  const jobNumberRef: any = useRef(null)
  const bundleNumberRef: any = useRef(null)
  const quantityRef: any = useRef(null)

  const onFocusJobNumber = () => {
    jobNumberRef.current.focus()
  }
  const onFocusBundleNumber = () => {
    bundleNumberRef.current.focus()
  }
  const onFocusQuantity = () => {
    quantityRef.current.focus()
  }

  return (
    // disableRestoreFocus seems necessary for the autofocus prop to function. See https://github.com/mui/material-ui/issues/33004#issuecomment-1455260156
    <Dialog open={open} disableRestoreFocus>
      <Formik
        enableReinitialize
        initialValues={new BandingDto()}
        validateOnBlur
        validateOnChange
        validationSchema={bandingScanBarcodeValidationSchema}
        onSubmit={values => {
          onSave(values)
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setTouched,
          submitForm,
          touched,
          validateForm,
          values
        }) => {
          const onSubmitForm = () => {
            // Due to an issue with Formik, the form fields need to be manually set as touched when there are errors on submit.
            // Otherwise, sometimes the field errors won't display even though the validation throws them
            validateForm().then(errors => {
              const errorKeys = Object.keys(errors)
              if (errorKeys.length === 0) {
                submitForm()
              } else {
                setTouched(setNestedObjectValues(errors, true))
                showFormErrorsPrompt(errors)
              }
            })
          }

          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2} alignItems='center'>
                  <DialogTitle>
                    Scan Barcodes (Or Key In Values) For Each Bundle
                  </DialogTitle>

                  <Grid item xs={12}>
                    <TextField
                      autoFocus
                      error={Boolean(touched.jobNumber && errors.jobNumber)}
                      fullWidth
                      helperText={touched.jobNumber && errors.jobNumber}
                      label='Job #'
                      name='jobNumber'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      InputProps={{
                        inputComponent: IntFormat as any
                      }}
                      value={values.jobNumber + ''}
                      inputRef={jobNumberRef}
                      onKeyUp={e => {
                        if (e.key === 'Enter') {
                          onSubmitForm()
                        }
                      }}
                      inputProps={{ tabIndex: 1 }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(touched.bundleNumber && errors.bundleNumber)}
                      fullWidth
                      helperText={touched.bundleNumber && errors.bundleNumber}
                      label='Bundle #'
                      name='bundleNumber'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      InputProps={{
                        inputComponent: IntFormat as any
                      }}
                      value={values.bundleNumber + ''}
                      inputRef={bundleNumberRef}
                      onKeyUp={e => {
                        if (e.key === 'Enter') {
                          onSubmitForm()
                        }
                      }}
                      inputProps={{ tabIndex: 2 }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(touched.quantity && errors.quantity)}
                      fullWidth
                      helperText={touched.quantity && errors.quantity}
                      label='Quantity'
                      name='quantity'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      InputProps={{
                        inputComponent: IntFormat as any
                      }}
                      value={values.quantity + ''}
                      inputRef={quantityRef}
                      onKeyUp={e => {
                        if (e.key === 'Enter') {
                          onSubmitForm()
                        }
                      }}
                      inputProps={{ tabIndex: 3 }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ExplanationAccordion>
                      Clicking "SUBMIT" creates a new Finished Goods Inventory record. The
                      new record is associated with the job matching the entered Job #,
                      has the pallet location Bander and has the entered Bundle # and
                      quantity.||Based on the scan the forklift operator is notified to
                      pick up the banded bundle.||If the Job # and Bundle # combination
                      has already been scanned at banding, a warning will be displayed
                      saying the Bundle already exists and cannot be scanned by banding
                      again.
                    </ExplanationAccordion>
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions sx={{ mb: 2, mr: 2 }}>
                <Button
                  disabled={isSubmitting}
                  color='secondary'
                  onClick={() => {
                    onClose()
                  }}
                  tabIndex={5}
                  variant='text'
                >
                  CLOSE
                </Button>

                <Button
                  disabled={isSubmitting}
                  onClick={() => {
                    onSubmitForm()
                  }}
                  tabIndex={4}
                  variant='contained'
                >
                  SUBMIT
                </Button>
              </DialogActions>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}
