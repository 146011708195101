import { Visibility, VisibilityOff } from '@mui/icons-material'
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Link as MuiLink,
  TextField,
  Typography
} from '@mui/material'
import { Copyright } from 'components'
import { GuestLayout } from 'components/_template'
import { showFormErrorsPrompt } from 'components/_template/form/FormErrorsPrompt'
import { useAuthContext } from 'context'
import { SignInDto, signInValidationSchema } from 'dtos'
import { Formik, setNestedObjectValues } from 'formik'
import { useEffect, useState } from 'react'
import { NavLink, Navigate, useLocation } from 'react-router-dom'

export default function SignIn() {
  const { signIn, user, status } = useAuthContext()
  const location = useLocation()

  const [showPassword, setShowPassword] = useState<boolean>(false)

  useEffect(() => {
    document.title = 'Sign In | Spud ERP'
  }, [])

  return (
    <>
      {user && status === 'authorized' && (
        <Navigate to={location.state?.from ?? user.landingPage ?? '/'} />
      )}

      {status === 'loading' && (
        <Grid
          container
          spacing={2}
          direction='column'
          alignItems='center'
          justifyContent='center'
          style={{ minHeight: '100vh' }}
        >
          <Grid item>
            <Typography variant='h4' fontWeight={600} display='block'>
              Spud ERP
            </Typography>
          </Grid>

          <Grid item>
            <CircularProgress size={40} />
          </Grid>
        </Grid>
      )}

      {status === 'unauthorized' && (
        <Formik
          enableReinitialize
          initialValues={new SignInDto()}
          validateOnChange
          validateOnBlur
          validationSchema={signInValidationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            const { emailOrUsername, passwordOrPin } = values
            await signIn({ emailOrUsername, passwordOrPin })
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            setTouched,
            submitForm,
            touched,
            validateForm,
            values
          }) => {
            return (
              <GuestLayout handleSubmit={handleSubmit} title='Welcome to Spud ERP'>
                <TextField
                  autoComplete='sign-in_emailOrUsername'
                  autoFocus
                  disabled={isSubmitting}
                  error={Boolean(touched.emailOrUsername && errors.emailOrUsername)}
                  fullWidth
                  helperText={touched.emailOrUsername && errors.emailOrUsername}
                  InputLabelProps={{
                    shrink: true
                  }}
                  label='Email/Username'
                  margin='normal'
                  name='emailOrUsername'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.emailOrUsername || ''}
                />

                <TextField
                  disabled={isSubmitting}
                  error={Boolean(touched.passwordOrPin && errors.passwordOrPin)}
                  fullWidth
                  helperText={touched.passwordOrPin && errors.passwordOrPin}
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={_ => setShowPassword(!showPassword)}
                          onMouseDown={e => e.preventDefault()}
                          edge='end'
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  label='Password/Sign-In PIN'
                  margin='normal'
                  name='passwordOrPin'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type={showPassword ? 'text' : 'password'}
                  value={values.passwordOrPin || ''}
                />

                <Button
                  disabled={isSubmitting}
                  fullWidth
                  onClick={() => {
                    // Due to an issue with Formik, the form fields need to be manually set as touched when there are errors on submit.
                    // Otherwise, sometimes the field errors won't display even though the validation throws them
                    validateForm().then(errors => {
                      const errorKeys = Object.keys(errors)
                      if (errorKeys.length === 0) {
                        submitForm()
                      } else {
                        setTouched(setNestedObjectValues(errors, true))
                        showFormErrorsPrompt(errors)
                      }
                    })
                  }}
                  variant='contained'
                >
                  SIGN IN
                </Button>

                <Typography align='center'>
                  <MuiLink
                    component={NavLink}
                    sx={{ textDecoration: 'none' }}
                    to='/account/forgot-password'
                  >
                    Forgot password?
                  </MuiLink>
                </Typography>

                <Copyright sx={{ mt: 5 }} />
              </GuestLayout>
            )
          }}
        </Formik>
      )}
    </>
  )
}
