import { Button, Grid, Tooltip } from '@mui/material'
import * as api from 'api'
import { Layout } from 'components/_template'
import { ExplanationAccordion } from 'components/_template/accordion'
import { List, ListHeaderCard } from 'components/_template/list'
import { useState } from 'react'

import { BandingScanBarcodeDialog } from 'components'
import { BandingDto } from 'dtos'
import { enqueueSnackbar } from 'notistack'
import { errorHandling } from 'constantValues'

export default function Banding() {
  const [bandingScanBarcodeDialogOpen, setBandingScanBarcodeDialogOpen] =
    useState<boolean>(false)
  const [isBandingLoading, setIsBandingLoading] = useState<boolean>(false)

  const onSaveBandingScanBarcodeDialog = async (banding: BandingDto) => {
    setIsBandingLoading(true)
    api
      .createFinishedGoodsFromBanding(banding)
      .then(() => {
        enqueueSnackbar('Finished Goods Created Successfully!', {
          variant: 'success'
        })
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsBandingLoading(false)
        setBandingScanBarcodeDialogOpen(false)
      })
  }

  return (
    <Layout title='Banding' isLoading={isBandingLoading}>
      <BandingScanBarcodeDialog
        open={bandingScanBarcodeDialogOpen}
        onClose={() => {
          setBandingScanBarcodeDialogOpen(false)
        }}
        onSave={savedBandingBarcode => {
          onSaveBandingScanBarcodeDialog(savedBandingBarcode)
        }}
      />

      <List
        header={
          <ListHeaderCard
            title='Banding'
            actions={[
              <Tooltip title='Add' placement='bottom'>
                <Button
                  variant='contained'
                  onClick={() => {
                    setBandingScanBarcodeDialogOpen(true)
                  }}
                >
                  Scan Barcode
                </Button>
              </Tooltip>
            ]}
          />
        }
      >
        <Grid item xs={12}>
          <ExplanationAccordion>
            The Banding page is currently only used for scanning travelers to create
            Finished Goods records. There are intentionally no list cards shown here.
          </ExplanationAccordion>
        </Grid>
      </List>
    </Layout>
  )
}
