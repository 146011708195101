import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField
} from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import { ExplanationAccordion } from 'components/_template/accordion'
import { JobStatusDto, JobStopReasonTypeDto, jobStatusValidationSchema } from 'dtos'
import { Formik, setNestedObjectValues } from 'formik'
import { IntFormat } from './NumberFields'
import { showFormErrorsPrompt } from './_template/form/FormErrorsPrompt'

interface JobCenter66RdcStopDialogProps {
  initialValues: JobStatusDto
  jobStopReasonTypes: JobStopReasonTypeDto[]
  onClose: () => void
  onSave: (values: JobStatusDto) => void
  open: boolean
}

export default function JobCenter66RdcStopDialog({
  initialValues,
  jobStopReasonTypes,
  onClose,
  onSave,
  open
}: JobCenter66RdcStopDialogProps) {
  return (
    <Dialog open={open}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validateOnBlur
        validateOnChange
        validationSchema={jobStatusValidationSchema}
        onSubmit={values => {
          onSave(values)
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setTouched,
          submitForm,
          touched,
          validateForm,
          values
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2} alignItems='center'>
                  <DialogTitle>Reason for Stopping</DialogTitle>
                  <Grid item xs={12}>
                    <TextField
                      disabled={isSubmitting}
                      error={Boolean(
                        touched.jobStopReasonType && errors.jobStopReasonType
                      )}
                      fullWidth
                      helperText={touched.jobStopReasonType && errors.jobStopReasonType}
                      label='Reason For Stopping'
                      name='jobStopReasonType'
                      onBlur={handleBlur}
                      onChange={e => {
                        setFieldValue('jobStopReasonType', JSON.parse(e.target.value))
                      }}
                      select
                      value={
                        values?.jobStopReasonType
                          ? JSON.stringify(
                              jobStopReasonTypes.filter(
                                jobStopReasonType =>
                                  jobStopReasonType.id === values.jobStopReasonType!.id
                              )[0]
                            )
                          : ''
                      }
                    >
                      {jobStopReasonTypes.map(jobStopReasonType => (
                        <MenuItem
                          key={jobStopReasonType.id}
                          value={JSON.stringify(jobStopReasonType)}
                        >
                          {jobStopReasonType.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={isSubmitting}
                      error={Boolean(touched.note && errors.note)}
                      fullWidth
                      helperText={touched.note && errors.note}
                      label='Note'
                      name='note'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.note || ''}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={isSubmitting}
                      error={Boolean(
                        touched.lastBandingQuantity && errors.lastBandingQuantity
                      )}
                      fullWidth
                      helperText={
                        touched.lastBandingQuantity && errors.lastBandingQuantity
                      }
                      label='Last Banding Quantity'
                      name='lastBandingQuantity'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      InputProps={{
                        inputComponent: IntFormat as any
                      }}
                      value={values.lastBandingQuantity + ''}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ExplanationAccordion>
                      Generates a PDF of the final traveler with quantity entered by user.
                    </ExplanationAccordion>
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions sx={{ mb: 2, mr: 2 }}>
                <Button variant='text' color='secondary' onClick={onClose}>
                  CLOSE
                </Button>

                <Button
                  onClick={() => {
                    // Due to an issue with Formik, the form fields need to be manually set as touched when there are errors on submit.
                    // Otherwise, sometimes the field errors won't display even though the validation throws them
                    validateForm().then(errors => {
                      const errorKeys = Object.keys(errors)
                      if (errorKeys.length === 0) {
                        submitForm()
                      } else {
                        setTouched(setNestedObjectValues(errors, true))
                        showFormErrorsPrompt(errors)
                      }
                    })
                  }}
                  variant='contained'
                >
                  SAVE
                </Button>
              </DialogActions>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}
