import * as yup from 'yup'
import {
  fieldTypeMessage,
  maxIntMessage,
  maxIntValue,
  maxString200Message,
  maxString200Value,
  minIntMessage,
  minIntValue,
  requiredFieldMessage
} from 'constantValues'
import { ForkliftPalletLocationDto } from './forkliftPalletLocation'

// Formik and Yup do not behave correctly when the fields are initialized to a value other than `undefined`
// so mark every non-boolean Dto property referenced in the validation as optional, initialized to undefined,
// and use Yup and the back-end logic for validation
export class ForkliftToDoMoveFinishedGoodsInventoryDto {
  itemNumber?: string = undefined
  isAdditionalInformationAvailable?: boolean = true
  jobNumber?: string = undefined
  bundleNumber?: string = undefined
  quantity?: number = undefined
  dropOffForkliftPalletLocation?: ForkliftPalletLocationDto = undefined
}

export const forkliftToDoCheckMoveFinishedGoodsInventoryValidationSchema =
  yup.object<ForkliftToDoMoveFinishedGoodsInventoryDto>({
    itemNumber: yup
      .string()
      .required(requiredFieldMessage)
      .max(maxString200Value, maxString200Message),
    jobNumber: yup
      .number()
      .integer(fieldTypeMessage('Whole Number'))
      .typeError(fieldTypeMessage('Whole Number'))
      .min(minIntValue, minIntMessage)
      .max(maxIntValue, maxIntMessage)
      .when(['isAdditionalInformationAvailable'], (values, schema) => {
        if (values[0]) {
          return schema.required(requiredFieldMessage)
        } else {
          return schema.nullable()
        }
      }),
    bundleNumber: yup
      .number()
      .integer(fieldTypeMessage('Whole Number'))
      .typeError(fieldTypeMessage('Whole Number'))
      .min(minIntValue, minIntMessage)
      .max(maxIntValue, maxIntMessage)
      .when(['isAdditionalInformationAvailable'], (values, schema) => {
        if (values[0]) {
          return schema.required(requiredFieldMessage)
        } else {
          return schema.nullable()
        }
      }),
    dropOffForkliftPalletLocation: yup
      .object()
      .typeError(fieldTypeMessage('Pallet Location'))
      .when(['isAdditionalInformationAvailable'], (values, schema) => {
        if (values[0]) {
          return schema.required(requiredFieldMessage)
        } else {
          return schema.nullable()
        }
      })
  })

export const forkliftToDoMoveFinishedGoodsInventoryValidationSchema =
  yup.object<ForkliftToDoMoveFinishedGoodsInventoryDto>({
    itemNumber: yup
      .string()
      .required(requiredFieldMessage)
      .max(maxString200Value, maxString200Message),
    quantity: yup
      .number()
      .integer(fieldTypeMessage('Whole Number'))
      .typeError(fieldTypeMessage('Whole Number'))
      .required(requiredFieldMessage)
      .min(minIntValue, minIntMessage)
      .max(maxIntValue, maxIntMessage),
    dropOffForkliftPalletLocation: yup
      .object()
      .typeError(fieldTypeMessage('Pallet Location'))
      .required(requiredFieldMessage)
  })
