import { LoadingButton } from '@mui/lab'
import {
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import apiClient from 'api'
import { AddressAddEditDialog, UnsavedChangesPrompt } from 'components'
import { Layout } from 'components/_template'
import {
  Details,
  DetailsActions,
  DetailsForm,
  DetailsHeaderCard,
  DetailsTab
} from 'components/_template/details'
import { showFormErrorsPrompt } from 'components/_template/form/FormErrorsPrompt'
import { ListItemCard } from 'components/_template/list'
import { useAuthContext } from 'context'
import {
  AddressDto,
  AddressTypeDto,
  ApiResponse,
  CompanyDto,
  companyValidationSchema
} from 'dtos'
import { Formik, setNestedObjectValues } from 'formik'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { errorHandling } from 'constantValues'

export default function CompanyDetails() {
  // #region hooks
  const { enqueueSnackbar } = useSnackbar()
  const { COMPANIES_AND_PEOPLE_ADD_EDIT, COMPANY_CUSTOMER_VENDOR_NUMBER_ADD_EDIT } =
    useAuthContext()
  const navigate = useNavigate()
  const location = useLocation()
  const { id } = useParams()

  // #endregion

  // #region useState
  const [activeAddressesOnly, setActiveAddressesOnly] = useState<boolean>(true)
  const [address, setAddress] = useState<AddressDto>(new AddressDto())
  const [addressDialogOpen, setAddressDialogOpen] = useState<boolean>(false)
  const [addressTypes, setAddressTypes] = useState<AddressTypeDto[]>([])
  const [initialValues, setInitialValues] = useState<CompanyDto>(new CompanyDto())
  const [isGettingCompany, setIsGettingCompany] = useState<boolean>(false)
  // #endregion

  // #region useEffect
  useEffect(() => {
    apiClient
      .get<ApiResponse<AddressTypeDto[]>>('/api/address-types')
      .then(({ data: { value } }) => {
        setAddressTypes(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
  }, [])

  useEffect(() => {
    if (id && id.toLowerCase() !== 'new' && id.toLowerCase() !== 'undefined') {
      setIsGettingCompany(true)
      apiClient
        .get<ApiResponse<CompanyDto>>('/api/companies/' + id)
        .then(({ data: { value } }) => {
          setInitialValues(value)
        })
        .catch((errors: string[]) => {
          errorHandling(errors)
        })
        .finally(() => {
          setIsGettingCompany(false)
        })
    } else {
      setInitialValues(new CompanyDto())
    }
  }, [id])
  // #endregion

  return (
    <Layout title='Company Details' isLoading={isGettingCompany}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validateOnBlur
        validateOnChange
        validationSchema={companyValidationSchema}
        onSubmit={async (values, submitProps) => {
          await (values.id ? apiClient.put : apiClient.post)('/api/companies', values)
            .then(res => {
              enqueueSnackbar(`${values.name} has been saved!`, {
                variant: 'success'
              })

              setInitialValues(res.data.value)
              submitProps.resetForm({ values: res.data.value })

              if (!values.id) {
                setTimeout(() => {
                  navigate('/companies/' + res.data.value.id, { replace: true })
                }, 100)
              }
            })
            .catch((errors: string[]) => {
              errorHandling(errors)
            })
        }}
      >
        {({
          dirty,
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setTouched,
          submitForm,
          touched,
          validateForm,
          values
        }) => {
          let detailsTabErrors = Boolean(
            errors.name ||
              errors.customerNumber ||
              errors.vendorNumber ||
              errors.website ||
              errors.howTheyFoundUs ||
              errors.notes ||
              errors.industry ||
              errors.phone ||
              errors.logoFileName ||
              errors.isActive
          )
          let addressesTabErrors = Boolean(errors.isActive)
          return (
            <>
              <AddressAddEditDialog
                address={address}
                addressTypes={addressTypes}
                onClose={() => setAddressDialogOpen(false)}
                onSave={(savedAddress: any) => {
                  // Edit
                  if (values.addresses?.indexOf(address) > -1) {
                    setFieldValue('addresses', [
                      ...values.addresses.filter(
                        (_, index) => index !== values.addresses.indexOf(address)
                      ),
                      savedAddress
                    ])
                  }
                  // Add
                  else {
                    setFieldValue('addresses', [...values.addresses, savedAddress])
                  }

                  setAddressDialogOpen(false)
                }}
                open={addressDialogOpen}
              />

              <Details
                header={<DetailsHeaderCard title='Company Details' />}
                tabs={[
                  { value: 'details', label: 'DETAILS', error: detailsTabErrors },
                  { value: 'addresses', label: 'ADDRESSES', error: addressesTabErrors }
                ]}
                onSubmit={handleSubmit}
              >
                <DetailsTab value='details'>
                  <DetailsForm>
                    <Grid container spacing={2} alignItems='center'>
                      <Grid item xs={12}>
                        <Tooltip
                          title={
                            COMPANIES_AND_PEOPLE_ADD_EDIT
                              ? ''
                              : 'Must have "Companies and people add/edit" permission'
                          }
                          placement='bottom'
                        >
                          <TextField
                            disabled={isSubmitting || !COMPANIES_AND_PEOPLE_ADD_EDIT}
                            error={Boolean(touched.name && errors.name)}
                            fullWidth
                            helperText={touched.name && errors.name}
                            label='Company Name'
                            name='name'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.name || ''}
                          />
                        </Tooltip>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Tooltip
                          title={
                            COMPANY_CUSTOMER_VENDOR_NUMBER_ADD_EDIT
                              ? ''
                              : 'Must have "Company customer vendor # add / edit" permission'
                          }
                          placement='bottom'
                        >
                          <TextField
                            disabled={
                              isSubmitting || !COMPANY_CUSTOMER_VENDOR_NUMBER_ADD_EDIT
                            }
                            error={Boolean(
                              touched.customerNumber && errors.customerNumber
                            )}
                            fullWidth
                            helperText={touched.customerNumber && errors.customerNumber}
                            label='Customer #'
                            name='customerNumber'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.customerNumber || ''}
                          />
                        </Tooltip>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Tooltip
                          title={
                            COMPANY_CUSTOMER_VENDOR_NUMBER_ADD_EDIT
                              ? ''
                              : 'Must have "Company customer vendor # add / edit" permission'
                          }
                          placement='bottom'
                        >
                          <TextField
                            disabled={
                              isSubmitting || !COMPANY_CUSTOMER_VENDOR_NUMBER_ADD_EDIT
                            }
                            error={Boolean(touched.vendorNumber && errors.vendorNumber)}
                            fullWidth
                            helperText={touched.vendorNumber && errors.vendorNumber}
                            label='Vendor #'
                            name='vendorNumber'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.vendorNumber || ''}
                          />
                        </Tooltip>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Tooltip
                          title={
                            COMPANIES_AND_PEOPLE_ADD_EDIT
                              ? ''
                              : 'Must have "Companies and people add/edit" permission'
                          }
                          placement='bottom'
                        >
                          <TextField
                            disabled={isSubmitting || !COMPANIES_AND_PEOPLE_ADD_EDIT}
                            error={Boolean(touched.website && errors.website)}
                            fullWidth
                            helperText={touched.website && errors.website}
                            label='Website'
                            name='website'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.website || ''}
                          />
                        </Tooltip>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Tooltip
                          title={
                            COMPANIES_AND_PEOPLE_ADD_EDIT
                              ? ''
                              : 'Must have "Companies and people add/edit" permission'
                          }
                          placement='bottom'
                        >
                          <TextField
                            disabled={isSubmitting || !COMPANIES_AND_PEOPLE_ADD_EDIT}
                            error={Boolean(
                              touched.howTheyFoundUs && errors.howTheyFoundUs
                            )}
                            fullWidth
                            helperText={touched.howTheyFoundUs && errors.howTheyFoundUs}
                            label='How they heard about us'
                            name='howTheyFoundUs'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.howTheyFoundUs || ''}
                          />
                        </Tooltip>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Tooltip
                          title={
                            COMPANIES_AND_PEOPLE_ADD_EDIT
                              ? ''
                              : 'Must have "Companies and people add/edit" permission'
                          }
                          placement='bottom'
                        >
                          <TextField
                            disabled={isSubmitting || !COMPANIES_AND_PEOPLE_ADD_EDIT}
                            error={Boolean(touched.notes && errors.notes)}
                            fullWidth
                            helperText={touched.notes && errors.notes}
                            label='Internal notes'
                            name='notes'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.notes || ''}
                          />
                        </Tooltip>
                      </Grid>

                      <Grid item xs={12} sm>
                        <Tooltip
                          title={
                            COMPANIES_AND_PEOPLE_ADD_EDIT
                              ? ''
                              : 'Must have "Companies and people add/edit" permission'
                          }
                          placement='bottom'
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.internalExternal}
                                disabled={isSubmitting || !COMPANIES_AND_PEOPLE_ADD_EDIT}
                                name='internalExternal'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.internalExternal}
                              />
                            }
                            label='Internal'
                          />
                        </Tooltip>

                        <Tooltip
                          title={
                            COMPANIES_AND_PEOPLE_ADD_EDIT
                              ? ''
                              : 'Must have "Companies and people add/edit" permission'
                          }
                          placement='bottom'
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.isActive}
                                disabled={isSubmitting || !COMPANIES_AND_PEOPLE_ADD_EDIT}
                                name='isActive'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.isActive}
                              />
                            }
                            label='Active'
                          />
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </DetailsForm>
                </DetailsTab>

                <DetailsTab value='addresses'>
                  <Grid container spacing={2} alignItems='center'>
                    <DetailsActions>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={activeAddressesOnly}
                            disabled={isSubmitting}
                            onChange={e => setActiveAddressesOnly(e.target.checked)}
                          />
                        }
                        label='Active only'
                      />

                      <Tooltip
                        title={
                          COMPANIES_AND_PEOPLE_ADD_EDIT
                            ? ''
                            : 'Must have "Companies and people add/edit" permission'
                        }
                        placement='bottom'
                      >
                        <span>
                          <Button
                            disabled={isSubmitting || !COMPANIES_AND_PEOPLE_ADD_EDIT}
                            onClick={() => {
                              setAddress(new AddressDto())
                              setAddressDialogOpen(true)
                            }}
                            variant='contained'
                          >
                            ADD NEW
                          </Button>
                        </span>
                      </Tooltip>
                    </DetailsActions>

                    {values?.addresses
                      ?.filter(address => !activeAddressesOnly || address.isActive)
                      // Sort by address notes (description)
                      .sort((a, b) => (a.notes ?? '').localeCompare(b.notes ?? ''))
                      // Then sort by address type
                      .sort((a, b) =>
                        a.addressType!.name.localeCompare(b.addressType!.name)
                      )
                      .map(address => (
                        <Grid item xs={12}>
                          <ListItemCard
                            chips={[
                              ...(!address.isActive
                                ? [
                                    <Chip
                                      color='default'
                                      label='Inactive'
                                      variant='outlined'
                                    />
                                  ]
                                : [])
                            ]}
                            title={
                              address.addressType?.name +
                              (address.notes ? ` - ${address.notes}` : '')
                            }
                            actions={[
                              <Tooltip
                                title={
                                  COMPANIES_AND_PEOPLE_ADD_EDIT
                                    ? ''
                                    : 'Must have "Companies and people add/edit" permission'
                                }
                                placement='bottom'
                              >
                                <span>
                                  <Button
                                    disabled={
                                      isSubmitting || !COMPANIES_AND_PEOPLE_ADD_EDIT
                                    }
                                    onClick={() => {
                                      setAddress(address)
                                      setAddressDialogOpen(true)
                                    }}
                                    variant='text'
                                  >
                                    EDIT
                                  </Button>
                                </span>
                              </Tooltip>
                            ]}
                            variant='outlined'
                          >
                            {address.phone && (
                              <Typography variant='body2'>{address.phone}</Typography>
                            )}

                            {address.email && (
                              <Typography variant='body2'>{address.email}</Typography>
                            )}

                            <Typography variant='body2'>
                              {address.address1},{' '}
                              {address.address2 && address.address2 + ', '}
                              {address.city}, {address.state + ', '}
                              {address.postalCode} {address.country}
                            </Typography>
                          </ListItemCard>
                        </Grid>
                      ))}
                  </Grid>
                </DetailsTab>

                <DetailsActions>
                  <Button
                    color='secondary'
                    disabled={
                      isSubmitting ||
                      (!COMPANIES_AND_PEOPLE_ADD_EDIT &&
                        !COMPANY_CUSTOMER_VENDOR_NUMBER_ADD_EDIT)
                    }
                    onClick={() => {
                      navigate('/companies')
                    }}
                    variant='text'
                  >
                    BACK
                  </Button>

                  <Tooltip
                    title={
                      COMPANIES_AND_PEOPLE_ADD_EDIT
                        ? ''
                        : 'Must have "Companies and people add/edit" permission'
                    }
                    placement='bottom'
                  >
                    <span>
                      <LoadingButton
                        color='primary'
                        loading={isSubmitting}
                        disabled={isSubmitting || !COMPANIES_AND_PEOPLE_ADD_EDIT}
                        onClick={() => {
                          // Due to an issue with Formik, the form fields need to be manually set as touched when there are errors on submit.
                          // Otherwise, sometimes the field errors won't display even though the validation throws them
                          validateForm().then(errors => {
                            const errorKeys = Object.keys(errors)
                            if (errorKeys.length === 0) {
                              submitForm()
                            } else {
                              setTouched(setNestedObjectValues(errors, true))
                              showFormErrorsPrompt(errors)
                            }
                          })
                        }}
                        variant='contained'
                      >
                        {values.id ? 'UPDATE' : 'CREATE'} COMPANY
                      </LoadingButton>
                    </span>
                  </Tooltip>
                </DetailsActions>
              </Details>

              <UnsavedChangesPrompt when={dirty} />
            </>
          )
        }}
      </Formik>
    </Layout>
  )
}
