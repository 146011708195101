import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@mui/material'
import { ExplanationAccordion } from 'components/_template/accordion'
import { ForkliftToDoMoveFinishedGoodsInventoryDto } from 'dtos'

interface ForkliftToDosCompleteMoveFinishedGoodsDialogProps {
  forkliftToDo: ForkliftToDoMoveFinishedGoodsInventoryDto
  isLoading: boolean
  onClose: () => void
  open: boolean
}

export default function ForkliftToDosCompleteMoveFinishedGoodsDialog({
  forkliftToDo,
  isLoading,
  onClose,
  open
}: ForkliftToDosCompleteMoveFinishedGoodsDialogProps) {
  return (
    <Dialog open={open}>
      <DialogContent>
        <Grid container spacing={2} alignItems='center'>
          <DialogTitle>Write New Traveler For Bundle</DialogTitle>
          <Grid item xs={12}>
            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
              Item #: {forkliftToDo?.itemNumber}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
              Job #: {forkliftToDo?.jobNumber}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
              Bundle #: {forkliftToDo?.bundleNumber}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
              Drop Off Location: {forkliftToDo?.dropOffForkliftPalletLocation?.name}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <ExplanationAccordion>
              The Item #, Job #, and Bundle # in this dialog must be used to write a new
              traveler for this bundle.
            </ExplanationAccordion>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions sx={{ mb: 2, mr: 2 }}>
        <Button
          disabled={isLoading}
          onClick={() => {
            onClose()
          }}
          tabIndex={5}
          variant='contained'
        >
          DONE
        </Button>
      </DialogActions>
    </Dialog>
  )
}
