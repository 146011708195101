import * as yup from 'yup'
import { fieldTypeMessage, requiredFieldMessage } from 'constantValues'
import { FinishedGoodItemTypeDto } from './finishedGoodItemTypes'
import { FinishedGoodsInventoryDto } from './finishedGoodsInventory'
import { JobStatusDto } from './jobStatus'
import { VendorOrderDto } from './vendorOrder'

// Formik and Yup do not behave correctly when the fields are initialized to a value other than `undefined`
// so mark every non-boolean Dto property referenced in the validation as optional, initialized to undefined,
// and use Yup and the back-end logic for validation
export class JobDto {
  id?: string = undefined
  vendorOrder?: VendorOrderDto = undefined
  jobNumber?: number = undefined
  palletCount?: number = undefined
  // Because this does not have a time, DateOnly is used on the back-end. DateOnly and TimeOnly types must be treated as a string on the front-end
  scheduleDate?: string = undefined
  priority?: number = undefined
  jobStatus?: JobStatusDto = undefined
  finishedGoodItemDescription?: string = undefined
  finishedGoodGenericDescription?: string = undefined
  finishedGoodItemType?: FinishedGoodItemTypeDto = undefined
  finishedGoodWeightPerPiece?: number = undefined
  dieItemNumber?: string = undefined
  dieItemDescription?: string = undefined
  dieGenericDescription?: string = undefined
  dieItemType?: FinishedGoodItemTypeDto = undefined
  printPlateItemNumber?: string = undefined
  printPlateItemDescription?: string = undefined
  printPlateGenericDescription?: string = undefined
  printPlateItemType?: FinishedGoodItemTypeDto = undefined
  inkItemNumber?: string = undefined
  inkItemDescription?: string = undefined
  inkGenericDescription?: string = undefined
  inkItemType?: FinishedGoodItemTypeDto = undefined
  isActive?: boolean = true
  finishedGoodsInventory?: FinishedGoodsInventoryDto[] = undefined
}

export const jobValidationSchema = yup.object<JobDto>({
  id: yup.string().nullable(),
  scheduleDate: yup.string().required(requiredFieldMessage),
  priority: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
})
