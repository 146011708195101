import * as yup from 'yup'
import { CompanyDto } from './companies'
import { PermissionDto } from './permissions'
import {
  fieldTypeMessage,
  maxString200Message,
  maxString200Value,
  maxTextFieldMessage,
  minTextFieldMessage,
  requiredFieldMessage
} from 'constantValues'
import { DepartmentDto } from './departments'

export type PeopleWithCompany = {}

export class PersonDto {
  id?: string = undefined
  firstName: string = ''
  lastName: string = ''
  // CompanyId?: string = undefined
  title?: string = undefined
  email?: string = undefined
  username?: string = undefined
  signInPin?: string = undefined
  mobilePhone?: string = undefined
  workPhone?: string = undefined
  allowUserToSignIn: boolean = false
  allowEmailSignIn: boolean = false
  allowUsernameSignIn: boolean = false
  employmentStartDate?: Date | string = undefined
  employmentEndDate?: Date | string = undefined
  isActive: boolean = true
  isUser: boolean = false
  token?: string = undefined
  company?: CompanyDto = undefined
  landingPage?: string = '/companies'
  permissions: PermissionDto[] = []
  departments: DepartmentDto[] = []
  // LandingPage?: string = undefined;

  constructor(model?: any) {}
}

export const peopleValidationSchema = yup.object<PersonDto>({
  id: yup.string().nullable(),
  firstName: yup
    .string()
    .required(requiredFieldMessage)
    .max(maxString200Value, maxString200Message),
  lastName: yup
    .string()
    .required(requiredFieldMessage)
    .max(maxString200Value, maxString200Message),
  company: yup.object().nullable(),
  title: yup.string().max(maxString200Value, maxString200Message).nullable(),
  email: yup
    .string()
    .email(fieldTypeMessage('Email'))
    .max(320, maxTextFieldMessage(320))
    .when(['allowUserToSignIn', 'allowEmailSignIn'], (values, schema) => {
      if (values[0] && values[1]) {
        return schema.required(requiredFieldMessage)
      } else {
        return schema.nullable()
      }
    }),
  username: yup
    .string()
    .max(maxString200Value, maxString200Message)
    .matches(
      /^\w+$/,
      'Username must not contain special characters (except "_") or whitespace.'
    )
    .when(['allowUserToSignIn', 'allowUsernameSignIn'], (values, schema) => {
      if (values[0] && values[1]) {
        return schema.required(requiredFieldMessage)
      } else {
        return schema.nullable()
      }
    }),
  signInPin: yup
    .string()
    .min(6, minTextFieldMessage(6))
    .max(6, maxTextFieldMessage(6))
    .matches(/^\d{6}$/, 'Sign-In PIN must be a 6-digit whole number.')
    .when(['allowUserToSignIn', 'allowUsernameSignIn'], (values, schema) => {
      if (values[0] && values[1]) {
        return schema.required(requiredFieldMessage)
      } else {
        return schema.nullable()
      }
    }),
  mobilePhone: yup.string().max(maxString200Value, maxString200Message).nullable(),
  workPhone: yup.string().max(maxString200Value, maxString200Message).nullable(),
  allowUserToSignIn: yup.bool(),
  allowEmailSignIn: yup.bool(),
  allowUsernameSignIn: yup.bool(),
  employmentStartDate: yup.string().nullable(),
  employmentEndDate: yup.string().nullable(),
  isActive: yup.bool()
})
