import * as yup from 'yup'
import {
  fieldTypeMessage,
  maxIntMessage,
  maxIntValue,
  maxString200Message,
  maxString200Value,
  minIntMessage,
  minIntValue,
  requiredFieldMessage
} from 'constantValues'
import { ForkliftPalletLocationDto } from './forkliftPalletLocation'
import { ForkliftInstructionDto } from './forkliftInstruction'

// Formik and Yup do not behave correctly when the fields are initialized to a value other than `undefined`
// so mark every non-boolean Dto property referenced in the validation as optional, initialized to undefined,
// and use Yup and the back-end logic for validation
export class ForkliftToDoDto {
  id?: string = undefined
  forkliftInstruction?: ForkliftInstructionDto
  vendorOrderNumber?: string = undefined
  vendorItemNumber?: string = undefined
  palletNumber?: string = undefined
  itemNumber?: string = undefined
  isAdditionalInformationAvailable?: boolean = true
  suggestedItemNumber?: string = undefined
  jobNumber?: string = undefined
  suggestedJobNumber?: string = undefined
  bundleNumber?: string = undefined
  suggestedBundleNumber?: string = undefined
  quantity?: number = undefined
  door?: string = undefined
  currentForkliftPalletLocation?: ForkliftPalletLocationDto = undefined
  dropOffForkliftPalletLocation?: ForkliftPalletLocationDto = undefined
  isActive?: boolean = true
}

export const forkliftToDosScanPalletBarcodeValidationSchema = yup.object<ForkliftToDoDto>(
  {
    vendorOrderNumber: yup
      .string()
      .required(requiredFieldMessage)
      .max(maxString200Value, maxString200Message),
    vendorItemNumber: yup
      .string()
      .required(requiredFieldMessage)
      .max(maxString200Value, maxString200Message),
    palletNumber: yup
      .string()
      .required(requiredFieldMessage)
      .max(maxString200Value, maxString200Message),
    dropOffForkliftPalletLocation: yup
      .object()
      .typeError(fieldTypeMessage('Pallet Location'))
      .required(requiredFieldMessage)
  }
)

export const forkliftToDosScanBundleBarcodeValidationSchema = yup.object<ForkliftToDoDto>(
  {
    itemNumber: yup
      .string()
      .required(requiredFieldMessage)
      .max(maxString200Value, maxString200Message),
    jobNumber: yup
      .number()
      .integer(fieldTypeMessage('Whole Number'))
      .typeError(fieldTypeMessage('Whole Number'))
      .required(requiredFieldMessage)
      .min(minIntValue, minIntMessage)
      .max(maxIntValue, maxIntMessage),
    bundleNumber: yup
      .number()
      .integer(fieldTypeMessage('Whole Number'))
      .typeError(fieldTypeMessage('Whole Number'))
      .required(requiredFieldMessage)
      .min(minIntValue, minIntMessage)
      .max(maxIntValue, maxIntMessage),
    dropOffForkliftPalletLocation: yup
      .object()
      .typeError(fieldTypeMessage('Pallet Location'))
      .required(requiredFieldMessage)
  }
)
