import { DepartureBoard, QrCodeScanner, Warehouse } from '@mui/icons-material'
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material'
import * as api from 'api'
import {
  ForkliftToDosScanBundleBarcodeDialog,
  ForkliftToDosScanPalletBarcodeDialog,
  ForkliftToDosScanShippingItemBarcodeDialog
} from 'components'
import { Layout } from 'components/_template'
import { ExplanationAccordion } from 'components/_template/accordion'
import { List, ListHeaderCard, ListItemCard } from 'components/_template/list'
import ForkliftToDosCheckMoveFinishedGoodsDialog from 'components/ForkliftToDosCheckMoveFinishedGoodsDialog'
import ForkliftToDosCompleteMoveFinishedGoodsDialog from 'components/ForkliftToDosCompleteMoveFinishedGoodsDialog'
import ForkliftToDosCreateUnquotedFinishedGoodsInventoryDialog from 'components/ForkliftToDosCreateUnquotedFinishedGoodsInventoryDialog'
import ForkliftToDosMoveRawMaterialsDialog from 'components/ForkliftToDosMoveRawMaterialsDialog'
import { ForkliftPalletLocationDto, ForkliftToDoDto } from 'dtos'
import { enqueueSnackbar } from 'notistack'
import { ForkliftToDosParameters } from 'parameters'
import { useEffect, useState } from 'react'
import { useDebounce } from 'utils'
import { errorHandling } from 'constantValues'

const sessionStorageKey = 'forkliftToDos:parameters'

export default function ForkliftToDos() {
  const defaultParameters: ForkliftToDosParameters = {
    page: 0,
    pageSize: 10,
    includeReceiving: false,
    includeJobCenter86Rdc: false,
    includeJobCenter66Rdc: false,
    includeBander: false,
    includeStaging: false,
    includeTruck: false
  }

  const [count, setCount] = useState<number>(0)
  const [
    isSavingForkliftToDoMoveFinishedGoods,
    setIsSavingForkliftToDoMoveFinishedGoods
  ] = useState<boolean>(false)
  const [isSavingForkliftToDoMoveRawMaterials, setIsSavingForkliftToDoMoveRawMaterials] =
    useState<boolean>(false)
  const [isUpdatingForkliftToDoBundle, setIsUpdatingForkliftToDoBundle] =
    useState<boolean>(false)
  const [isUpdatingForkliftToDoPallet, setIsUpdatingForkliftToDoPallet] =
    useState<boolean>(false)
  const [isUpdatingForkliftToDoShippingItem, setIsUpdatingForkliftToDoShippingItem] =
    useState<boolean>(false)
  const [isGettingForkliftToDos, setIsGettingForkliftToDos] = useState<boolean>(false)
  const [parameters, setParameters] = useState<ForkliftToDosParameters>(
    sessionStorage.getItem(sessionStorageKey)
      ? JSON.parse(sessionStorage.getItem(sessionStorageKey)!)
      : defaultParameters
  )
  const [forkliftPalletLocations, setForkliftPalletLocations] = useState<
    ForkliftPalletLocationDto[]
  >([])
  const [forkliftToDo, setForkliftToDo] = useState<ForkliftToDoDto>(new ForkliftToDoDto())
  const [forkliftToDos, setForkliftToDos] = useState<ForkliftToDoDto[]>([])
  const [
    forkliftToDosCheckMoveFinishedGoodsDialogOpen,
    setForkliftToDosCheckMoveFinishedGoodsDialogOpen
  ] = useState<boolean>(false)
  const [
    forkliftToDosCompleteMoveFinishedGoodsDialogOpen,
    setForkliftToDosCompleteMoveFinishedGoodsDialogOpen
  ] = useState<boolean>(false)
  const [
    forkliftToDosCreateUnquotedFinishedGoodsInventoryDialogOpen,
    setForkliftToDosCreateUnquotedFinishedGoodsInventoryDialogOpen
  ] = useState<boolean>(false)
  const [forkliftMoveRawMaterialsDialogOpen, setForkliftMoveRawMaterialsDialogOpen] =
    useState<boolean>(false)
  const [forkliftBundleDialogOpen, setForkliftBundleDialogOpen] = useState<boolean>(false)
  const [forkliftPalletDialogOpen, setForkliftPalletDialogOpen] = useState<boolean>(false)
  const [forkliftShippingItemDialogOpen, setForkliftShippingItemDialogOpen] =
    useState<boolean>(false)

  const isLoading =
    isGettingForkliftToDos ||
    isUpdatingForkliftToDoBundle ||
    isUpdatingForkliftToDoPallet ||
    isUpdatingForkliftToDoShippingItem ||
    isSavingForkliftToDoMoveFinishedGoods ||
    isSavingForkliftToDoMoveRawMaterials

  const getDropOffPalletLocation = (
    forkliftToDo: ForkliftToDoDto
  ): ForkliftPalletLocationDto | undefined => {
    var dropOffPalletLocation = undefined

    switch (forkliftToDo.forkliftInstruction?.specialIdentifier) {
      case 'PICK_UP_FROM_RECEIVING':
        break
      case 'PICK_UP_AND_DELIVER_TO_JOB_CENTER_86RDC':
        dropOffPalletLocation = forkliftPalletLocations.find(
          forkliftPalletLocation =>
            forkliftPalletLocation.specialIdentifier == 'JOB_CENTER_86RDC'
        )
        break
      case 'PICK_UP_AND_DELIVER_TO_JOB_CENTER_66RDC':
        dropOffPalletLocation = forkliftPalletLocations.find(
          forkliftPalletLocation =>
            forkliftPalletLocation.specialIdentifier == 'JOB_CENTER_66RDC'
        )
        break
      case 'PICK_UP_FROM_BANDER':
        break
      case 'PICK_UP_AND_PUT_IN_STAGING':
        dropOffPalletLocation = forkliftPalletLocations.find(
          forkliftPalletLocation => forkliftPalletLocation.specialIdentifier == 'STAGING'
        )
        break
      case 'PICK_UP_AND_PUT_ON_TRUCK':
        dropOffPalletLocation = forkliftPalletLocations.find(
          forkliftPalletLocation => forkliftPalletLocation.specialIdentifier == 'TRUCK'
        )
        break
    }
    return dropOffPalletLocation
  }

  const getForkliftToDos = useDebounce((parameters: ForkliftToDosParameters) => {
    setIsGettingForkliftToDos(true)
    sessionStorage.setItem(sessionStorageKey, JSON.stringify(parameters))
    api
      .getForkliftToDos(parameters)
      .then(res => {
        setForkliftToDos(res.value)
        setCount(res.totalCount!)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsGettingForkliftToDos(false)
      })
  }, 300)

  useEffect(() => {
    api
      .getForkliftPalletLocations()
      .then(({ value }) => {
        setForkliftPalletLocations(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
  }, [])

  useEffect(() => {
    getForkliftToDos(parameters)
  }, [parameters])

  return (
    <Layout title='Forklift To Dos' isLoading={isLoading}>
      <ForkliftToDosMoveRawMaterialsDialog
        forkliftPalletLocations={forkliftPalletLocations}
        forkliftToDo={forkliftToDo}
        isLoading={isLoading}
        open={forkliftMoveRawMaterialsDialogOpen}
        onClose={() => {
          setForkliftToDo(new ForkliftToDoDto())
          setForkliftMoveRawMaterialsDialogOpen(false)
        }}
        onSave={forkliftToDo => {
          setIsSavingForkliftToDoMoveRawMaterials(true)
          return api
            .forkliftToDoMoveRawInventory(forkliftToDo)
            .then(({ value }) => {
              enqueueSnackbar(`Raw Materials Moved Successfully!`, {
                variant: 'success'
              })
              getForkliftToDos(parameters)
            })
            .catch((errors: string[]) => {
              errorHandling(errors)
            })
            .finally(() => {
              setForkliftToDo({
                ...new ForkliftToDoDto(),
                dropOffForkliftPalletLocation:
                  forkliftToDo?.dropOffForkliftPalletLocation,
                forkliftInstruction: forkliftToDo?.forkliftInstruction
              })
              setIsSavingForkliftToDoMoveRawMaterials(false)
            })
        }}
      />

      <ForkliftToDosCheckMoveFinishedGoodsDialog
        forkliftPalletLocations={forkliftPalletLocations}
        forkliftToDo={forkliftToDo}
        isLoading={isLoading}
        open={forkliftToDosCheckMoveFinishedGoodsDialogOpen}
        onClose={() => {
          setForkliftToDo(new ForkliftToDoDto())
          setForkliftToDosCheckMoveFinishedGoodsDialogOpen(false)
        }}
        onSave={values => {
          setIsSavingForkliftToDoMoveFinishedGoods(true)
          return api
            .forkliftToDoCheckMoveFinishedGoodsInventory(values)
            .then(({ value }) => {
              if (value) {
                enqueueSnackbar(`Finished Goods Moved Successfully!`, {
                  variant: 'success'
                })
              } else {
                setForkliftToDosCheckMoveFinishedGoodsDialogOpen(false)
                // Copy the entered Item # to forkliftToDo so it populates in ForkliftToDosCreateUnquotedFinishedGoodsInventoryDialog
                setForkliftToDo({ ...forkliftToDo, itemNumber: values.itemNumber })
                setForkliftToDosCreateUnquotedFinishedGoodsInventoryDialogOpen(true)
              }
            })
            .catch((errors: string[]) => {
              errorHandling(errors)
            })
            .finally(() => {
              setIsSavingForkliftToDoMoveFinishedGoods(false)
            })
        }}
      />

      <ForkliftToDosCreateUnquotedFinishedGoodsInventoryDialog
        forkliftPalletLocations={forkliftPalletLocations}
        forkliftToDo={forkliftToDo}
        isLoading={isLoading}
        open={forkliftToDosCreateUnquotedFinishedGoodsInventoryDialogOpen}
        onClose={() => {
          setForkliftToDo(new ForkliftToDoDto())
          setForkliftToDosCreateUnquotedFinishedGoodsInventoryDialogOpen(false)
        }}
        onSave={values => {
          setIsSavingForkliftToDoMoveFinishedGoods(true)
          return api
            .createUnquotedFinishedGoodsInventory(values)
            .then(({ value }) => {
              getForkliftToDos(parameters)
              enqueueSnackbar(`Finished Goods Inventory Created Successfully!`, {
                variant: 'success'
              })
              setForkliftToDosCreateUnquotedFinishedGoodsInventoryDialogOpen(false)
              // Copy the returned FinishedGoodsInventory data so it populates in ForkliftToDosCompleteMoveFinishedGoodsDialog
              setForkliftToDo(value)
              setForkliftToDosCompleteMoveFinishedGoodsDialogOpen(true)
            })
            .catch((errors: string[]) => {
              errorHandling(errors)
            })
            .finally(() => {
              setIsSavingForkliftToDoMoveFinishedGoods(false)
            })
        }}
      />

      <ForkliftToDosCompleteMoveFinishedGoodsDialog
        forkliftToDo={forkliftToDo}
        isLoading={isLoading}
        open={forkliftToDosCompleteMoveFinishedGoodsDialogOpen}
        onClose={() => {
          setForkliftToDo(new ForkliftToDoDto())
          setForkliftToDosCompleteMoveFinishedGoodsDialogOpen(false)
        }}
      />

      <ForkliftToDosScanPalletBarcodeDialog
        forkliftPalletLocations={forkliftPalletLocations}
        forkliftToDo={forkliftToDo}
        open={forkliftPalletDialogOpen}
        onClose={() => {
          setForkliftToDo(new ForkliftToDoDto())
          setForkliftPalletDialogOpen(false)
        }}
        onSave={forkliftToDo => {
          setIsUpdatingForkliftToDoPallet(true)
          return api
            .updateForkliftToDo(forkliftToDo)
            .then(({ value }) => {
              enqueueSnackbar(`Forklift To Do Updated Successfully!`, {
                variant: 'success'
              })
              getForkliftToDos(parameters)
            })
            .catch((errors: string[]) => {
              errorHandling(errors)
            })
            .finally(() => {
              setForkliftToDo({
                ...new ForkliftToDoDto(),
                dropOffForkliftPalletLocation:
                  forkliftToDo?.dropOffForkliftPalletLocation,
                forkliftInstruction: forkliftToDo?.forkliftInstruction
              })
              setIsUpdatingForkliftToDoPallet(false)
            })
        }}
      />

      <ForkliftToDosScanBundleBarcodeDialog
        forkliftPalletLocations={forkliftPalletLocations}
        forkliftToDo={forkliftToDo}
        open={forkliftBundleDialogOpen}
        onClose={() => {
          setForkliftToDo(new ForkliftToDoDto())
          setForkliftBundleDialogOpen(false)
        }}
        onSave={forkliftToDo => {
          setIsUpdatingForkliftToDoBundle(true)
          return api
            .updateForkliftToDo(forkliftToDo)
            .then(({ value }) => {
              enqueueSnackbar(`Forklift To Do Updated Successfully!`, {
                variant: 'success'
              })
              getForkliftToDos(parameters)
            })
            .catch((errors: string[]) => {
              errorHandling(errors)
            })
            .finally(() => {
              setForkliftToDo({
                ...new ForkliftToDoDto(),
                dropOffForkliftPalletLocation:
                  forkliftToDo?.dropOffForkliftPalletLocation,
                forkliftInstruction: forkliftToDo?.forkliftInstruction
              })
              setIsUpdatingForkliftToDoBundle(false)
            })
        }}
      />

      <ForkliftToDosScanShippingItemBarcodeDialog
        forkliftPalletLocations={forkliftPalletLocations}
        forkliftToDo={forkliftToDo}
        open={forkliftShippingItemDialogOpen}
        onClose={() => {
          setForkliftToDo(new ForkliftToDoDto())
          setForkliftShippingItemDialogOpen(false)
        }}
        onSave={forkliftToDo => {
          setIsUpdatingForkliftToDoShippingItem(true)
          return api
            .updateForkliftToDo(forkliftToDo)
            .then(({ value }) => {
              enqueueSnackbar(`Forklift To Do Updated Successfully!`, {
                variant: 'success'
              })
              getForkliftToDos(parameters)
            })
            .catch((errors: string[]) => {
              errorHandling(errors)
            })
            .finally(() => {
              setForkliftToDo(new ForkliftToDoDto())
              setIsUpdatingForkliftToDoShippingItem(false)
              setForkliftShippingItemDialogOpen(false)
            })
        }}
      />
      <List
        pagination={{
          page: parameters.page,
          pageSize: parameters.pageSize,
          count,
          onPageChange: page => setParameters({ ...parameters, page }),
          onPageSizeChange: pageSize => setParameters({ ...parameters, pageSize })
        }}
        header={
          <ListHeaderCard
            title='Forklift To Dos'
            actions={[
              <Grid container spacing={1} minWidth={250}>
                <Grid item xs={6}>
                  <Button
                    disabled={isLoading}
                    onClick={() => {
                      setForkliftMoveRawMaterialsDialogOpen(true)
                    }}
                    variant='contained'
                    size='medium'
                  >
                    MOVE RM
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    disabled={isLoading}
                    onClick={() => {
                      setForkliftToDosCheckMoveFinishedGoodsDialogOpen(true)
                    }}
                    variant='contained'
                    size='medium'
                  >
                    MOVE FG
                  </Button>
                </Grid>
              </Grid>
            ]}
          />
        }
      >
        <ListItemCard>
          <Grid container spacing={2} alignItems='center' justifyContent='space-between'>
            <Grid item xs={12} sm={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={parameters.includeReceiving}
                    checked={parameters.includeReceiving}
                    onChange={e => {
                      setParameters({
                        ...parameters,
                        includeReceiving: e.target.checked
                      })
                    }}
                  />
                }
                label='Include Receiving'
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={parameters.includeJobCenter86Rdc}
                    checked={parameters.includeJobCenter86Rdc}
                    onChange={e => {
                      setParameters({
                        ...parameters,
                        includeJobCenter86Rdc: e.target.checked
                      })
                    }}
                  />
                }
                label='Include 86RDC'
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={parameters.includeJobCenter66Rdc}
                    checked={parameters.includeJobCenter66Rdc}
                    onChange={e => {
                      setParameters({
                        ...parameters,
                        includeJobCenter66Rdc: e.target.checked
                      })
                    }}
                  />
                }
                label='Include 66RDC'
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={parameters.includeBander}
                    checked={parameters.includeBander}
                    onChange={e => {
                      setParameters({
                        ...parameters,
                        includeBander: e.target.checked
                      })
                    }}
                  />
                }
                label='Include Bander'
              />
            </Grid>

            <Grid item xs={12} sm={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={parameters.includeStaging}
                    checked={parameters.includeStaging}
                    onChange={e => {
                      setParameters({
                        ...parameters,
                        includeStaging: e.target.checked
                      })
                    }}
                  />
                }
                label='Include Staging'
              />
            </Grid>

            <Grid item xs={12} sm={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={parameters.includeTruck}
                    checked={parameters.includeTruck}
                    onChange={e => {
                      setParameters({
                        ...parameters,
                        includeTruck: e.target.checked
                      })
                    }}
                  />
                }
                label='Include Truck'
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Button
                color='primary'
                size='medium'
                variant='text'
                onClick={() => {
                  setParameters(defaultParameters)
                }}
              >
                RESET FILTERS
              </Button>
            </Grid>
          </Grid>
        </ListItemCard>

        {forkliftToDos.map((forkliftToDo, index) =>
          forkliftToDo.forkliftInstruction?.specialIdentifier ==
            'PICK_UP_FROM_RECEIVING' ||
          forkliftToDo.forkliftInstruction?.specialIdentifier ==
            'PICK_UP_AND_DELIVER_TO_JOB_CENTER_86RDC' ||
          forkliftToDo.forkliftInstruction?.specialIdentifier ==
            'PICK_UP_AND_DELIVER_TO_JOB_CENTER_66RDC' ? (
            <ListItemCard
              key={forkliftToDo.id}
              title={forkliftToDo.forkliftInstruction.name}
              actions={[
                <Tooltip title='Scan Pallet Barcodes'>
                  <IconButton
                    onClick={() => {
                      setForkliftToDo({
                        ...forkliftToDo,
                        dropOffForkliftPalletLocation:
                          getDropOffPalletLocation(forkliftToDo)
                      })
                      setForkliftPalletDialogOpen(true)
                    }}
                    color='primary'
                  >
                    <QrCodeScanner fontSize='large' />
                  </IconButton>
                </Tooltip>
              ]}
            >
              <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
                Current Location:{' '}
                {forkliftToDo.currentForkliftPalletLocation?.description}
              </Typography>

              <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
                Vendor Order #: {forkliftToDo.vendorOrderNumber}
              </Typography>

              <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
                Vendor Item #: {forkliftToDo.vendorItemNumber}
              </Typography>

              <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
                Pallet #: {forkliftToDo.palletNumber}
              </Typography>

              <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
                Quantity: {forkliftToDo.quantity}
              </Typography>
            </ListItemCard>
          ) : forkliftToDo.forkliftInstruction?.specialIdentifier ==
            'PICK_UP_FROM_BANDER' ? (
            <ListItemCard
              key={forkliftToDo.id}
              title={forkliftToDo.forkliftInstruction.name}
              actions={[
                <Tooltip title='Scan Bundle Barcodes'>
                  <IconButton
                    onClick={() => {
                      setForkliftToDo({
                        ...forkliftToDo,
                        dropOffForkliftPalletLocation:
                          getDropOffPalletLocation(forkliftToDo)
                      })
                      setForkliftBundleDialogOpen(true)
                    }}
                    color='primary'
                  >
                    <Warehouse fontSize='large' />
                  </IconButton>
                </Tooltip>
              ]}
            >
              <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
                Current Location:{' '}
                {forkliftToDo.currentForkliftPalletLocation?.description}
              </Typography>

              <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
                Part #: {forkliftToDo.itemNumber}
              </Typography>

              <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
                Job #: {forkliftToDo.jobNumber}
              </Typography>

              <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
                Quantity: {forkliftToDo.quantity}
              </Typography>

              <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
                Bundle #: {forkliftToDo.bundleNumber}
              </Typography>
            </ListItemCard>
          ) : forkliftToDo.forkliftInstruction?.specialIdentifier ==
            'PICK_UP_AND_PUT_IN_STAGING' ? (
            <ListItemCard
              key={forkliftToDo.id}
              title={forkliftToDo.forkliftInstruction.name}
              actions={[
                <Tooltip title='Scan Bundle Barcodes'>
                  <IconButton
                    onClick={() => {
                      setForkliftToDo({
                        ...forkliftToDo,
                        dropOffForkliftPalletLocation:
                          getDropOffPalletLocation(forkliftToDo),
                        itemNumber: forkliftToDo.suggestedItemNumber,
                        jobNumber: forkliftToDo.suggestedJobNumber,
                        bundleNumber: forkliftToDo.suggestedBundleNumber
                      })
                      setForkliftShippingItemDialogOpen(true)
                    }}
                    color='primary'
                  >
                    <DepartureBoard fontSize='large' />
                  </IconButton>
                </Tooltip>
              ]}
            >
              <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
                Current Location:{' '}
                {forkliftToDo.currentForkliftPalletLocation?.description}
              </Typography>

              <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
                Part #: {forkliftToDo.suggestedItemNumber}
              </Typography>

              <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
                Job #: {forkliftToDo.suggestedJobNumber}
              </Typography>

              <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
                Quantity: {forkliftToDo.quantity}
              </Typography>

              <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
                Bundle #: {forkliftToDo.suggestedBundleNumber}
              </Typography>
            </ListItemCard>
          ) : forkliftToDo.forkliftInstruction?.specialIdentifier ==
            'PICK_UP_AND_PUT_ON_TRUCK' ? (
            <ListItemCard
              key={forkliftToDo.id}
              title={forkliftToDo.forkliftInstruction.name}
              actions={[
                <Tooltip title='Scan Bundle Barcodes'>
                  <IconButton
                    onClick={() => {
                      setForkliftToDo({
                        ...forkliftToDo,
                        dropOffForkliftPalletLocation:
                          getDropOffPalletLocation(forkliftToDo),
                        itemNumber: forkliftToDo.suggestedItemNumber,
                        jobNumber: forkliftToDo.suggestedJobNumber,
                        bundleNumber: forkliftToDo.suggestedBundleNumber
                      })
                      setForkliftShippingItemDialogOpen(true)
                    }}
                    color='primary'
                  >
                    <DepartureBoard fontSize='large' />
                  </IconButton>
                </Tooltip>
              ]}
            >
              <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
                Current Location:{' '}
                {forkliftToDo.currentForkliftPalletLocation?.description}
              </Typography>

              <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
                Part #: {forkliftToDo.suggestedItemNumber}
              </Typography>

              <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
                Job #: {forkliftToDo.suggestedJobNumber}
              </Typography>

              <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
                Quantity: {forkliftToDo.quantity}
              </Typography>

              <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
                Bundle #: {forkliftToDo.suggestedBundleNumber}
              </Typography>

              <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
                Door: {forkliftToDo.door}
              </Typography>
            </ListItemCard>
          ) : null
        )}

        <Grid item xs={12}>
          <ExplanationAccordion>
            A card is shown for each pallet or bundle to be picked up and
            delivered.||Pallets are stored as Raw Inventory. They begin in the Receiving
            pallet location and are ultimately moved to the 86RDC or 66RDC pallet
            locations. Raw Inventory with a pallet location of 86RDC or 66RDC is no longer
            shown in the system.||Bundles are stored as Finished Goods and labeled via the
            traveler inserted between bundles by the Job Center Operator. They begin in
            the Bander pallet location and are ultimately moved to the Truck pallet
            location (once it has been shipped on a truck).||When a Shipping record is
            created, a card is created for each bundle to be shipped, suggesting which
            bundles the Forklift Operator should pick up and deliver to Staging or a
            truck. This suggestion first checks for bundles with a pallet location of
            Staging, then with a pallet location of Bander, then the most recently updated
            bundles.||Card header options:||1 - "Pick up from Receiving" - Shown on each
            Raw Inventory record with a pallet location of Received. The Forklift Operator
            picks up from Receiving and places in some other pallet location then scans
            the label to assign it to that pallet location (E.g., WARE-B4-01-01).||2 -
            "Pick up and deliver to 86RDC" - Shown on each Raw Inventory record with a
            pallet location that is not 86RDC or 66RDC that is associated with a Job with
            a job center of 86RDC and a scheduled date of today or older. The Forklift
            Operator picks up from its pallet location and places at 86RDC and scans the
            barcode to assign it to that pallet location.||3 - "Pick up and deliver to
            66RDC" - Shown on each Raw Inventory record with a pallet location that is not
            86RDC or 66RDC that is associated with a Job with a job center of 66RDC and a
            scheduled date of today or older. The Forklift Operator picks up from its
            pallet location and places it at 66RDC and scans the barcode to assign it to
            that pallet location.||4 - "Pick up from Bander" - Shown on each Finished
            Goods Inventory record with a location of Bander. The Forklift Operator picks
            up from Banding and places in some other pallet location and scans the barcode
            to assign it to that pallet location (E.g., SHIP-H6-01-01).||5 - "Pick up and
            put in Staging" - Shown on each temporary Shipping Item record where the
            pallet location is not Staging or Truck that is on a shipping record without a
            Trailer # assigned yet.||6 - "Pick up and put on truck" - Shown on each
            temporary Shipping Item record where the pallet location is Staging and is not
            Truck with a Trailer # assigned.||Cards are sorted in reverse order of the
            header options shown here, then by CreatedDateTime descending (I.e., Cards
            with header option 6 are shown first, then option 5. etc.).
          </ExplanationAccordion>
        </Grid>
      </List>
    </Layout>
  )
}
