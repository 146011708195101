import * as yup from 'yup'
import { fieldTypeMessage, requiredFieldMessage } from 'constantValues'

export class ForgotPasswordDto {
  email?: string = undefined
}

export const forgotPasswordValidationSchema = yup.object({
  email: yup.string().email(fieldTypeMessage('Email')).required(requiredFieldMessage)
})
