import * as yup from 'yup'
import {
  fieldTypeMessage,
  maxIntMessage,
  maxIntValue,
  maxString200Message,
  maxString200Value,
  minIntMessage,
  minIntValue,
  requiredFieldMessage
} from 'constantValues'
import { VendorOrderDto } from './vendorOrder'
import { ForkliftPalletLocationDto } from './forkliftPalletLocation'

// Formik and Yup do not behave correctly when the fields are initialized to a value other than `undefined`
// so mark every non-boolean Dto property referenced in the validation as optional, initialized to undefined,
// and use Yup and the back-end logic for validation
export class RawInventoryDto {
  id?: string = undefined
  vendorOrder?: VendorOrderDto = undefined
  vendorOrderNumber?: string = undefined
  vendorItemNumber?: string = undefined
  palletNumber?: string = undefined
  quantityReceived?: number = undefined
  // Because this does not have a time, DateOnly is used on the back-end. DateOnly and TimeOnly types must be treated as a string on the front-end
  receivedDate?: string = undefined
  createdDateTimeUtc?: Date = undefined
  forkliftPalletLocation?: ForkliftPalletLocationDto = undefined
  isActive?: boolean = true
}

export const rawInventoryValidationSchema = yup.object<VendorOrderDto>({
  sheetItemDescription: yup
    .string()
    .max(maxString200Value, maxString200Message)
    .nullable(),
  sheetGenericDescription: yup
    .string()
    .max(maxString200Value, maxString200Message)
    .nullable(),
  sheetItemType: yup.object().nullable()
})

export const receivingScanBarcodeValidationSchema = yup.object({
  vendorOrderNumber: yup
    .string()
    .required(requiredFieldMessage)
    .max(maxString200Value, maxString200Message),
  vendorItemNumber: yup
    .string()
    .required(requiredFieldMessage)
    .max(maxString200Value, maxString200Message),
  palletNumber: yup
    .string()
    .required(requiredFieldMessage)
    .max(maxString200Value, maxString200Message),
  quantityReceived: yup
    .number()
    .integer(fieldTypeMessage('Whole Number'))
    .typeError(fieldTypeMessage('Whole Number'))
    .required(requiredFieldMessage)
    .min(minIntValue, minIntMessage)
    .max(maxIntValue, maxIntMessage)
})
