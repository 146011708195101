import { AssignmentReturn, SearchOutlined } from '@mui/icons-material'
import {
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import * as api from 'api'
import { ReceivingScanBarcodeDialog } from 'components'
import { Layout } from 'components/_template'
import { ExplanationAccordion } from 'components/_template/accordion'
import { List, ListHeaderCard, ListItemCard } from 'components/_template/list'
import { RawInventoryDto, VendorOrderDto } from 'dtos'
import { enqueueSnackbar } from 'notistack'
import { VendorOrdersReceivingParameters } from 'parameters'
import { useEffect, useState } from 'react'
import { useDebounce } from 'utils'
import { errorHandling } from 'constantValues'

const sessionStorageKey = 'receiving:parameters'

export default function Receiving() {
  const defaultParameters: VendorOrdersReceivingParameters = {
    page: 0,
    pageSize: 10,
    includeReceivedInFull: false,
    search: ''
  }

  const [count, setCount] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [parameters, setParameters] = useState<VendorOrdersReceivingParameters>(
    sessionStorage.getItem(sessionStorageKey)
      ? JSON.parse(sessionStorage.getItem(sessionStorageKey)!)
      : defaultParameters
  )
  // Receiving *is* a process on Vendor Orders. 'rawInventory' is used to pre-populate the Scan Barcode dialog
  // with a vendor order's details.
  const [rawInventory, setRawInventory] = useState<RawInventoryDto | undefined>(undefined)
  const [receivingScanBarcodeDialogOpen, setReceivingScanBarcodeDialogOpen] =
    useState<boolean>(false)
  const [vendorOrderReceiving, setVendorOrderReceiving] = useState<VendorOrderDto[]>([])

  useEffect(() => {
    getVendorOrdersReceiving(parameters)

    return () => {}
  }, [parameters])

  const getVendorOrdersReceiving = useDebounce(
    (parameters: VendorOrdersReceivingParameters) => {
      setIsLoading(true)
      sessionStorage.setItem(sessionStorageKey, JSON.stringify(parameters))
      api
        .getVendorOrdersReceiving(parameters)
        .then(res => {
          setVendorOrderReceiving(res.value)
          setCount(res.totalCount!)
        })
        .catch((errors: string[]) => {
          errorHandling(errors)
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    300
  )

  return (
    <Layout title='Receiving' isLoading={isLoading}>
      <ReceivingScanBarcodeDialog
        open={receivingScanBarcodeDialogOpen}
        onClose={() => {
          setReceivingScanBarcodeDialogOpen(false)
          setRawInventory(undefined)
        }}
        rawInventory={rawInventory}
        onSave={values => {
          setIsLoading(true)
          return api
            .createRawInventory(values)
            .then(() => {
              enqueueSnackbar(
                `Received for Vendor Order${
                  values.vendorOrder?.vendorOrderNumber
                    ? ' #' + values.vendorOrder?.vendorOrderNumber
                    : ''
                } successfully!`,
                { variant: 'success' }
              )
              getVendorOrdersReceiving(parameters)
            })
            .catch((errors: string[]) => {
              errorHandling(errors)
            })
            .finally(() => {
              setRawInventory(undefined)
              setIsLoading(false)
            })
        }}
      />

      <List
        pagination={{
          page: parameters.page,
          pageSize: parameters.pageSize,
          count,
          onPageChange: page => setParameters({ ...parameters, page }),
          onPageSizeChange: pageSize => setParameters({ ...parameters, pageSize })
        }}
        header={
          <ListHeaderCard
            title='Receiving'
            actions={[
              <Button
                variant='contained'
                onClick={() => {
                  setReceivingScanBarcodeDialogOpen(true)
                }}
              >
                Scan Barcode
              </Button>
            ]}
          />
        }
      >
        <ListItemCard>
          <Grid container spacing={2} alignItems='center' justifyContent='space-between'>
            <Grid item xs={12} sm={3}>
              <TextField
                data-search
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <SearchOutlined fontSize='small' />
                    </InputAdornment>
                  )
                }}
                label='Search'
                onChange={e => {
                  setParameters({ ...parameters, search: e.target.value })
                }}
                size='small'
                type='search'
                value={parameters.search}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <FormControlLabel
                checked={parameters.includeReceivedInFull}
                control={<Checkbox />}
                label='Include received in full'
                onChange={e => {
                  setParameters({
                    ...parameters,
                    includeReceivedInFull: !parameters.includeReceivedInFull
                  })
                }}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Button
                color='primary'
                size='medium'
                variant='text'
                onClick={() => {
                  setParameters(defaultParameters)
                }}
              >
                RESET FILTERS
              </Button>
            </Grid>
          </Grid>
        </ListItemCard>

        {vendorOrderReceiving.map(vendorOrder => (
          <ListItemCard
            chips={[
              <Chip
                label={vendorOrder.vendorOrderStatus?.name}
                color='primary'
                variant='outlined'
              />
            ]}
            key={vendorOrder.id}
            title={
              'Vendor Order #' +
              vendorOrder.vendorOrderNumber +
              ' - ' +
              vendorOrder.company?.name
            }
            actions={[
              <Tooltip
                title={
                  vendorOrder.vendorOrderStatus?.specialIdentifier === 'RECEIVED_IN_FULL'
                    ? 'Cannot Receive For A Vendor Order That is Received in Full'
                    : 'Receive Vendor Order #' + vendorOrder.vendorOrderNumber
                }
              >
                <span>
                  <IconButton
                    onClick={() => {
                      setRawInventory({
                        ...new RawInventoryDto(),
                        vendorItemNumber: vendorOrder.vendorItemNumber,
                        vendorOrderNumber: vendorOrder.vendorOrderNumber
                      })
                      setReceivingScanBarcodeDialogOpen(true)
                    }}
                    color='primary'
                    disabled={
                      vendorOrder.vendorOrderStatus?.specialIdentifier ===
                      'RECEIVED_IN_FULL'
                    }
                  >
                    <AssignmentReturn />
                  </IconButton>
                </span>
              </Tooltip>
            ]}
          >
            <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
              {vendorOrder.vendorItemNumber}
            </Typography>

            <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
              Quantity: {vendorOrder.quoteLineItem?.checkValuesVendorOrderQuantity}
            </Typography>
          </ListItemCard>
        ))}

        <Grid item xs={12}>
          <ExplanationAccordion>
            Search checks if the search term is contained in any of the individual data
            values shown on the list card. It does not enable searching by labels, chips,
            or special characters.||Shows all vendor orders (defaults to Received in Full
            not checked).||When receiving it adds a record to Raw Inventory with a pallet
            location of "Receiving" for each pallet received.||Using the scan barcode
            button prompts for the Vendor Order # barcode, the quantity barcode and then
            the Pallet # barcode.||If a scanner isn't available the user can search for
            the Vendor Order # and enter the information by hand.||Chips show the Order
            status values: Not Received in Full, Partial Received, Received in
            Full.||Note: The Pallet # is used for identifying and tracking each pallet.
          </ExplanationAccordion>
        </Grid>
      </List>
    </Layout>
  )
}
