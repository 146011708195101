import CheckIcon from '@mui/icons-material/Check'
import EditIcon from '@mui/icons-material/Edit'
import { Button, Checkbox, FormControlLabel, TextField, Tooltip } from '@mui/material'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import * as api from 'api'
import { UnsavedChangesPrompt } from 'components'
import { ExplanationAccordion } from 'components/_template/accordion'
import { DetailsActions, DetailsTab } from 'components/_template/details'
import { RatesInkDto, ratesInksListValidationSchema } from 'dtos'
import { Formik, FormikProps, getIn, setNestedObjectValues } from 'formik'
import { RatesInksParameters } from 'parameters'
import { Ref, useState } from 'react'
import { showFormErrorsPrompt } from '../../components/_template/form/FormErrorsPrompt'

interface RatesInksProps {
  getRatesInks: () => void
  isLoading: boolean
  RATES_ADD_EDIT: boolean
  ratesInksList: RatesInkDto[]
  ratesInksParameters: RatesInksParameters
  ratesInksTabRef:
    | Ref<
        FormikProps<{
          ratesInks: RatesInkDto[]
        }>
      >
    | undefined
  setIsRatesInksAddEditDialogOpen: (isRatesInksAddEditDialogOpen: boolean) => void
  setIsRatesInksTabDirty: (isRatesInksTabDirty: boolean) => void
  setIsUpdatingRatesInks: (isUpdatingRatesInks: boolean) => void
  setRatesInksDialogValues: (ratesInksDialogValues: RatesInkDto) => void
  setRatesInksParameters: (ratesInksParameters: RatesInksParameters) => void
}

export default function RatesInksTab({
  getRatesInks,
  isLoading,
  RATES_ADD_EDIT,
  ratesInksList,
  ratesInksParameters,
  ratesInksTabRef,
  setIsRatesInksAddEditDialogOpen,
  setIsRatesInksTabDirty,
  setIsUpdatingRatesInks,
  setRatesInksParameters
}: RatesInksProps) {
  const [isEditingAllRows, setIsEditingAllRows] = useState<boolean>(false)

  return (
    <Formik
      enableReinitialize
      initialValues={{ ratesInks: ratesInksList }}
      innerRef={ratesInksTabRef}
      validateOnBlur
      validateOnChange
      validationSchema={ratesInksListValidationSchema}
      onSubmit={async (values, formikHelpers) => {
        setIsUpdatingRatesInks(true)
        await api.updateRatesInksList(values.ratesInks).finally(() => {
          setIsEditingAllRows(false)
          formikHelpers.resetForm() // Reset the form so that FRONT_END_isEditing is false
          getRatesInks()
          formikHelpers.setSubmitting(false)
          setIsUpdatingRatesInks(false)
          setIsRatesInksTabDirty(false)
        })
      }}
    >
      {({
        dirty,
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setTouched,
        submitForm,
        resetForm,
        touched,
        validateForm,
        values
      }) => {
        return (
          <>
            <form onSubmit={handleSubmit}>
              <DetailsTab value='tab6'>
                <Grid
                  container
                  xs={12}
                  spacing={2}
                  alignItems='center'
                  justifyContent='flex-end'
                >
                  <Grid
                    container
                    item
                    xs={12}
                    spacing={2}
                    alignItems='center'
                    justifyContent='flex-end'
                  >
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={ratesInksParameters.includeInactive}
                            checked={ratesInksParameters.includeInactive}
                            onChange={e =>
                              setRatesInksParameters({
                                ...ratesInksParameters,
                                includeInactive: e.target.checked
                              })
                            }
                          />
                        }
                        label='Include Inactive'
                        sx={{ mb: 2 }}
                      />
                    </Grid>

                    <Grid item>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={!RATES_ADD_EDIT}
                            value={isEditingAllRows}
                            checked={isEditingAllRows}
                            onChange={e => setIsEditingAllRows(!isEditingAllRows)}
                          />
                        }
                        label='Enable Editing All Rows'
                        sx={{ mb: 2 }}
                      />
                    </Grid>

                    <Grid item>
                      <Tooltip
                        title={
                          RATES_ADD_EDIT
                            ? 'Edit'
                            : 'Must have "Rates add/edit" permission'
                        }
                        placement='bottom'
                      >
                        <Button
                          variant='outlined'
                          disabled={!RATES_ADD_EDIT}
                          onClick={() => {
                            setIsRatesInksAddEditDialogOpen(true)
                          }}
                          sx={{ mb: 2 }}
                        >
                          Add Inks
                        </Button>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                      <TableHead>
                        <TableRow>
                          <TableCell align='center' width='35%'>
                            GCMI Colors
                          </TableCell>
                          <TableCell align='center' width='35%'>
                            Pantone Equivalents
                          </TableCell>
                          <TableCell align='center' width='20%'>
                            Hex
                          </TableCell>
                          <TableCell align='center' width='5%' padding='checkbox'>
                            Active
                          </TableCell>
                          <TableCell width='5%' />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {values.ratesInks.map((ratesInk, index) => (
                          <TableRow
                            key={ratesInk.id ?? index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell align='center'>
                              {ratesInk.FRONT_END_isEditing || isEditingAllRows ? (
                                <TextField
                                  disabled={isLoading || isSubmitting}
                                  error={Boolean(
                                    getIn(touched, `ratesInks[${index}].gcmiColors`) &&
                                      getIn(errors, `ratesInks[${index}].gcmiColors`)
                                  )}
                                  fullWidth
                                  helperText={
                                    getIn(touched, `ratesInks[${index}].gcmiColors`) &&
                                    getIn(errors, `ratesInks[${index}].gcmiColors`)
                                  }
                                  label='GCMI Colors'
                                  name={`ratesInks[${index}].gcmiColors`}
                                  onBlur={handleBlur}
                                  onChange={e => {
                                    setIsRatesInksTabDirty(true)
                                    handleChange(e)
                                  }}
                                  value={ratesInk.gcmiColors || ''}
                                />
                              ) : (
                                ratesInk.gcmiColors
                              )}
                            </TableCell>
                            <TableCell align='center'>
                              {ratesInk.FRONT_END_isEditing || isEditingAllRows ? (
                                <TextField
                                  disabled={isLoading || isSubmitting}
                                  error={Boolean(
                                    getIn(
                                      touched,
                                      `ratesInks[${index}].pantoneEquivalents`
                                    ) &&
                                      getIn(
                                        errors,
                                        `ratesInks[${index}].pantoneEquivalents`
                                      )
                                  )}
                                  fullWidth
                                  helperText={
                                    getIn(
                                      touched,
                                      `ratesInks[${index}].pantoneEquivalents`
                                    ) &&
                                    getIn(
                                      errors,
                                      `ratesInks[${index}].pantoneEquivalents`
                                    )
                                  }
                                  label='Pantone Equivalents'
                                  name={`ratesInks[${index}].pantoneEquivalents`}
                                  onBlur={handleBlur}
                                  onChange={e => {
                                    setIsRatesInksTabDirty(true)
                                    handleChange(e)
                                  }}
                                  value={ratesInk.pantoneEquivalents || ''}
                                />
                              ) : (
                                ratesInk.pantoneEquivalents
                              )}
                            </TableCell>
                            <TableCell align='center'>
                              {ratesInk.FRONT_END_isEditing || isEditingAllRows ? (
                                <TextField
                                  disabled={isLoading || isSubmitting}
                                  error={Boolean(
                                    getIn(touched, `ratesInks[${index}].hexCode`) &&
                                      getIn(errors, `ratesInks[${index}].hexCode`)
                                  )}
                                  fullWidth
                                  helperText={
                                    getIn(touched, `ratesInks[${index}].hexCode`) &&
                                    getIn(errors, `ratesInks[${index}].hexCode`)
                                  }
                                  label='Hex'
                                  name={`ratesInks[${index}].hexCode`}
                                  onBlur={handleBlur}
                                  onChange={e => {
                                    setIsRatesInksTabDirty(true)
                                    handleChange(e)
                                  }}
                                  value={ratesInk.hexCode || ''}
                                />
                              ) : (
                                ratesInk.hexCode
                              )}
                            </TableCell>
                            <TableCell align='center'>
                              {ratesInk.FRONT_END_isEditing || isEditingAllRows ? (
                                <Checkbox
                                  checked={ratesInk.isActive}
                                  disabled={isLoading || isSubmitting}
                                  name={`ratesInks[${index}].isActive`}
                                  onBlur={handleBlur}
                                  onChange={e => {
                                    setIsRatesInksTabDirty(true)
                                    handleChange(e)
                                  }}
                                  value={ratesInk.isActive}
                                />
                              ) : ratesInk.isActive ? (
                                <CheckIcon />
                              ) : null}
                            </TableCell>
                            <TableCell align='center'>
                              <Tooltip
                                title={
                                  RATES_ADD_EDIT
                                    ? 'Edit'
                                    : 'Must have "Rates add/edit" permission'
                                }
                                placement='bottom'
                              >
                                <Grid item>
                                  <IconButton
                                    aria-label={'Edit'}
                                    disabled={
                                      !RATES_ADD_EDIT ||
                                      (ratesInk.FRONT_END_isEditing &&
                                        Boolean(ratesInk.id))
                                    }
                                    onClick={() => {
                                      setFieldValue(
                                        `ratesInks[${index}].FRONT_END_isEditing`,
                                        true
                                      )
                                    }}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Grid>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Grid item xs={12}>
                    <DetailsActions>
                      <Button
                        variant='text'
                        color='secondary'
                        disabled={isLoading || isSubmitting}
                        onClick={() => {
                          resetForm()
                        }}
                      >
                        CANCEL
                      </Button>

                      <Button
                        disabled={isLoading || isSubmitting || !RATES_ADD_EDIT}
                        onClick={() => {
                          // Due to an issue with Formik, the form fields need to be manually set as touched when there are errors on submit.
                          // Otherwise, sometimes the field errors won't display even though the validation throws them
                          validateForm().then(errors => {
                            const errorKeys = Object.keys(errors)
                            if (errorKeys.length === 0) {
                              submitForm()
                            } else {
                              setTouched(setNestedObjectValues(errors, true))
                              showFormErrorsPrompt(errors)
                            }
                          })
                        }}
                        variant='contained'
                      >
                        SAVE
                      </Button>
                    </DetailsActions>
                  </Grid>
                  <Grid item xs={12}>
                    <ExplanationAccordion>
                      Click the Enable Editing All Rows checkbox to access form fields for
                      all rows in the table.||Click the ADD INKS button to open the dialog
                      to add a new Scoring Adder.||To edit an existing row, click the
                      pencil icon edit button to access form fields for the selected row.
                      After clicking the pencil icon edit button, it becomes disabled
                      until saving or cancelling.||Click the CANCEL button to clear all
                      unsaved changes on this tab.||Click the SAVE button to save all
                      unsaved changes on this tab.
                    </ExplanationAccordion>
                  </Grid>
                </Grid>
              </DetailsTab>
              <UnsavedChangesPrompt when={dirty} />
            </form>
          </>
        )
      }}
    </Formik>
  )
}
