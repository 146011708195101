import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField
} from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import { JobPauseReasonTypeDto, JobStatusDto, jobStatusValidationSchema } from 'dtos'
import { Formik, setNestedObjectValues } from 'formik'
import { showFormErrorsPrompt } from './_template/form/FormErrorsPrompt'

interface JobCenter86RdcPauseDialogProps {
  initialValues: JobStatusDto
  jobPauseReasonTypes: JobPauseReasonTypeDto[]
  onClose: () => void
  onSave: (values: JobStatusDto) => void
  open: boolean
}

export default function JobCenter86RdcPauseDialog({
  initialValues,
  jobPauseReasonTypes,
  onClose,
  onSave,
  open
}: JobCenter86RdcPauseDialogProps) {
  return (
    <Dialog open={open}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validateOnBlur
        validateOnChange
        validationSchema={jobStatusValidationSchema}
        onSubmit={values => {
          onSave(values)
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setTouched,
          submitForm,
          touched,
          validateForm,
          values
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2} alignItems='center'>
                  <DialogTitle>Reason for Pausing</DialogTitle>

                  <Grid item xs={12}>
                    <TextField
                      disabled={isSubmitting}
                      error={Boolean(
                        touched.jobPauseReasonType && errors.jobPauseReasonType
                      )}
                      fullWidth
                      helperText={touched.jobPauseReasonType && errors.jobPauseReasonType}
                      label='Reason For Pausing'
                      name='jobPauseReasonType'
                      onBlur={handleBlur}
                      onChange={e => {
                        setFieldValue('jobPauseReasonType', JSON.parse(e.target.value))
                      }}
                      select
                      value={
                        values?.jobPauseReasonType
                          ? JSON.stringify(
                              jobPauseReasonTypes.filter(
                                jobPauseReasonType =>
                                  jobPauseReasonType.id === values.jobPauseReasonType!.id
                              )[0]
                            )
                          : ''
                      }
                    >
                      {jobPauseReasonTypes.map(jobPauseReasonType => (
                        <MenuItem
                          key={jobPauseReasonType.id}
                          value={JSON.stringify(jobPauseReasonType)}
                        >
                          {jobPauseReasonType.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={isSubmitting}
                      error={Boolean(touched.note && errors.note)}
                      fullWidth
                      helperText={touched.note && errors.note}
                      label='Note'
                      name='note'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.note || ''}
                    />
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions sx={{ mb: 2, mr: 2 }}>
                <Button variant='text' color='secondary' onClick={onClose}>
                  CLOSE
                </Button>

                <Button
                  onClick={() => {
                    // Due to an issue with Formik, the form fields need to be manually set as touched when there are errors on submit.
                    // Otherwise, sometimes the field errors won't display even though the validation throws them
                    validateForm().then(errors => {
                      const errorKeys = Object.keys(errors)
                      if (errorKeys.length === 0) {
                        submitForm()
                      } else {
                        setTouched(setNestedObjectValues(errors, true))
                        showFormErrorsPrompt(errors)
                      }
                    })
                  }}
                  variant='contained'
                >
                  SAVE
                </Button>
              </DialogActions>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}
