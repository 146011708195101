import apiClient from 'api'
import { ApiResponse, RatesAssemblyAdderDto } from 'dtos'
import { RatesAssemblyAddersParameters } from 'parameters'
import { URIQuery } from 'utils'

export const getRatesAssemblyAdders = (parameters: RatesAssemblyAddersParameters) =>
  apiClient
    .get<ApiResponse<RatesAssemblyAdderDto[]>>(
      '/api/rates-assembly-adders' + URIQuery({ ...parameters })
    )
    .then(({ data }) => data)

export const getRatesAssemblyAdderById = (id: string) =>
  apiClient
    .get<ApiResponse<RatesAssemblyAdderDto>>('/api/rates-assembly-adders/' + id)
    .then(({ data }) => data)

export const createRatesAssemblyAdder = (values: RatesAssemblyAdderDto) =>
  apiClient
    .post<ApiResponse<RatesAssemblyAdderDto>>('/api/rates-assembly-adders', values)
    .then(({ data }) => data)

export const updateRatesAssemblyAddersList = (values: RatesAssemblyAdderDto[]) =>
  apiClient
    .put<ApiResponse<boolean>>('/api/rates-assembly-adders', values)
    .then(({ data }) => data)
