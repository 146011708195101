import { ReadMoreOutlined, SearchOutlined } from '@mui/icons-material'
import {
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import * as api from 'api'
import { Layout } from 'components/_template'
import { ExplanationAccordion } from 'components/_template/accordion'
import { List, ListHeaderCard, ListItemCard } from 'components/_template/list'
import { JobDto } from 'dtos'
import { JobsParameters } from 'parameters'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { format, formats } from 'theme'
import { useDebounce } from 'utils'
import { errorHandling } from 'constantValues'

const sessionStorageKey = 'jobScheduling:parameters'

export default function JobScheduling() {
  // #region hooks
  const navigate = useNavigate()

  const defaultParameters: JobsParameters = {
    page: 0,
    pageSize: 10,
    search: '',
    includeNotScheduled: true,
    includeCompleted: false
  }

  const getJobs = useDebounce((parameters: JobsParameters) => {
    setIsGettingJobs(true)
    sessionStorage.setItem(sessionStorageKey, JSON.stringify(parameters))
    api
      .getJobs(parameters)
      .then(res => {
        setJobs(res.value)
        setCount(res.totalCount!)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsGettingJobs(false)
      })
  }, 300)
  // #endregion

  // #region useState
  const [count, setCount] = useState<number>(0)
  const [isGettingJobs, setIsGettingJobs] = useState<boolean>(false)
  const [parameters, setParameters] = useState<JobsParameters>(
    sessionStorage.getItem(sessionStorageKey)
      ? JSON.parse(sessionStorage.getItem(sessionStorageKey)!)
      : defaultParameters
  )
  const [jobs, setJobs] = useState<JobDto[]>([])
  // #endregion

  // #region useEffect
  useEffect(() => {
    getJobs(parameters)
  }, [parameters])
  // #endregion

  return (
    <Layout title='Jobs / Scheduling' isLoading={isGettingJobs}>
      <List
        pagination={{
          page: parameters.page,
          pageSize: parameters.pageSize,
          count,
          onPageChange: page => setParameters({ ...parameters, page }),
          onPageSizeChange: pageSize => setParameters({ ...parameters, pageSize })
        }}
        header={<ListHeaderCard title='Jobs / Scheduling' actions={[]} />}
      >
        <ListItemCard>
          <Grid container spacing={2} alignItems='center' justifyContent='space-between'>
            <Grid item xs={12} sm={2}>
              <TextField
                data-search
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <SearchOutlined fontSize='small' />
                    </InputAdornment>
                  )
                }}
                label='Search'
                name='search'
                onChange={e => setParameters({ ...parameters, search: e.target.value })}
                size='small'
                type='search'
                value={parameters.search}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={parameters.includeNotScheduled}
                    checked={parameters.includeNotScheduled}
                    onChange={e =>
                      setParameters({
                        ...parameters,
                        includeNotScheduled: e.target.checked
                      })
                    }
                  />
                }
                label='Include Not Scheduled'
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={parameters.includeCompleted}
                    checked={parameters.includeCompleted}
                    onChange={e =>
                      setParameters({
                        ...parameters,
                        includeCompleted: e.target.checked
                      })
                    }
                  />
                }
                label='Include Completed'
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Button
                color='primary'
                size='medium'
                variant='text'
                onClick={() => {
                  setParameters(defaultParameters)
                }}
              >
                RESET FILTERS
              </Button>
            </Grid>
          </Grid>
        </ListItemCard>

        {jobs.map(job => (
          <ListItemCard
            chips={[
              !job.scheduleDate && (
                <Chip label='Not Scheduled' color='error' variant='outlined' />
              ),
              job.jobStatus && (
                <Chip
                  label={job.jobStatus?.jobStatusType?.name}
                  color='primary'
                  variant='outlined'
                />
              )
            ]}
            key={job.id}
            title={
              job.vendorOrder?.company?.name +
              (' - Priority: ' +
                job.vendorOrder?.quoteLineItem?.jobCenter +
                ' - ' +
                format(job.priority))
            }
            actions={[
              <Tooltip title='View Details'>
                <IconButton
                  onClick={() => navigate('/job-scheduling/' + job.id)}
                  color='primary'
                >
                  <ReadMoreOutlined fontSize='large' />
                </IconButton>
              </Tooltip>
            ]}
          >
            <Typography variant='body2' sx={{ color: '#7F7F7F', fontWeight: 600 }}>
              Job #: {job.jobNumber}
            </Typography>

            <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
              Schedule Date: {format(job.scheduleDate, formats.dateNoTimeZone)}
            </Typography>

            <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
              Item #: {job.vendorOrder?.quoteLineItem?.itemNumber} - Quantity Received:{' '}
              {job.vendorOrder?.receivedToDate}
            </Typography>
          </ListItemCard>
        ))}

        <Grid item xs={12}>
          <ExplanationAccordion>
            Search checks if the search term is contained in any of the individual data
            values shown on the list card. To search by a date, ensure the date is
            formatted as shown on the list card. It does not enable searching by labels,
            chips, or special characters.||Jobs appear automatically based on "Ready to
            Schedule" being clicked on the Vendor Order Details screen.||The "Include Not
            Scheduled" checkbox is checked by default. The rest are not checked by
            default.||Cards are listed in order of Schedule Date, then Company Name, then
            Job # where jobs without a Schedule Date (not scheduled) are listed first.||A
            chip shows "Not Scheduled" if the job is not scheduled, or the status of
            "Started", "Paused", "Stopped", or "Completed" if the job has been started.
          </ExplanationAccordion>
          {/* <ExplanationAccordion development></ExplanationAccordion> */}
        </Grid>
      </List>
    </Layout>
  )
}
