import {
  Description,
  KeyboardArrowDown,
  KeyboardArrowUp,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
  ListAlt,
  OndemandVideo,
  Quiz,
  UnfoldMore
} from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Typography
} from '@mui/material'
import apiClient from 'api'
import {
  LearningCourseSectionAddEditDialog,
  LearningMaterialAddEditDialog,
  UnsavedChangesPrompt
} from 'components'
import { Layout } from 'components/_template'
import {
  Details,
  DetailsActions,
  DetailsHeaderCard,
  DetailsTab
} from 'components/_template/details'
import { showFormErrorsPrompt } from 'components/_template/form/FormErrorsPrompt'
import {
  ApiResponse,
  LearningCourseDto,
  LearningCourseSectionDto,
  LearningMaterialDto,
  LearningMaterialTypeDto
} from 'dtos'
import { Formik, setNestedObjectValues } from 'formik'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import * as yup from 'yup'
import { errorHandling } from 'constantValues'

export default function CourseDetails() {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { id } = useParams()

  const [initialValues, setInitialValues] = useState<LearningCourseDto>(
    new LearningCourseDto()
  )

  // #region Learning Course Section
  const [learningCourseSectionDialogOpen, setLearningCourseSectionDialogOpen] =
    useState<boolean>(false)
  const [learningCourseSection, setLearningCourseSection] =
    useState<LearningCourseSectionDto>(new LearningCourseSectionDto())
  // #endregion

  // learningSectionIndex is used to add new materials to the correct section
  const [learningSectionIndex, setLearningSectionIndex] = useState<number>(0)
  const [learningMaterial, setLearningMaterial] = useState<LearningMaterialDto>(
    new LearningMaterialDto()
  )
  const [learningMaterialTypes, setLearningMaterialTypes] = useState<
    LearningMaterialTypeDto[]
  >([])

  const [learningMaterialDialogOpen, setLearningMaterialDialogOpen] =
    useState<boolean>(false)

  useEffect(() => {
    apiClient
      .get<ApiResponse<LearningMaterialTypeDto[]>>('/api/learning-material-types')
      .then(({ data: { value } }) => {
        setLearningMaterialTypes(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
  }, [])

  //#region Sorting
  // Menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const [fieldName, setFieldName] = useState<string>('')
  const [list, setList] = useState<{ order: number }[]>([])
  const [listIndex, setListIndex] = useState<number>(0)

  const moveItemUp = (list: { order: number }[], index: number) => {
    if (index > 0) {
      const movedItem = list.splice(index, 1)[0]
      list.splice(index - 1, 0, movedItem)
    }

    return updateOrder(list)
  }

  const moveItemTop = (list: { order: number }[], index: number) => {
    if (index > 0) {
      const movedItem = list.splice(index, 1)[0]
      list.unshift(movedItem)
    }

    return updateOrder(list)
  }

  const moveItemDown = (list: { order: number }[], index: number) => {
    if (index < list.length - 1) {
      const movedItem = list.splice(index, 1)[0]
      list.splice(index + 1, 0, movedItem)
    }

    return updateOrder(list)
  }

  const moveItemBottom = (list: { order: number }[], index: number) => {
    if (index < list.length - 1) {
      const movedItem = list.splice(index, 1)[0]
      list.push(movedItem)
    }

    return updateOrder(list)
  }

  function updateOrder(list: { order: number }[]) {
    return list.map((item, index) => ({ ...item, order: index + 1 }))
  }
  //#endregion

  useEffect(() => {
    if (id && id.toLowerCase() !== 'new' && id.toLowerCase() !== 'undefined') {
      // setIsGettingCompany(true)
      apiClient
        .get<ApiResponse<LearningCourseDto>>('/api/learning-courses/' + id)
        .then(({ data: { value } }) => {
          setInitialValues(value)
        })
        .catch((errors: string[]) => {
          errorHandling(errors)
        })
        .finally(() => {
          // setIsGettingCompany(false)
        })
    } else {
      setInitialValues(new LearningCourseDto())
    }
  }, [id])

  return (
    <Layout title='Course Details'>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validateOnBlur
        validateOnChange
        validationSchema={yup.object({})}
        onSubmit={async (values, submitProps) => {
          await (values.id ? apiClient.put : apiClient.post)(
            '/api/learning-courses',
            values
          )
            .then(res => {
              enqueueSnackbar(`${values.name} has been saved!`, {
                variant: 'success'
              })

              setInitialValues(res.data.value)
              submitProps.resetForm({ values: res.data.value })

              if (!values.id) {
                setTimeout(() => {
                  navigate('/courses/' + res.data.value.id, { replace: true })
                }, 100)
              }
            })
            .catch((errors: string[]) => {
              errorHandling(errors)
            })
          // await (values.id ? apiClient.put : apiClient.post)('/api/companies', values)
          //   .then(res => {
          //     enqueueSnackbar(`${values.name} has been saved!`, {
          //       variant: 'success'
          //     })
          //     setInitialValues(res.data.value)
          //     submitProps.resetForm({ values: res.data.value })
          //     if (!values.id) {
          //       setTimeout(() => {
          //         navigate('/companies/' + res.data.value.id, { replace: true })
          //       }, 100)
          //     }
          //   })
          // .catch((errors: string[]) => {
          //   errors?.forEach(error => {
          //     enqueueSnackbar(error, { variant: 'error' })
          //   })
          // })
        }}
      >
        {({
          dirty,
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setTouched,
          submitForm,
          touched,
          validateForm,
          values
        }) => {
          return (
            <>
              <LearningCourseSectionAddEditDialog
                learningCourseSection={learningCourseSection}
                open={learningCourseSectionDialogOpen}
                onClose={() => {
                  setLearningCourseSectionDialogOpen(false)
                }}
                onSave={savedLearningCourseSection => {
                  // Edit
                  if (
                    values.learningCourseSections?.indexOf(learningCourseSection) > -1
                  ) {
                    setFieldValue('learningCourseSections', [
                      ...values.learningCourseSections.filter(
                        (_, index) =>
                          index !==
                          values.learningCourseSections.indexOf(learningCourseSection)
                      ),
                      savedLearningCourseSection
                    ])
                  }
                  // Add
                  else {
                    setFieldValue('learningCourseSections', [
                      ...values.learningCourseSections,
                      {
                        ...savedLearningCourseSection,
                        order: values.learningCourseSections.length + 1
                      }
                    ])
                  }

                  setLearningCourseSectionDialogOpen(false)
                }}
              />

              <LearningMaterialAddEditDialog
                learningMaterial={learningMaterial}
                learningMaterialTypes={learningMaterialTypes}
                onClose={() => {
                  setLearningMaterialDialogOpen(false)
                }}
                open={learningMaterialDialogOpen}
                onSave={savedLearningMaterial => {
                  // Edit `learningCourseSections[${learningSectionIndex}].learningMaterials`
                  if (
                    values.learningCourseSections[
                      learningSectionIndex
                    ].learningMaterials.indexOf(learningMaterial) > -1
                  ) {
                    setFieldValue(
                      `learningCourseSections[${learningSectionIndex}].learningMaterials`,
                      [
                        ...values.learningCourseSections[
                          learningSectionIndex
                        ].learningMaterials.filter(
                          (_, index) =>
                            index !==
                            values.learningCourseSections[
                              learningSectionIndex
                            ].learningMaterials.indexOf(learningMaterial)
                        ),
                        savedLearningMaterial
                      ]
                    )
                  }
                  // Add
                  else {
                    setFieldValue(
                      `learningCourseSections[${learningSectionIndex}].learningMaterials`,
                      [
                        ...values.learningCourseSections[learningSectionIndex]
                          .learningMaterials,
                        {
                          ...savedLearningMaterial,
                          order:
                            values.learningCourseSections[learningSectionIndex]
                              .learningMaterials.length + 1
                        }
                      ]
                    )
                  }

                  setLearningMaterialDialogOpen(false)
                }}
              />

              <Details
                header={<DetailsHeaderCard title='Course Details' />}
                tabs={[
                  { value: 'details', label: 'DETAILS ' },
                  { value: 'materials', label: 'MATERIALS ' }
                ]}
                onSubmit={handleSubmit}
              >
                <DetailsTab value='details'>
                  <Grid container spacing={2} alignItems='center'>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label='Name'
                        name='name'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.name}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label='Description'
                        maxRows={4}
                        minRows={4}
                        multiline
                        name='description'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.description}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      container
                      alignItems='center'
                      justifyContent='space-between'
                    >
                      <Grid item xs={12} sm={6}>
                        <Typography variant='h6' sx={{ color: '#7F7F7F' }}>
                          Course Materials
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Button
                          variant='outlined'
                          onClick={() => {
                            setLearningCourseSection(new LearningCourseSectionDto())
                            setLearningCourseSectionDialogOpen(true)
                          }}
                        >
                          Add Section
                        </Button>
                      </Grid>
                    </Grid>

                    {values.learningCourseSections
                      ?.sort((a, b) => a.order - b.order)
                      .map((learningCourseSection, learningCourseSectionIndex) => (
                        <>
                          <Grid
                            item
                            xs={12}
                            container
                            alignItems='center'
                            justifyContent='space-between'
                          >
                            <Grid item xs={12} sm={6} container spacing={1}>
                              <Grid item>
                                <ListAlt color='primary' />
                              </Grid>

                              <Grid item>
                                <Typography variant='body1'>
                                  {learningCourseSection.title}
                                </Typography>
                              </Grid>
                            </Grid>

                            <Grid
                              item
                              xs={12}
                              sm={6}
                              container
                              alignItems='center'
                              spacing={1}
                              justifyContent='flex-end'
                            >
                              <Grid item>
                                <IconButton
                                  onClick={e => {
                                    handleClick(e)

                                    setFieldName(`learningCourseSections`)
                                    setList(values.learningCourseSections)
                                    setListIndex(learningCourseSectionIndex)
                                  }}
                                >
                                  <UnfoldMore fontSize='small' color='primary' />
                                </IconButton>
                              </Grid>

                              <Grid item>
                                <Button
                                  size='small'
                                  onClick={() => {
                                    setLearningSectionIndex(learningCourseSectionIndex)
                                    setLearningMaterial(new LearningMaterialDto())
                                    setLearningMaterialDialogOpen(true)
                                  }}
                                >
                                  ADD
                                </Button>
                              </Grid>

                              <Grid item>
                                <Button
                                  size='small'
                                  color='primary'
                                  onClick={() => {
                                    setLearningCourseSection(learningCourseSection)
                                    setLearningCourseSectionDialogOpen(true)
                                  }}
                                >
                                  EDIT
                                </Button>
                              </Grid>

                              <Grid item>
                                <Button
                                  size='small'
                                  color='secondary'
                                  onClick={() => {
                                    if (
                                      window.confirm(
                                        `Are you sure you want to remove this learning section "${learningCourseSection.title}" and all of its learning materials?`
                                      )
                                    ) {
                                      setFieldValue(
                                        'learningCourseSections',
                                        updateOrder(
                                          values.learningCourseSections.filter(
                                            (_, index) =>
                                              index !== learningCourseSectionIndex
                                          )
                                        )
                                      )
                                    }
                                  }}
                                >
                                  DELETE
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>

                          {learningCourseSection.description && (
                            <Grid item xs={12}>
                              <Typography
                                variant='body2'
                                sx={{
                                  color: '#7F7F7F',
                                  mt: -3,
                                  overflowWrap: 'break-word'
                                }}
                              >
                                {learningCourseSection.description}
                              </Typography>
                            </Grid>
                          )}

                          {learningCourseSection.learningMaterials
                            ?.sort((a, b) => a.order - b.order)
                            .map((learningMaterial, learningMaterialIndex) => (
                              <Grid
                                item
                                xs={12}
                                container
                                alignItems='center'
                                justifyContent='space-between'
                              >
                                <Grid item xs={12} sm={6} container spacing={1}>
                                  <Grid item xs={1} />

                                  <Grid item>
                                    {learningMaterial.learningMaterialType.name ===
                                      'Document' && <Description color='disabled' />}

                                    {learningMaterial.learningMaterialType.name ===
                                      'Quiz' && <Quiz color='disabled' />}

                                    {learningMaterial.learningMaterialType.name ===
                                      'Video' && <OndemandVideo color='disabled' />}
                                  </Grid>

                                  <Grid item>
                                    <Typography variant='body1'>
                                      {Boolean(learningMaterial.name)
                                        ? learningMaterial.name
                                        : learningMaterial.learningMaterialType.name}
                                    </Typography>
                                  </Grid>
                                </Grid>

                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  container
                                  alignItems='center'
                                  spacing={1}
                                  justifyContent='flex-end'
                                >
                                  <Grid item>
                                    <IconButton
                                      onClick={e => {
                                        handleClick(e)

                                        setFieldName(
                                          `learningCourseSections[${learningCourseSectionIndex}].learningMaterials`
                                        )
                                        setList(
                                          values.learningCourseSections[
                                            learningCourseSectionIndex
                                          ].learningMaterials
                                        )
                                        setListIndex(learningMaterialIndex)
                                      }}
                                    >
                                      <UnfoldMore fontSize='small' color='primary' />
                                    </IconButton>
                                  </Grid>

                                  <Grid item>
                                    <Button
                                      size='small'
                                      color='primary'
                                      onClick={() => {
                                        // setLearningCourseSectionIndex(learningCourseSectionIndex);
                                        setLearningMaterial(learningMaterial)
                                        setLearningMaterialDialogOpen(true)
                                        // setLearningCourseSection(learningCourseSection)
                                        // setLearningCourseSectionDialogOpen(true)
                                      }}
                                    >
                                      EDIT
                                    </Button>
                                  </Grid>

                                  <Grid item>
                                    <Button
                                      size='small'
                                      color='secondary'
                                      onClick={() => {
                                        if (
                                          window.confirm(
                                            `Are you sure you want to remove this learning material "${learningMaterial.name}"?`
                                          )
                                        ) {
                                          setFieldValue(
                                            `learningCourseSections[${learningCourseSectionIndex}].learningMaterials`,
                                            updateOrder(
                                              values.learningCourseSections[
                                                learningCourseSectionIndex
                                              ].learningMaterials.filter(
                                                (_, index) =>
                                                  index !== learningMaterialIndex
                                              )
                                            )
                                          )
                                        }
                                      }}
                                    >
                                      DELETE
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))}
                        </>
                      ))}

                    <Menu
                      id='basic-menu'
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button'
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          setFieldValue(fieldName, [...moveItemUp(list, listIndex)])
                          handleClose()
                        }}
                        disabled={listIndex === 0}
                      >
                        <ListItemIcon>
                          <KeyboardArrowUp fontSize='small' />
                        </ListItemIcon>
                        <ListItemText>Up</ListItemText>
                      </MenuItem>

                      <MenuItem
                        onClick={() => {
                          setFieldValue(fieldName, [...moveItemTop(list, listIndex)])
                          handleClose()
                        }}
                        disabled={listIndex === 0}
                      >
                        <ListItemIcon>
                          <KeyboardDoubleArrowUp fontSize='small' />
                        </ListItemIcon>
                        <ListItemText>Top</ListItemText>
                      </MenuItem>

                      <MenuItem
                        onClick={() => {
                          setFieldValue(fieldName, [...moveItemBottom(list, listIndex)])
                          handleClose()
                        }}
                        disabled={listIndex === list.length - 1}
                      >
                        <ListItemIcon>
                          <KeyboardDoubleArrowDown fontSize='small' />
                        </ListItemIcon>
                        <ListItemText>Bottom</ListItemText>
                      </MenuItem>

                      <MenuItem
                        onClick={() => {
                          setFieldValue(fieldName, [...moveItemDown(list, listIndex)])
                          handleClose()
                        }}
                        disabled={listIndex === list.length - 1}
                      >
                        <ListItemIcon>
                          <KeyboardArrowDown fontSize='small' />
                        </ListItemIcon>
                        <ListItemText>Down</ListItemText>
                      </MenuItem>
                    </Menu>
                  </Grid>
                </DetailsTab>

                <DetailsTab value='materials'></DetailsTab>

                <DetailsActions>
                  <Button
                    color='secondary'
                    // disabled={
                    //   isSubmitting ||
                    //   (!COMPANIES_AND_PEOPLE_ADD_EDIT &&
                    //     !COMPANY_CUSTOMER_VENDOR_NUMBER_ADD_EDIT)
                    // }
                    onClick={() => {
                      navigate('/courses')
                    }}
                    variant='text'
                  >
                    BACK
                  </Button>

                  <LoadingButton
                    color='primary'
                    loading={isSubmitting}
                    // disabled={
                    //   isSubmitting ||
                    //   (!COMPANIES_AND_PEOPLE_ADD_EDIT &&
                    //     !COMPANY_CUSTOMER_VENDOR_NUMBER_ADD_EDIT)
                    // }
                    onClick={() => {
                      // Due to an issue with Formik, the form fields need to be manually set as touched when there are errors on submit.
                      // Otherwise, sometimes the field errors won't display even though the validation throws them
                      validateForm().then(errors => {
                        const errorKeys = Object.keys(errors)
                        if (errorKeys.length === 0) {
                          submitForm()
                        } else {
                          setTouched(setNestedObjectValues(errors, true))
                          showFormErrorsPrompt(errors)
                        }
                      })
                    }}
                    variant='contained'
                  >
                    <span>{values.id ? 'UPDATE' : 'CREATE'} COURSE</span>
                  </LoadingButton>
                </DetailsActions>
              </Details>

              <UnsavedChangesPrompt when={dirty} />
            </>
          )
        }}
      </Formik>
    </Layout>
  )
}
