import {
  Document,
  Font,
  Image,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View
} from '@react-pdf/renderer'
import { ReportBillOfLadingDto } from 'dtos'
import { formatDate } from 'utils'

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: process.env.PUBLIC_URL + '/Roboto-Regular.ttf'
    },
    {
      src: process.env.PUBLIC_URL + '/Roboto-Bold.ttf',
      fontWeight: 'bold'
    }
  ]
})

const styles = StyleSheet.create({
  cell: {
    flexDirection: 'row', // Arrange columns horizontally
    justifyContent: 'space-between', // Evenly distribute columns
    textAlign: 'left', // Left-align the text
    width: '100%' // Occupy the entire width of the page
  },
  column: {
    flex: 1,
    padding: 5 // Adjust as needed
  },
  headerCell: {
    flexDirection: 'row', // Arrange columns horizontally
    justifyContent: 'space-between', // Evenly distribute columns
    textAlign: 'left', // Left-align the text
    width: '100%' // Occupy the entire width of the page
  },
  headerCellGreen: {
    color: 'green', // Text color green
    flexDirection: 'row', // Arrange columns horizontally
    fontWeight: 'bold', // Bold font
    justifyContent: 'space-between', // Evenly distribute columns
    textAlign: 'left', // Left-align the text
    width: '100%' // Occupy the entire width of the page
  },
  headerCellWhite: {
    color: 'white', // Text color white
    flexDirection: 'row', // Arrange columns horizontally
    fontWeight: 'bold', // Bold font
    justifyContent: 'space-between', // Evenly distribute columns
    textAlign: 'left', // Left-align the text
    width: '100%' // Occupy the entire width of the page
  },
  horizontalLine: {
    borderBottomColor: 'black',
    borderBottomWidth: 1,
    marginBottom: 5,
    marginTop: 5
  },
  logo: {
    height: 75,
    width: 250
  },
  page: {
    backgroundColor: '#FFFFFF',
    fontFamily: 'Roboto',
    flexDirection: 'column',
    fontSize: 10,
    justifyContent: 'space-between'
  },
  pdf: {
    height: '500px',
    width: '100%'
  },
  section: {
    flexGrow: 1,
    margin: 10,
    padding: 10
  },
  spaceBetween: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  table: {
    alignItems: 'center',
    borderBottomColor: '#000',
    borderBottomStyle: 'solid',
    borderBottomWidth: 0,
    flexDirection: 'row', // Arrange columns horizontally
    justifyContent: 'space-between', // Evenly distribute columns
    width: '100%' // Occupy the entire width of the page
  },
  tableGreenSpace: {
    alignItems: 'center',
    backgroundColor: 'green',
    borderBottomColor: '#000',
    borderBottomStyle: 'solid',
    borderBottomWidth: 0,
    flexDirection: 'row', // Arrange columns horizontally
    justifyContent: 'space-between', // Evenly distribute columns
    width: '100%' // Occupy the entire width of the page
  },
  tableGreenRight: {
    alignItems: 'center',
    backgroundColor: 'green',
    borderBottomColor: '#000',
    borderBottomStyle: 'solid',
    borderBottomWidth: 0,
    flexDirection: 'row', // Arrange columns horizontally
    justifyContent: 'flex-end',
    width: '100%' // Occupy the entire width of the page
  },
  tablePadded: {
    alignItems: 'center',
    borderBottomColor: '#000',
    borderBottomStyle: 'solid',
    borderBottomWidth: 0,
    flexDirection: 'row', // Arrange columns horizontally
    justifyContent: 'space-between', // Evenly distribute columns
    marginTop: '20px',
    width: '100%' // Occupy the entire width of the page
  },
  textBlock: {
    marginBottom: 10
  },
  textBoldGreen: {
    color: 'green',
    fontWeight: 'bold'
  }
})

interface ReportBillOfLadingProps {
  reportBillOfLadingDto: ReportBillOfLadingDto
}

const TableWithPDFButton = ({ reportBillOfLadingDto }: ReportBillOfLadingProps) => {
  return (
    <div>
      <PDFViewer style={styles.pdf}>
        <Document>
          <Page size='LETTER' style={styles.page}>
            <View style={styles.section}>
              <Image src={process.env.PUBLIC_URL + '/nglogo.jpg'} style={styles.logo} />

              <Text style={styles.textBlock}>{''}</Text>

              <Text style={styles.textBlock}>
                <Text style={styles.textBoldGreen}>BOL #</Text>
                {'\n'}
                {reportBillOfLadingDto.displayBillOfLadingNumber}
              </Text>

              <Text style={styles.textBlock}>
                <Text style={styles.textBoldGreen}>Date</Text>
                {'\n'}
                {formatDate(new Date())}
              </Text>

              <View style={styles.horizontalLine} />

              <View style={styles.table}>
                <View style={styles.column}>
                  <Text style={styles.headerCellGreen}>From</Text>
                  <Text style={styles.cell}>JackPack</Text>
                  <Text style={styles.cell}>2100 T A Mansour Blvd</Text>
                  <Text style={styles.cell}>Flint, MI 48532</Text>
                  <Text style={styles.cell}> </Text>
                </View>

                <View style={styles.column}>
                  <Text style={styles.headerCellGreen}>Ship To</Text>

                  <Text style={styles.cell}>{reportBillOfLadingDto.company?.name}</Text>

                  <Text style={styles.cell}>
                    {reportBillOfLadingDto.shipToAddress?.address1}
                  </Text>

                  <Text style={styles.cell}>
                    {reportBillOfLadingDto.shipToAddress?.address2}
                  </Text>

                  <Text style={styles.cell}>
                    {reportBillOfLadingDto.shipToAddress?.city},{' '}
                    {reportBillOfLadingDto.shipToAddress?.state}{' '}
                    {reportBillOfLadingDto.shipToAddress?.postalCode}
                  </Text>
                </View>

                <View style={styles.column}>
                  <Text style={styles.headerCellGreen}>Bill To</Text>
                  <Text style={styles.cell}>{reportBillOfLadingDto.company?.name}</Text>

                  <Text style={styles.cell}>
                    {reportBillOfLadingDto.billToAddress?.address1}
                  </Text>

                  <Text style={styles.cell}>
                    {reportBillOfLadingDto.billToAddress?.address2}
                  </Text>

                  <Text style={styles.cell}>
                    {reportBillOfLadingDto.billToAddress?.city},{' '}
                    {reportBillOfLadingDto.billToAddress?.state}{' '}
                    {reportBillOfLadingDto.billToAddress?.postalCode}
                  </Text>
                </View>
              </View>

              <View style={styles.horizontalLine} />

              <View style={styles.tableGreenSpace}>
                <Text style={styles.headerCellWhite}>Seal #</Text>
                <Text style={styles.headerCellWhite}>Carrier</Text>
                <Text style={styles.headerCellWhite}>Trailer #</Text>
              </View>

              <View style={styles.table}>
                <Text style={styles.cell}>{reportBillOfLadingDto.sealNumber}</Text>
                <Text style={styles.cell}>{reportBillOfLadingDto.carrier}</Text>
                <Text style={styles.cell}>{reportBillOfLadingDto.trailerNumber}</Text>
              </View>

              <Text style={styles.textBlock}>{''}</Text>
              <View style={styles.tableGreenSpace}>
                <Text style={styles.headerCellWhite}>SLI#</Text>
              </View>

              <View style={styles.table}>
                <Text style={styles.cell}>{reportBillOfLadingDto.movementNumber}</Text>
              </View>

              <Text style={styles.textBlock}>{''}</Text>
              <View style={styles.tableGreenSpace}>
                <Text style={styles.headerCellWhite}>Item #</Text>
                <Text style={styles.headerCellWhite}>Description</Text>
                <Text style={styles.headerCellWhite}>Quantity (Pieces)</Text>
                <Text style={styles.headerCellWhite}>Weight</Text>
              </View>

              {reportBillOfLadingDto.shippingJobs?.map(shippingJob => (
                <View style={styles.table}>
                  <Text style={styles.cell}>
                    {shippingJob.job?.vendorOrder?.quoteLineItem?.itemNumber}
                  </Text>
                  <Text style={styles.cell}>
                    {shippingJob.job?.finishedGoodItemDescription}
                  </Text>
                  <Text style={styles.cell}>
                    {shippingJob.shippingItems?.reduce(
                      (accumulator, shippingItem) =>
                        accumulator + shippingItem.finishedGoodsInventory?.quantity!,
                      0
                    ) ?? ''}
                  </Text>
                  <Text style={styles.cell}>{shippingJob.weight}</Text>
                </View>
              ))}

              <Text style={styles.textBlock}>{''}</Text>
              <View style={styles.tableGreenRight}>
                <Text style={styles.cell} />
                <Text style={styles.cell} />
                <Text style={styles.headerCellWhite}>Total Qty</Text>
                <Text style={styles.headerCellWhite}>Total Weight</Text>
              </View>

              <View style={styles.table}>
                <Text style={styles.cell} />
                <Text style={styles.cell} />
                <Text style={styles.cell}>
                  {reportBillOfLadingDto.shippingJobs &&
                    reportBillOfLadingDto.shippingJobs.length > 0 &&
                    reportBillOfLadingDto.shippingJobs.reduce(
                      (shippingJobAccumulator, shippingJob) =>
                        shippingJobAccumulator +
                        (shippingJob.shippingItems?.reduce(
                          (shippingItemAccumulator, shippingItem) =>
                            shippingItemAccumulator +
                            shippingItem.finishedGoodsInventory?.quantity!,
                          0
                        ) ?? 0),
                      0
                    )}
                </Text>
                <Text style={styles.cell}>
                  {reportBillOfLadingDto.shippingJobs?.reduce(
                    (accumulator, shippingJob) => accumulator + (shippingJob.weight ?? 0),
                    0
                  ) ?? ''}
                </Text>
              </View>
            </View>

            <View style={styles.section}>
              <Text style={styles.textBlock}>{''}</Text>
              <View style={styles.table}>
                {reportBillOfLadingDto.isThirdPartyFreightBilling ? (
                  <View style={styles.column}>
                    <Text style={styles.headerCellGreen}>
                      Third Party Freight - Bill To
                    </Text>
                    <Text style={styles.cell}>
                      {reportBillOfLadingDto.thirdPartyFreightBillingAddress}
                    </Text>
                  </View>
                ) : null}

                <View style={styles.column}>
                  <Text style={styles.headerCellGreen}>Special Instructions</Text>

                  <Text style={styles.cell}>
                    {reportBillOfLadingDto.specialInstructions}
                  </Text>
                </View>
              </View>

              <Text style={styles.textBlock}>{''}</Text>

              <View style={styles.table}>
                <Text style={styles.textBlock}>
                  RECEIVED, subject to individual determined rates or contracts that have
                  been agreed upon in writing between the carrier and shipper, if
                  applicable, otherwise to the rates, classifications and rules that have
                  been established by the carrier and are available to the shipper on
                  request. The property described above, in apparent good order, except as
                  noted (contents and condition of contest of packages unknown), marked
                  consigned, and destined as shown above, which said carrier agrees to
                  carry to destination, if on its route, or otherwise deliver to another
                  carrier on the route to destination. Every service to be performed
                  hereunder shall be subject to all bill of lading terms and conditions in
                  the governing classification of the date of shipment. Shipper hereby
                  certifies that he is hereby familiar with all the bill of lading terms
                  and conditions in the governing classification and the said terms and
                  conditions are hereby agreed to by the shipper and accepted for himself
                  and his assigns.
                </Text>
              </View>

              <Text style={styles.textBlock}>{''}</Text>
              <View style={styles.spaceBetween}>
                <Text>
                  Signature:
                  _____________________________________________________________________
                </Text>
                <Text>Date: _________________________</Text>
              </View>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </div>
  )
}

export default TableWithPDFButton
