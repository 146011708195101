import { ApiResponse, RatesPaperDto } from 'dtos'
import apiClient from '.'
import { URIQuery } from 'utils'
import { RatesPaperParameters } from 'parameters'

export const getRatesPaper = (parameters: RatesPaperParameters) =>
  apiClient
    .get<ApiResponse<RatesPaperDto[]>>('/api/rates-paper' + URIQuery({ ...parameters }))
    .then(({ data }) => data)

export const getRatesPaperById = (id: string) =>
  apiClient
    .get<ApiResponse<RatesPaperDto>>('/api/rates-paper/' + id)
    .then(({ data }) => data)

export const createRatesPaper = (values: RatesPaperDto) =>
  apiClient
    .post<ApiResponse<RatesPaperDto>>('/api/rates-paper', values)
    .then(({ data }) => data)

export const updateRatesPapersList = (values: RatesPaperDto[]) =>
  apiClient.put<ApiResponse<boolean>>('/api/rates-paper', values).then(({ data }) => data)
