import apiClient from 'api'
import { ApiResponse, JobDto } from 'dtos'
import {
  JobCenter66RdcToDosParameters,
  JobCenter86RdcToDosParameters,
  JobsParameters
} from 'parameters'
import { URIQuery } from 'utils'

export const getJobs = (parameters: JobsParameters) =>
  apiClient
    .get<ApiResponse<JobDto[]>>('/api/jobs' + URIQuery({ ...parameters }))
    .then(({ data }) => data)

export const getJobCenter86RdcToDos = (parameters: JobCenter86RdcToDosParameters) =>
  apiClient
    .get<ApiResponse<JobDto[]>>(
      '/api/jobs/job-center-86rdc-to-dos' + URIQuery({ ...parameters })
    )
    .then(({ data }) => data)

export const getJobCenter66RdcToDos = (parameters: JobCenter66RdcToDosParameters) =>
  apiClient
    .get<ApiResponse<JobDto[]>>(
      '/api/jobs/job-center-66rdc-to-dos' + URIQuery({ ...parameters })
    )
    .then(({ data }) => data)

export const getJobPriorityListByDateAndId = (id: string, scheduleDate: string) =>
  apiClient
    .get<ApiResponse<JobDto[]>>(
      '/api/jobs/priority-list' + URIQuery({ id, scheduleDate })
    )
    .then(({ data }) => data)

export const getJobById = (id: string) =>
  apiClient.get<ApiResponse<JobDto>>('/api/jobs/' + id).then(({ data }) => data)

export const getJobCenter86RdcToDoById = (id: string) =>
  apiClient
    .get<ApiResponse<JobDto>>('/api/jobs/job-center-86rdc-to-dos/' + id)
    .then(({ data }) => data)

export const getJobCenter66RdcToDoById = (id: string) =>
  apiClient
    .get<ApiResponse<JobDto>>('/api/jobs/job-center-66rdc-to-dos/' + id)
    .then(({ data }) => data)

export const createJob = (values: JobDto) =>
  apiClient.post<ApiResponse<JobDto>>('/api/jobs', values).then(({ data }) => data)

export const updateJob = (values: JobDto) =>
  apiClient.put<ApiResponse<JobDto>>('/api/jobs', values).then(({ data }) => data)
