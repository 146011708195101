import { ReadMoreOutlined, SearchOutlined } from '@mui/icons-material'
import { Button, IconButton, InputAdornment, TextField, Tooltip } from '@mui/material'
import apiClient from 'api'
import { Layout } from 'components/_template'
import { List, ListHeaderCard, ListItemCard } from 'components/_template/list'
import { LearningCourseDto } from 'dtos'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { URIQuery, useDebounce } from 'utils'
import { errorHandling } from 'constantValues'

export default function LearningCourseDetails() {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const getLearningCourses = useDebounce(
    (page: number, pageSize: number, search: string, activeOnly: boolean) => {
      setIsGettingLearningCourses(true)
      apiClient
        .get('/api/learning-courses' + URIQuery({ page, pageSize, search, activeOnly }))
        .then(res => {
          setLearningCourses(res.data.value)
          setCount(res.data.totalCount)
        })
        .catch((errors: string[]) => {
          errorHandling(errors)
        })
        .finally(() => {
          setIsGettingLearningCourses(false)
        })
    },
    300
  )

  useEffect(() => {
    apiClient.get('/api/learning-material-types')
  }, [])

  const [count, setCount] = useState<number>(0)
  const [isGettingLearningCourses, setIsGettingLearningCourses] = useState<boolean>(false)
  const [learningCourses, setLearningCourses] = useState<LearningCourseDto[]>([])
  const [parameters, setParameters] = useState<{
    page: number
    pageSize: number
    search: string
    activeOnly: boolean
  }>({ page: 0, pageSize: 10, search: '', activeOnly: true })

  useEffect(() => {
    getLearningCourses(
      parameters.page,
      parameters.pageSize,
      parameters.search,
      parameters.activeOnly
    )

    return () => {}
  }, [parameters])

  return (
    <Layout title='Course Administration' isLoading={isGettingLearningCourses}>
      <List
        pagination={{
          page: parameters.page,
          pageSize: parameters.pageSize,
          count,
          onPageChange: page => setParameters({ ...parameters, page }),
          onPageSizeChange: pageSize => setParameters({ ...parameters, pageSize })
        }}
        header={
          <ListHeaderCard
            title='Courses'
            filters={[
              <TextField
                data-search
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <SearchOutlined fontSize='small' />
                    </InputAdornment>
                  )
                }}
                label='Search'
                name='search'
                onChange={e => setParameters({ ...parameters, search: e.target.value })}
                size='small'
                type='search'
                value={parameters.search}
              />
              // <FormControlLabel
              //   control={
              //     <Checkbox
              //       value={parameters.activeOnly}
              //       checked={parameters.activeOnly}
              //       onChange={e =>
              //         setParameters({ ...parameters, activeOnly: e.target.checked })
              //       }
              //     />
              //   }
              //   label='Active only'
              // />
            ]}
            actions={[
              <Tooltip title='Add course' placement='bottom'>
                <Button
                  onClick={() => navigate('/courses/new')}
                  variant='contained'
                  size='medium'
                >
                  ADD NEW
                </Button>
              </Tooltip>
            ]}
          />
        }
      >
        {learningCourses.map(learningCourse => (
          <ListItemCard
            title={learningCourse.name}
            subtitle={learningCourse.description}
            actions={[
              <Tooltip title={`View ${learningCourse.name}`}>
                <IconButton
                  onClick={() => navigate('/courses/' + learningCourse.id)}
                  color='primary'
                  // size='large'
                >
                  <ReadMoreOutlined fontSize='large' />
                </IconButton>
              </Tooltip>
            ]}
          ></ListItemCard>
        ))}
      </List>
    </Layout>
  )
}
