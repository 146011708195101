import {
  ApiResponse,
  OpportunityDto,
  ReportBillOfLadingDto,
  ReportPurchaseOrderDto,
  ReportQuoteDto
} from 'dtos'
import { URIQuery } from 'utils'
import apiClient from '.'
import { format, formats } from 'theme'

var date = new Date()

export const getQuoteReportById = (quoteId: string, revisionNumber?: number) => {
  return apiClient
    .get<ApiResponse<ReportQuoteDto>>(
      `/api/reports/quote-by-id/${quoteId}` + URIQuery({ revisionNumber })
    )
    .then(({ data }) => data)
}

export const getQuoteReportByLineItemId = (quoteLineItemId: string) => {
  return apiClient
    .get<ApiResponse<ReportQuoteDto>>(
      `/api/reports/quote-by-line-item-id/${quoteLineItemId}`
    )
    .then(({ data }) => data)
}

export const getPurchaseOrderReport = (vendorOrderId: string) =>
  apiClient
    .get<ApiResponse<ReportPurchaseOrderDto>>(
      `/api/reports/purchase-order/${vendorOrderId}`
    )
    .then(({ data }) => data)

// This route is used for both ReportTravelerExceptLastPage and ReportTravelerLastPage
export const getTravelerReport = (jobId: string) =>
  apiClient
    .get<ApiResponse<OpportunityDto>>(`/api/reports/traveler/${jobId}`)
    .then(({ data }) => data)

// This route is used for ReportTravelerLastPage for incrementing the Bundle #
export const getTravelerReportStopCount = (jobId: string) =>
  apiClient
    .get<ApiResponse<number>>(`/api/reports/traveler/stop-count/${jobId}`)
    .then(({ data }) => data)

export const getBillOfLadingReportByShippingId = (shippingId: string) =>
  apiClient
    .get<ApiResponse<ReportBillOfLadingDto>>(
      '/api/reports/bill-of-lading?shippingId=' + shippingId
    )
    .then(({ data }) => data)

// #region CSV Reports
export const getCustomerOrdersCsvReport = () =>
  apiClient.get('/api/reports/customer-orders-csv').then(res => {
    const blob = new Blob([atob(res.data.value)], { type: 'text/csv' })

    const a = document.createElement('a')
    const blobUrl = window.URL.createObjectURL(blob)
    a.href = blobUrl
    a.setAttribute('download', `Customer Orders Report - ${format(date, formats.date)}`)
    document.body.appendChild(a)
    a.click()
  })

export const getFinishedGoodsInventoryAdjustmentsCsvReport = () =>
  apiClient.get('/api/reports/finished-goods-inventory-adjustments-csv').then(res => {
    const blob = new Blob([atob(res.data.value)], { type: 'text/csv' })

    const a = document.createElement('a')
    const blobUrl = window.URL.createObjectURL(blob)
    a.href = blobUrl
    a.setAttribute(
      'download',
      `Finished Goods Inventory Adjustments Report - ${format(date, formats.date)}`
    )
    document.body.appendChild(a)
    a.click()
  })

export const getFinishedGoodsInventoryStockCsvReport = () =>
  apiClient.get('/api/reports/finished-goods-inventory-stock-csv').then(res => {
    const blob = new Blob([atob(res.data.value)], { type: 'text/csv' })

    const a = document.createElement('a')
    const blobUrl = window.URL.createObjectURL(blob)
    a.href = blobUrl
    a.setAttribute(
      'download',
      `Finished Goods Inventory Stock Report - ${format(date, formats.date)}`
    )
    document.body.appendChild(a)
    a.click()
  })

export const getRawInventoryAdjustmentsCsvReport = () =>
  apiClient.get('/api/reports/raw-inventory-adjustments-csv').then(res => {
    const blob = new Blob([atob(res.data.value)], { type: 'text/csv' })

    const a = document.createElement('a')
    const blobUrl = window.URL.createObjectURL(blob)
    a.href = blobUrl
    a.setAttribute(
      'download',
      `Raw Inventory Adjustments Report - ${format(date, formats.date)}`
    )
    document.body.appendChild(a)
    a.click()
  })

export const getRawInventoryStockCsvReport = () =>
  apiClient.get('/api/reports/raw-inventory-stock-csv').then(res => {
    const blob = new Blob([atob(res.data.value)], { type: 'text/csv' })

    const a = document.createElement('a')
    const blobUrl = window.URL.createObjectURL(blob)
    a.href = blobUrl
    a.setAttribute(
      'download',
      `Raw Inventory Stock Report - ${format(date, formats.date)}`
    )
    document.body.appendChild(a)
    a.click()
  })

export const getReceivingCsvReport = () =>
  apiClient.get('/api/reports/receiving-csv').then(res => {
    const blob = new Blob([atob(res.data.value)], { type: 'text/csv' })

    const a = document.createElement('a')
    const blobUrl = window.URL.createObjectURL(blob)
    a.href = blobUrl
    a.setAttribute('download', `Receiving Report - ${format(date, formats.date)}`)
    document.body.appendChild(a)
    a.click()
  })

export const getShippingCsvReport = () =>
  apiClient.get('/api/reports/shipping-csv').then(res => {
    const blob = new Blob([atob(res.data.value)], { type: 'text/csv' })

    const a = document.createElement('a')
    const blobUrl = window.URL.createObjectURL(blob)
    a.href = blobUrl
    a.setAttribute('download', `Shipping Report - ${format(date, formats.date)}`)
    document.body.appendChild(a)
    a.click()
  })

export const getVendorOrdersCsvReport = () =>
  apiClient.get('/api/reports/vendor-orders-csv').then(res => {
    const blob = new Blob([atob(res.data.value)], { type: 'text/csv' })

    const a = document.createElement('a')
    const blobUrl = window.URL.createObjectURL(blob)
    a.href = blobUrl
    a.setAttribute('download', `Vendor Orders Report - ${format(date, formats.date)}`)
    document.body.appendChild(a)
    a.click()
  })

export const getVendorUsageCsvReport = () =>
  apiClient.get('/api/reports/vendor-usage-csv').then(res => {
    const blob = new Blob([atob(res.data.value)], { type: 'text/csv' })

    const a = document.createElement('a')
    const blobUrl = window.URL.createObjectURL(blob)
    a.href = blobUrl
    a.setAttribute('download', `Vendor Usage Report - ${format(date, formats.date)}`)
    document.body.appendChild(a)
    a.click()
  })
// #endregion
