import { ReadMoreOutlined, SearchOutlined } from '@mui/icons-material'
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material'
import * as api from 'api'
import { Layout } from 'components/_template'
import { ExplanationAccordion } from 'components/_template/accordion'
import { List, ListHeaderCard, ListItemCard } from 'components/_template/list'
import { JobDto } from 'dtos'
import { useSnackbar } from 'notistack'
import { FinishedGoodsInventoryParameters } from 'parameters'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDebounce } from 'utils'
import { errorHandling } from 'constantValues'

const sessionStorageKey = 'finishedGoodsInventory:parameters'

export default function FinishedGoods() {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const defaultParameters: FinishedGoodsInventoryParameters = {
    page: 0,
    pageSize: 10,
    search: ''
  }

  const [count, setCount] = useState<number>(0)
  const [isFinishedGoodsInventoryLoading, setIsFinishedGoodsInventoryLoading] =
    useState<boolean>(false)
  const [parameters, setParameters] = useState<FinishedGoodsInventoryParameters>(
    sessionStorage.getItem(sessionStorageKey)
      ? JSON.parse(sessionStorage.getItem(sessionStorageKey)!)
      : defaultParameters
  )
  const [jobs, setJobs] = useState<JobDto[]>([])

  const getFinishedGoodsInventory = useDebounce(
    (parameters: FinishedGoodsInventoryParameters) => {
      setIsFinishedGoodsInventoryLoading(true)
      sessionStorage.setItem(sessionStorageKey, JSON.stringify(parameters))
      api
        .getFinishedGoodsInventory(parameters)
        .then(res => {
          setJobs(res.value)
          setCount(res.totalCount!)
        })
        .catch((errors: string[]) => {
          errorHandling(errors)
        })
        .finally(() => {
          setIsFinishedGoodsInventoryLoading(false)
        })
    },
    300
  )

  useEffect(() => {
    getFinishedGoodsInventory(parameters)
  }, [parameters])

  return (
    <Layout title='Finished Goods Inventory' isLoading={isFinishedGoodsInventoryLoading}>
      <List
        pagination={{
          page: parameters.page,
          pageSize: parameters.pageSize,
          count,
          onPageChange: page => setParameters({ ...parameters, page }),
          onPageSizeChange: pageSize => setParameters({ ...parameters, pageSize })
        }}
        header={<ListHeaderCard title='Finished Goods Inventory' actions={[]} />}
      >
        <ListItemCard>
          <Grid container spacing={2} alignItems='center' justifyContent='space-between'>
            <Grid item xs={12} sm={3}>
              <TextField
                data-search
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <SearchOutlined fontSize='small' />
                    </InputAdornment>
                  )
                }}
                label='Search'
                onChange={e => {
                  setParameters({ ...parameters, search: e.target.value })
                }}
                size='small'
                type='search'
                value={parameters.search}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Button
                color='primary'
                size='medium'
                variant='text'
                onClick={() => {
                  setParameters(defaultParameters)
                }}
              >
                RESET FILTERS
              </Button>
            </Grid>
          </Grid>
        </ListItemCard>

        {jobs.map((job, index) => (
          <ListItemCard
            key={job.id}
            title={job?.vendorOrder?.company?.name}
            actions={[
              <IconButton
                onClick={() => navigate(`/finished-goods-inventory/${job.id}`)}
                color='primary'
              >
                <ReadMoreOutlined fontSize='large' />
              </IconButton>
            ]}
          >
            <Typography variant='body2' sx={{ color: '#7F7F7F', fontWeight: 600 }}>
              Job #: {job?.jobNumber}
            </Typography>
          </ListItemCard>
        ))}

        <Grid item xs={12}>
          <ExplanationAccordion>
            Search checks if the search term is contained in any of the individual data
            values shown on the list card. It does not enable searching by labels, chips,
            or special characters.||A card is shown for each job with completed bundles
            (bundles that have been scanned at the Bander).||Cards are listed in order by
            Company Name then by Job #.
          </ExplanationAccordion>
        </Grid>
      </List>
    </Layout>
  )
}
