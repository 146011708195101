import { Button, Grid, MenuItem, TextField } from '@mui/material'
import * as api from 'api'
import { Layout } from 'components/_template'
import { ExplanationAccordion } from 'components/_template/accordion'
import { List, ListHeaderCard } from 'components/_template/list'
import { useAuthContext } from 'context'
import { enqueueSnackbar } from 'notistack'
import { useState } from 'react'
import { errorHandling } from 'constantValues'

const reports = {
  customerOrdersCsv: 'CUSTOMER_ORDERS_CSV',
  finishedGoodsInventoryAdjustmentsCsv: 'FINISHED_GOODS_INVENTORY_ADJUSTMENTS_CSV',
  finishedGoodsInventoryStockCsv: 'FINISHED_GOODS_INVENTORY_STOCK_CSV',
  rawInventoryAdjustmentsCsv: 'RAW_INVENTORY_ADJUSTMENTS_CSV',
  rawInventoryStockCsv: 'RAW_INVENTORY_STOCK_CSV',
  receivingCsv: 'RECEIVING_CSV',
  shippingCsv: 'SHIPPING_CSV',
  vendorOrdersCsv: 'VENDOR_ORDERS_CSV',
  vendorUsageCsv: 'VENDOR_USAGE_CSV'
}

export default function Reports() {
  const {
    CUSTOMER_ORDERS_REPORT,
    FINISHED_GOODS_INVENTORY_ADJUSTMENTS_REPORT,
    FINISHED_GOODS_INVENTORY_STOCK_REPORT,
    RAW_INVENTORY_ADJUSTMENTS_REPORT,
    RAW_INVENTORY_STOCK_REPORT,
    RECEIVING_REPORT,
    SHIPPING_REPORT,
    VENDOR_ORDERS_REPORT,
    VENDOR_USAGE_REPORT
  } = useAuthContext()

  const [isReportsLoading, setIsReportsLoading] = useState<boolean>(false)
  const [report, setReport] = useState<string>('')

  const onLoadReport = async (report: string) => {
    switch (report) {
      case reports.customerOrdersCsv:
        setIsReportsLoading(true)
        api
          .getCustomerOrdersCsvReport()
          .then(() => {
            enqueueSnackbar('Customer Orders CSV Report Loaded Successfully!', {
              variant: 'success'
            })
          })
          .catch((errors: string[]) => {
            errorHandling(errors)
          })
          .finally(() => {
            setIsReportsLoading(false)
          })
        break
      case reports.finishedGoodsInventoryAdjustmentsCsv:
        setIsReportsLoading(true)
        api
          .getFinishedGoodsInventoryAdjustmentsCsvReport()
          .then(() => {
            enqueueSnackbar(
              'Finished Goods Inventory Adjustments CSV Report Loaded Successfully!',
              {
                variant: 'success'
              }
            )
          })
          .catch((errors: string[]) => {
            errorHandling(errors)
          })
          .finally(() => {
            setIsReportsLoading(false)
          })
        break
      case reports.finishedGoodsInventoryStockCsv:
        setIsReportsLoading(true)
        api
          .getFinishedGoodsInventoryStockCsvReport()
          .then(() => {
            enqueueSnackbar(
              'Finished Goods Inventory Stock CSV Report Loaded Successfully!',
              {
                variant: 'success'
              }
            )
          })
          .catch((errors: string[]) => {
            errorHandling(errors)
          })
          .finally(() => {
            setIsReportsLoading(false)
          })
        break
      case reports.rawInventoryAdjustmentsCsv:
        setIsReportsLoading(true)
        api
          .getRawInventoryAdjustmentsCsvReport()
          .then(() => {
            enqueueSnackbar('Raw Inventory Adjustments CSV Report Loaded Successfully!', {
              variant: 'success'
            })
          })
          .catch((errors: string[]) => {
            errorHandling(errors)
          })
          .finally(() => {
            setIsReportsLoading(false)
          })
        break
      case reports.rawInventoryStockCsv:
        setIsReportsLoading(true)
        api
          .getRawInventoryStockCsvReport()
          .then(() => {
            enqueueSnackbar('Raw Inventory Stock CSV Report Loaded Successfully!', {
              variant: 'success'
            })
          })
          .catch((errors: string[]) => {
            errorHandling(errors)
          })
          .finally(() => {
            setIsReportsLoading(false)
          })
        break
      case reports.receivingCsv:
        setIsReportsLoading(true)
        api
          .getReceivingCsvReport()
          .then(() => {
            enqueueSnackbar('Receiving CSV Report Loaded Successfully!', {
              variant: 'success'
            })
          })
          .catch((errors: string[]) => {
            errorHandling(errors)
          })
          .finally(() => {
            setIsReportsLoading(false)
          })
        break
      case reports.shippingCsv:
        setIsReportsLoading(true)
        api
          .getShippingCsvReport()
          .then(() => {
            enqueueSnackbar('Shipping CSV Report Loaded Successfully!', {
              variant: 'success'
            })
          })
          .catch((errors: string[]) => {
            errorHandling(errors)
          })
          .finally(() => {
            setIsReportsLoading(false)
          })
        break
      case reports.vendorOrdersCsv:
        setIsReportsLoading(true)
        api
          .getVendorOrdersCsvReport()
          .then(() => {
            enqueueSnackbar('Vendor Orders CSV Report Loaded Successfully!', {
              variant: 'success'
            })
          })
          .catch((errors: string[]) => {
            errorHandling(errors)
          })
          .finally(() => {
            setIsReportsLoading(false)
          })
        break
      case reports.vendorUsageCsv:
        setIsReportsLoading(true)
        api
          .getVendorUsageCsvReport()
          .then(() => {
            enqueueSnackbar('Vendor Usage CSV Report Loaded Successfully!', {
              variant: 'success'
            })
          })
          .catch((errors: string[]) => {
            errorHandling(errors)
          })
          .finally(() => {
            setIsReportsLoading(false)
          })
        break
    }
  }

  return (
    <Layout title='Reports' isLoading={isReportsLoading}>
      <List header={<ListHeaderCard title='Reports' />}>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={6}>
            <TextField
              disabled={isReportsLoading}
              fullWidth
              label='Select Report'
              name='report'
              onChange={e => {
                setReport(e.target.value)
              }}
              select
              value={report || ''}
            >
              {CUSTOMER_ORDERS_REPORT && (
                <MenuItem
                  key={reports.customerOrdersCsv}
                  value={reports.customerOrdersCsv}
                >
                  Customer Orders CSV Report
                </MenuItem>
              )}
              {FINISHED_GOODS_INVENTORY_ADJUSTMENTS_REPORT && (
                <MenuItem
                  key={reports.finishedGoodsInventoryAdjustmentsCsv}
                  value={reports.finishedGoodsInventoryAdjustmentsCsv}
                >
                  Finished Goods Inventory Adjustments CSV Report
                </MenuItem>
              )}
              {FINISHED_GOODS_INVENTORY_STOCK_REPORT && (
                <MenuItem
                  key={reports.finishedGoodsInventoryStockCsv}
                  value={reports.finishedGoodsInventoryStockCsv}
                >
                  Finished Goods Inventory Stock CSV Report
                </MenuItem>
              )}
              {RAW_INVENTORY_ADJUSTMENTS_REPORT && (
                <MenuItem
                  key={reports.rawInventoryAdjustmentsCsv}
                  value={reports.rawInventoryAdjustmentsCsv}
                >
                  Raw Inventory Adjustments CSV Report
                </MenuItem>
              )}
              {RAW_INVENTORY_STOCK_REPORT && (
                <MenuItem
                  key={reports.rawInventoryStockCsv}
                  value={reports.rawInventoryStockCsv}
                >
                  Raw Inventory Stock CSV Report
                </MenuItem>
              )}
              {RECEIVING_REPORT && (
                <MenuItem key={reports.receivingCsv} value={reports.receivingCsv}>
                  Receiving CSV Report
                </MenuItem>
              )}
              {SHIPPING_REPORT && (
                <MenuItem key={reports.shippingCsv} value={reports.shippingCsv}>
                  Shipping CSV Report
                </MenuItem>
              )}
              {VENDOR_ORDERS_REPORT && (
                <MenuItem key={reports.vendorOrdersCsv} value={reports.vendorOrdersCsv}>
                  Vendor Orders CSV Report
                </MenuItem>
              )}
              {VENDOR_USAGE_REPORT && (
                <MenuItem key={reports.vendorUsageCsv} value={reports.vendorUsageCsv}>
                  Vendor Usage CSV Report
                </MenuItem>
              )}
            </TextField>
          </Grid>
          <Grid item xs={2} justifyContent='center'>
            <Button
              variant='contained'
              onClick={() => {
                onLoadReport(report)
              }}
            >
              RUN
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ExplanationAccordion>
            After selecting a report from the dropdown and clicking the "RUN" button, the
            selected report will be downloaded as a .csv file.
          </ExplanationAccordion>
        </Grid>
      </List>
    </Layout>
  )
}
