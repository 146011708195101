import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import Typography from '@mui/material/Typography'
import apiClient, { getDepartments } from 'api'
import { ListboxComponentCompany, StyledPopper, UnsavedChangesPrompt } from 'components'
import { Layout } from 'components/_template'
import { ExplanationAccordion } from 'components/_template/accordion'
import {
  Details,
  DetailsActions,
  DetailsForm,
  DetailsHeaderCard,
  DetailsTab
} from 'components/_template/details'
import { showFormErrorsPrompt } from 'components/_template/form/FormErrorsPrompt'
import { useAuthContext } from 'context'
import {
  ApiResponse,
  CompanyDto,
  DepartmentDto,
  PermissionDto,
  PersonDto,
  peopleValidationSchema
} from 'dtos'
import { Formik, setNestedObjectValues } from 'formik'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { formats } from 'theme'
import { errorHandling } from 'constantValues'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

export default function PersonDetails() {
  // #region hooks
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const { id } = useParams()
  const { COMPANIES_AND_PEOPLE_ADD_EDIT, USERS_ADD_EDIT } = useAuthContext()
  // #endregion

  // #region useState
  const [companies, setCompanies] = useState<CompanyDto[]>([])
  const [departments, setDepartments] = useState<DepartmentDto[]>([])
  const [permissions, setPermissions] = useState<PermissionDto[]>([])
  const [initialValues, setInitialValues] = useState<PersonDto>(new PersonDto())
  const [isGettingPerson, setIsGettingPerson] = useState<boolean>(false)
  // #endregion

  // #region useEffect
  useEffect(() => {
    apiClient
      .get<ApiResponse<CompanyDto[]>>('/api/companies?pageSize=2000')
      .then(({ data: { value } }) => {
        setCompanies(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })

    getDepartments()
      .then(({ value }) => {
        setDepartments(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })

    apiClient
      .get<ApiResponse<PermissionDto[]>>('/api/permissions')
      .then(({ data: { value } }) => {
        setPermissions(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
  }, [])

  useEffect(() => {
    if (id && id.toLowerCase() !== 'new' && id.toLowerCase() !== 'undefined') {
      setIsGettingPerson(true)
      apiClient
        .get<ApiResponse<PersonDto>>('/api/people/' + id)
        .then(({ data: { value } }) => {
          setInitialValues(value)
        })
        .catch((errors: string[]) => {
          errorHandling(errors)
        })
        .finally(() => {
          setIsGettingPerson(false)
        })
    } else {
      setInitialValues(new PersonDto())
    }
  }, [id])
  // #endregion

  const sendPasswordReset = () => {
    apiClient
      .post<ApiResponse<string>>('/api/account/forgot-password', {
        email: initialValues.email
      })
      .then(({ data: { value } }) => {
        enqueueSnackbar(value, { variant: 'success' })
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
  }

  return (
    <Layout title='Person Details' isLoading={isGettingPerson}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validateOnChange
        validateOnBlur
        validationSchema={peopleValidationSchema}
        onSubmit={async (values, submitProps) => {
          await (values.id ? apiClient.put : apiClient.post)('/api/people', values)
            .then(res => {
              enqueueSnackbar(`${values.firstName} ${values.lastName} has been saved!`, {
                variant: 'success'
              })

              setInitialValues(res.data.value)
              submitProps.resetForm({ values: res.data.value })

              if (!values.id) {
                setTimeout(() => {
                  navigate('/people/' + res.data.value.id, { replace: true })
                }, 100)
              }
            })
            .catch((errors: string[]) => {
              errorHandling(errors)
            })
        }}
      >
        {({
          dirty,
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          setTouched,
          submitForm,
          touched,
          validateForm,
          values
        }) => {
          let personDetailsTabErrors = Boolean(
            errors.firstName ||
              errors.lastName ||
              errors.company ||
              errors.title ||
              errors.email ||
              errors.mobilePhone ||
              errors.workPhone ||
              errors.isActive
          )
          let accountSetupTabErrors = Boolean(
            errors.allowUserToSignIn ||
              errors.allowEmailSignIn ||
              errors.allowUsernameSignIn ||
              errors.username ||
              errors.signInPin ||
              errors.permissions ||
              errors.isUser
          )
          let humanResourcesTabErrors = Boolean(
            errors.employmentStartDate || errors.employmentEndDate || errors.departments
          )

          return (
            <>
              <Details
                header={<DetailsHeaderCard title='Person Details' />}
                tabs={[
                  {
                    value: 'details',
                    label: 'PERSON DETAILS',
                    error: personDetailsTabErrors
                  },
                  {
                    value: 'account-setup',
                    label: 'ACCOUNT SETUP',
                    // disabled: !USERS_ADD_EDIT,
                    error: accountSetupTabErrors
                  },
                  {
                    value: 'human-resources',
                    label: 'HUMAN RESOURCES',
                    error: humanResourcesTabErrors
                  }
                ]}
                onSubmit={handleSubmit}
              >
                <DetailsTab value='details'>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <DetailsForm>
                        <Grid container spacing={2} alignItems='center'>
                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                COMPANIES_AND_PEOPLE_ADD_EDIT
                                  ? ''
                                  : 'Must have "Companies and people add/edit" permission'
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={isSubmitting || !COMPANIES_AND_PEOPLE_ADD_EDIT}
                                error={Boolean(touched.firstName && errors.firstName)}
                                fullWidth
                                helperText={touched.firstName && errors.firstName}
                                label='First Name'
                                name='firstName'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values?.firstName ?? ''}
                              />
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                COMPANIES_AND_PEOPLE_ADD_EDIT
                                  ? ''
                                  : 'Must have "Companies and people add/edit" permission'
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={isSubmitting || !COMPANIES_AND_PEOPLE_ADD_EDIT}
                                error={Boolean(touched.lastName && errors.lastName)}
                                fullWidth
                                helperText={touched.lastName && errors.lastName}
                                label='Last Name'
                                name='lastName'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values?.lastName ?? ''}
                              />
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                COMPANIES_AND_PEOPLE_ADD_EDIT
                                  ? ''
                                  : 'Must have "Companies and people add/edit" permission'
                              }
                              placement='bottom'
                            >
                              <Autocomplete
                                disabled={isSubmitting || !COMPANIES_AND_PEOPLE_ADD_EDIT}
                                id='company'
                                disableListWrap
                                getOptionLabel={option => option.name}
                                isOptionEqualToValue={(option, value) => {
                                  return option.id === value.id
                                }}
                                ListboxComponent={ListboxComponentCompany}
                                onChange={(_e, value) => {
                                  setFieldValue('company', value)
                                }}
                                options={companies}
                                PopperComponent={StyledPopper}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    label='Company'
                                    error={Boolean(touched.company && errors.company)}
                                    helperText={touched.company && errors.company}
                                  />
                                )}
                                renderOption={(props, option, state) =>
                                  [props, option, state.index] as React.ReactNode
                                }
                                // The Autocomplete is only blank when the value is null, NOT undefined, so this must be "values.company ?? null"
                                value={values.company ?? null}
                              />
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                COMPANIES_AND_PEOPLE_ADD_EDIT
                                  ? ''
                                  : 'Must have "Companies and people add/edit" permission'
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={isSubmitting || !COMPANIES_AND_PEOPLE_ADD_EDIT}
                                error={Boolean(touched.title && errors.title)}
                                fullWidth
                                helperText={touched.title && errors.title}
                                label='Title'
                                name='title'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder='Project Manager'
                                value={values?.title ?? ''}
                              />
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                COMPANIES_AND_PEOPLE_ADD_EDIT
                                  ? ''
                                  : 'Must have "Companies and people add/edit" permission'
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={isSubmitting || !COMPANIES_AND_PEOPLE_ADD_EDIT}
                                error={Boolean(touched.email && errors.email)}
                                fullWidth
                                helperText={touched.email && errors.email}
                                label='Email'
                                name='email'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values?.email ?? ''}
                              />
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                COMPANIES_AND_PEOPLE_ADD_EDIT
                                  ? ''
                                  : 'Must have "Companies and people add/edit" permission'
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={isSubmitting || !COMPANIES_AND_PEOPLE_ADD_EDIT}
                                error={Boolean(touched.mobilePhone && errors.mobilePhone)}
                                fullWidth
                                helperText={touched.mobilePhone && errors.mobilePhone}
                                label='Mobile No.'
                                name='mobilePhone'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder='+1 (123) 456-8790'
                                value={values?.mobilePhone ?? ''}
                              />
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                COMPANIES_AND_PEOPLE_ADD_EDIT
                                  ? ''
                                  : 'Must have "Companies and people add/edit" permission'
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={isSubmitting || !COMPANIES_AND_PEOPLE_ADD_EDIT}
                                error={Boolean(touched.workPhone && errors.workPhone)}
                                fullWidth
                                helperText={touched.workPhone && errors.workPhone}
                                label='Office No.'
                                name='workPhone'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder='+1 (123) 456-8790'
                                value={values?.workPhone ?? ''}
                              />
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                COMPANIES_AND_PEOPLE_ADD_EDIT
                                  ? ''
                                  : 'Must have "Companies and people add/edit" permission'
                              }
                              placement='bottom'
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.isActive}
                                    disabled={
                                      isSubmitting || !COMPANIES_AND_PEOPLE_ADD_EDIT
                                    }
                                    name='isActive'
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.isActive}
                                  />
                                }
                                label='Active'
                              />
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </DetailsForm>
                    </Grid>

                    <Grid item xs={12}>
                      <DetailsForm>
                        <Grid item xs={12}>
                          <ExplanationAccordion>
                            Users need the "Companies and People add/edit" permission to
                            access the Account Setup tab.||Unchecking the Active checkbox
                            unchecks the Access to Spud ERP checkbox and clears the
                            person's Username, Sign-In PIN, and all permissions, if
                            present.
                          </ExplanationAccordion>
                        </Grid>
                      </DetailsForm>
                    </Grid>
                  </Grid>
                </DetailsTab>

                <DetailsTab value='account-setup'>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <DetailsForm>
                        <Grid container spacing={1}>
                          <Grid
                            item
                            xs={12}
                            container
                            alignItems='center'
                            justifyContent='space-between'
                          >
                            <Grid item xs={12}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#2780E3', fontWeight: 600 }}
                              >
                                Spud ERP Access
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid container item spacing={2} xs={12}>
                            <Grid item xs={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.allowUserToSignIn}
                                    disabled={isSubmitting /* || !USERS_ADD_EDIT*/}
                                    onChange={() => {
                                      setFieldValue(
                                        'allowUserToSignIn',
                                        !values.allowUserToSignIn
                                      )
                                    }}
                                  />
                                }
                                label='Access to Spud ERP'
                              />
                            </Grid>

                            {values.allowUserToSignIn ? (
                              <Grid item xs={12}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.allowEmailSignIn}
                                      disabled={isSubmitting /* || !USERS_ADD_EDIT*/}
                                      onChange={() => {
                                        setFieldValue(
                                          'allowEmailSignIn',
                                          !values.allowEmailSignIn
                                        )
                                      }}
                                    />
                                  }
                                  label='Allow Email/Password Sign-In'
                                />

                                <Button
                                  disabled={
                                    isSubmitting ||
                                    !initialValues.allowUserToSignIn ||
                                    !initialValues.allowEmailSignIn /* ||
                                    !USERS_ADD_EDIT*/
                                  }
                                  onClick={sendPasswordReset}
                                  variant='text'
                                >
                                  SEND PASSWORD RESET
                                </Button>
                              </Grid>
                            ) : null}

                            {values.allowUserToSignIn ? (
                              <Grid item xs={12}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.allowUsernameSignIn}
                                      disabled={isSubmitting /* || !USERS_ADD_EDIT*/}
                                      onChange={() => {
                                        setFieldValue(
                                          'allowUsernameSignIn',
                                          !values.allowUsernameSignIn
                                        )
                                      }}
                                    />
                                  }
                                  label='Allow Username/PIN Sign-In'
                                />
                              </Grid>
                            ) : null}

                            {values.allowUserToSignIn && values.allowUsernameSignIn ? (
                              <Grid item xs={12}>
                                <TextField
                                  disabled={isSubmitting /* || !USERS_ADD_EDIT*/}
                                  error={Boolean(touched.username && errors.username)}
                                  fullWidth
                                  helperText={touched.username && errors.username}
                                  label='Username'
                                  name='username'
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values?.username ?? ''}
                                />
                              </Grid>
                            ) : null}

                            {values.allowUserToSignIn && values.allowUsernameSignIn ? (
                              <Grid item xs={12}>
                                <TextField
                                  disabled={isSubmitting /* || !USERS_ADD_EDIT*/}
                                  error={Boolean(touched.signInPin && errors.signInPin)}
                                  fullWidth
                                  helperText={touched.signInPin && errors.signInPin}
                                  label='Sign-In PIN'
                                  name='signInPin'
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values?.signInPin ?? ''}
                                />
                              </Grid>
                            ) : null}
                          </Grid>
                        </Grid>
                      </DetailsForm>
                    </Grid>

                    <Grid item xs={12}>
                      <DetailsForm>
                        <Grid container spacing={1}>
                          <Grid
                            item
                            xs={12}
                            container
                            alignItems='center'
                            justifyContent='space-between'
                          >
                            <Grid item xs={12}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#2780E3', fontWeight: 600 }}
                              >
                                Feature Access
                              </Typography>
                            </Grid>
                          </Grid>
                          {permissions
                            .filter(
                              permission =>
                                !permission.specialIdentifier?.includes('REPORT')
                            )
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map(permission => (
                              <Grid item xs={12} key={permission.id}>
                                <Tooltip
                                  title={
                                    USERS_ADD_EDIT
                                      ? ''
                                      : 'Must have "Users add/edit" permission'
                                  }
                                  placement='bottom'
                                >
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={values.permissions
                                          .map(permission => permission.id)
                                          .includes(permission.id)}
                                        disabled={
                                          isSubmitting || !values.allowUserToSignIn /* ||
                                          !USERS_ADD_EDIT*/
                                        }
                                        onChange={e => {
                                          // Add if not exist in permissions array
                                          if (e.target.checked) {
                                            setFieldValue('permissions', [
                                              ...values.permissions,
                                              permission
                                            ])
                                            // Remove if exist in permissions array
                                          } else {
                                            setFieldValue(
                                              'permissions',
                                              values.permissions.filter(
                                                existingPermission =>
                                                  existingPermission.id !== permission.id
                                              )
                                            )
                                          }
                                        }}
                                      />
                                    }
                                    label={permission.name}
                                  />
                                </Tooltip>
                              </Grid>
                            ))}
                        </Grid>
                      </DetailsForm>
                    </Grid>

                    <Grid item xs={12}>
                      <DetailsForm>
                        <Grid container spacing={1}>
                          <Grid
                            item
                            xs={12}
                            container
                            alignItems='center'
                            justifyContent='space-between'
                          >
                            <Grid item xs={12}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#2780E3', fontWeight: 600 }}
                              >
                                Report Access
                              </Typography>
                            </Grid>
                          </Grid>
                          {permissions
                            .filter(permission =>
                              permission.specialIdentifier?.includes('REPORT')
                            )
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map(permission => (
                              <Grid item xs={12} key={permission.id}>
                                <Tooltip
                                  title={
                                    USERS_ADD_EDIT
                                      ? ''
                                      : 'Must have "Users add/edit" permission'
                                  }
                                  placement='bottom'
                                >
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={values.permissions
                                          .map(permission => permission.id)
                                          .includes(permission.id)}
                                        disabled={
                                          isSubmitting || !values.allowUserToSignIn /* ||
                                          !USERS_ADD_EDIT*/
                                        }
                                        onChange={e => {
                                          // Add if not exist in permissions array
                                          if (e.target.checked) {
                                            setFieldValue('permissions', [
                                              ...values.permissions,
                                              permission
                                            ])
                                            // Remove if exist in permissions array
                                          } else {
                                            setFieldValue(
                                              'permissions',
                                              values.permissions.filter(
                                                existingPermission =>
                                                  existingPermission.id !== permission.id
                                              )
                                            )
                                          }
                                        }}
                                      />
                                    }
                                    label={permission.name}
                                  />
                                </Tooltip>
                              </Grid>
                            ))}
                        </Grid>
                      </DetailsForm>
                    </Grid>

                    <Grid item xs={12}>
                      <DetailsForm>
                        <Grid item xs={12}>
                          <ExplanationAccordion>
                            Only users with the Users add/edit permission can modify a
                            person's sign-in methods, Username, and PIN.||Checking the
                            Access to Spud ERP checkbox allows the person to be assigned
                            permissions and sign-in methods.||Unchecking the Access to
                            Spud ERP checkbox and clicking the SAVE button clears the
                            person's permissions, Username, and PIN.||Checking the Allow
                            Email/Password Sign-In checkbox makes the Email field on the
                            person details tab required, and allows the person to sign in
                            with that email address and a password set by the
                            user.||Unchecking the Allow Email/Password Sign-In checkbox
                            disallows the person from signing in with the email and
                            password, but does not clear the person's email or password,
                            if present.||Clicking the SEND PASSWORD RESET button sends an
                            email to reset the password for Email/Password Sign-In only.
                            To reset the Username or PIN for Username/Sign-In PIN sign in,
                            edit the form field here.||Checking the Allow Username/PIN
                            Sign-In checkbox displays the required Username and Sign-In
                            PIN fields on this page and allows the person to sign in with
                            that Username and PIN.||Unchecking the Allow Username/PIN
                            Sign-In checkbox and clicking the SAVE button clears the
                            person's Username and PIN, if present, and disallows the
                            person from signing in with a Username and PIN.||The Username
                            must be alphanumeric and cannot include special
                            characters.||The Sign-In PIN must be must be exactly 6 numeric
                            values (digits).||Email/Password Sign-In can result in the
                            user being locked out for a short time after 3 consecutive
                            incorrect password attemts. Username/Sign-In PIN sign in
                            cannot result in the user being locked out.||Access to at
                            least one report is required to see the Reports feature in the
                            navigation menu.
                          </ExplanationAccordion>
                        </Grid>
                      </DetailsForm>
                    </Grid>
                  </Grid>
                </DetailsTab>

                <DetailsTab value='human-resources'>
                  <DetailsForm>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Tooltip
                          title={
                            COMPANIES_AND_PEOPLE_ADD_EDIT
                              ? ''
                              : 'Must have "Companies and people add/edit" permission'
                          }
                          placement='bottom'
                        >
                          <TextField
                            disabled={isSubmitting || !COMPANIES_AND_PEOPLE_ADD_EDIT}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            label='Start Date'
                            name='employmentStartDate'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type='date'
                            value={
                              values?.employmentStartDate
                                ? moment
                                    .utc(values.employmentStartDate)
                                    .format(formats.dateOnlyField)
                                : ''
                            }
                          />
                        </Tooltip>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Tooltip
                          title={
                            COMPANIES_AND_PEOPLE_ADD_EDIT
                              ? ''
                              : 'Must have "Companies and people add/edit" permission'
                          }
                          placement='bottom'
                        >
                          <TextField
                            disabled={isSubmitting || !COMPANIES_AND_PEOPLE_ADD_EDIT}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            label='End Date'
                            name='employmentEndDate'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder='End Date'
                            type='date'
                            value={
                              values?.employmentEndDate
                                ? moment
                                    .utc(values.employmentEndDate)
                                    .format(formats.dateOnlyField)
                                : ''
                            }
                          />
                        </Tooltip>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Tooltip
                          title={
                            COMPANIES_AND_PEOPLE_ADD_EDIT
                              ? ''
                              : 'Must have "Companies and people add/edit" permission'
                          }
                          placement='bottom'
                        >
                          <FormControl fullWidth>
                            <InputLabel id='departments-label'>Positions</InputLabel>
                            <Select
                              labelId='departments-label'
                              disabled={isSubmitting || !COMPANIES_AND_PEOPLE_ADD_EDIT}
                              multiple
                              fullWidth
                              value={values.departments.map(department => department.id)}
                              onChange={(
                                event: SelectChangeEvent<string[]>,
                                _child: React.ReactNode
                              ) => {
                                if (Array.isArray(event.target.value)) {
                                  setFieldValue(
                                    'departments',
                                    departments.filter(department =>
                                      event.target.value.includes(department.id)
                                    )
                                  )
                                }
                              }}
                              input={<OutlinedInput label='Positions' />}
                              renderValue={selected => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                  {selected.map(departmentId => (
                                    <Chip
                                      key={departmentId}
                                      label={
                                        departments.filter(
                                          department => department.id === departmentId
                                        )[0].name
                                      }
                                    />
                                  ))}
                                </Box>
                              )}
                              MenuProps={MenuProps}
                            >
                              {departments
                                .map(department => department.id)
                                .map(departmentId => (
                                  <MenuItem key={departmentId} value={departmentId}>
                                    <Checkbox
                                      checked={
                                        values.departments
                                          .map(department => department.id)
                                          .indexOf(departmentId) > -1
                                      }
                                    />
                                    <ListItemText
                                      primary={
                                        departments.filter(
                                          department => department.id === departmentId
                                        )[0].name
                                      }
                                    />
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </DetailsForm>
                </DetailsTab>

                <DetailsActions>
                  <Button
                    color='secondary'
                    disabled={isSubmitting}
                    onClick={() => {
                      navigate('/people')
                    }}
                    variant='text'
                  >
                    BACK
                  </Button>

                  <Tooltip
                    title={
                      COMPANIES_AND_PEOPLE_ADD_EDIT
                        ? ''
                        : 'Must have "Companies and people add/edit" permission'
                    }
                    placement='bottom'
                  >
                    <span>
                      <LoadingButton
                        color='primary'
                        disabled={isSubmitting || !COMPANIES_AND_PEOPLE_ADD_EDIT}
                        loading={isSubmitting}
                        onClick={() => {
                          // Due to an issue with Formik, the form fields need to be manually set as touched when there are errors on submit.
                          // Otherwise, sometimes the field errors won't display even though the validation throws them
                          validateForm().then(errors => {
                            const errorKeys = Object.keys(errors)
                            if (errorKeys.length === 0) {
                              submitForm()
                            } else {
                              setTouched(setNestedObjectValues(errors, true))
                              showFormErrorsPrompt(errors)
                            }
                          })
                        }}
                        variant='contained'
                      >
                        <span>{values.id ? 'UPDATE' : 'CREATE'} PERSON</span>
                      </LoadingButton>
                    </span>
                  </Tooltip>
                </DetailsActions>
              </Details>

              <UnsavedChangesPrompt when={dirty} />
            </>
          )
        }}
      </Formik>
    </Layout>
  )
}
