import CheckIcon from '@mui/icons-material/Check'
import EditIcon from '@mui/icons-material/Edit'
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Tooltip
} from '@mui/material'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import * as api from 'api'
import { ChipStatusSelect, UnsavedChangesPrompt } from 'components'
import { ExplanationAccordion } from 'components/_template/accordion'
import { DetailsActions, DetailsTab } from 'components/_template/details'
import { StatusOption, StatusOptionColors } from 'components/ChipStatusSelect'
import { SI_COMPANY_GEOPAC, SI_COMPANY_VIKPAP } from 'constantValues'
import {
  CompanyDto,
  RatesScoringAdderDto,
  ratesScoringAddersListValidationSchema
} from 'dtos'
import { Formik, FormikProps, getIn, setNestedObjectValues } from 'formik'
import { RatesScoringAddersParameters } from 'parameters'
import { Ref, useState } from 'react'
import { format, formats } from 'theme'
import { showFormErrorsPrompt } from '../../components/_template/form/FormErrorsPrompt'

interface RatesScoringAddersProps {
  getRatesScoringAdders: () => void
  isLoading: boolean
  RATES_ADD_EDIT: boolean
  ratesScoringAddersList: RatesScoringAdderDto[]
  ratesScoringAddersParameters: RatesScoringAddersParameters
  ratesScoringAddersTabRef:
    | Ref<
        FormikProps<{
          ratesScoringAdders: RatesScoringAdderDto[]
        }>
      >
    | undefined
  setIsRatesScoringAddersAddEditDialogOpen: (
    isRatesScoringAddersAddEditDialogOpen: boolean
  ) => void
  setIsRatesScoringAddersTabDirty: (isRatesScoringAddersTabDirty: boolean) => void
  setIsUpdatingRatesScoringAdders: (isUpdatingRatesScoringAdders: boolean) => void
  setRatesScoringAddersDialogValues: (
    ratesScoringAddersDialogValues: RatesScoringAdderDto
  ) => void
  setRatesScoringAddersParameters: (
    ratesScoringAddersParameters: RatesScoringAddersParameters
  ) => void
  vendorsList: CompanyDto[]
}

export default function RatesScoringAddersTab({
  getRatesScoringAdders,
  isLoading,
  RATES_ADD_EDIT,
  ratesScoringAddersList,
  ratesScoringAddersParameters,
  ratesScoringAddersTabRef,
  setIsRatesScoringAddersAddEditDialogOpen,
  setIsRatesScoringAddersTabDirty,
  setIsUpdatingRatesScoringAdders,
  setRatesScoringAddersParameters,
  vendorsList
}: RatesScoringAddersProps) {
  const [isEditingAllRows, setIsEditingAllRows] = useState<boolean>(false)

  return (
    <Formik
      enableReinitialize
      initialValues={{ ratesScoringAdders: ratesScoringAddersList }}
      innerRef={ratesScoringAddersTabRef}
      validateOnBlur
      validateOnChange
      validationSchema={ratesScoringAddersListValidationSchema}
      onSubmit={async (values, formikHelpers) => {
        setIsUpdatingRatesScoringAdders(true)
        await api.updateRatesScoringAddersList(values.ratesScoringAdders).finally(() => {
          setIsEditingAllRows(false)
          formikHelpers.resetForm() // Reset the form so that FRONT_END_isEditing is false
          getRatesScoringAdders()
          formikHelpers.setSubmitting(false)
          setIsUpdatingRatesScoringAdders(false)
          setIsRatesScoringAddersTabDirty(false)
        })
      }}
    >
      {({
        dirty,
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setTouched,
        submitForm,
        resetForm,
        touched,
        validateForm,
        values
      }) => {
        return (
          <>
            <form onSubmit={handleSubmit}>
              <DetailsTab value='tab5'>
                <Grid
                  container
                  xs={12}
                  spacing={2}
                  alignItems='center'
                  justifyContent='flex-end'
                >
                  <Grid
                    container
                    item
                    xs={12}
                    spacing={2}
                    alignItems='center'
                    justifyContent='flex-end'
                  >
                    <Grid item xs={12} sm={4}>
                      <ChipStatusSelect
                        label='Vendors'
                        onChange={value => {
                          setRatesScoringAddersParameters({
                            ...ratesScoringAddersParameters,
                            vendorIds: vendorsList
                              .filter(vendor => value.some(value => value == vendor.id))
                              .map(vendor => vendor.id ?? '')
                          })
                        }}
                        options={[
                          ...vendorsList.map(vendor => {
                            let color: StatusOptionColors = 'gray'
                            switch (vendor.specialIdentifier?.toUpperCase()) {
                              case SI_COMPANY_GEOPAC:
                                color = 'yellow'
                                break
                              case SI_COMPANY_VIKPAP:
                                color = 'green'
                                break
                            }
                            return {
                              label: vendor.name,
                              color: color,
                              value: vendor.id
                            } as StatusOption
                          })
                        ]}
                        value={ratesScoringAddersParameters.vendorIds ?? []}
                      />
                    </Grid>

                    <Grid item>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={ratesScoringAddersParameters.includeInactive}
                            checked={ratesScoringAddersParameters.includeInactive}
                            onChange={e =>
                              setRatesScoringAddersParameters({
                                ...ratesScoringAddersParameters,
                                includeInactive: e.target.checked
                              })
                            }
                          />
                        }
                        label='Include Inactive'
                        sx={{ mb: 2 }}
                      />
                    </Grid>

                    <Grid item>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={!RATES_ADD_EDIT}
                            value={isEditingAllRows}
                            checked={isEditingAllRows}
                            onChange={e => setIsEditingAllRows(!isEditingAllRows)}
                          />
                        }
                        label='Enable Editing All Rows'
                        sx={{ mb: 2 }}
                      />
                    </Grid>

                    <Grid item>
                      <Tooltip
                        title={
                          RATES_ADD_EDIT
                            ? 'Edit'
                            : 'Must have "Rates add/edit" permission'
                        }
                        placement='bottom'
                      >
                        <Button
                          variant='outlined'
                          disabled={!RATES_ADD_EDIT}
                          onClick={() => {
                            setIsRatesScoringAddersAddEditDialogOpen(true)
                          }}
                          sx={{ mb: 2 }}
                        >
                          Add Scoring Adders
                        </Button>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                      <TableHead>
                        <TableRow>
                          <TableCell width='25%'>Vendor</TableCell>
                          <TableCell align='center' width='10%'>
                            ECT / Mullen
                          </TableCell>
                          <TableCell align='center' width='35%'>
                            Adder
                          </TableCell>
                          <TableCell align='center' width='10%'>
                            Adder Setup
                          </TableCell>
                          <TableCell align='center' width='10%'>
                            Adder Per MSF
                          </TableCell>
                          <TableCell align='center' width='5%' padding='checkbox'>
                            Active
                          </TableCell>
                          <TableCell width='5%' />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {values.ratesScoringAdders.map((ratesScoringAdder, index) => (
                          <TableRow
                            key={ratesScoringAdder.id ?? index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component='th'>
                              {ratesScoringAdder.FRONT_END_isEditing ||
                              isEditingAllRows ? (
                                <Autocomplete
                                  autoHighlight
                                  autoSelect
                                  disabled={isLoading || isSubmitting}
                                  disableListWrap
                                  getOptionLabel={option =>
                                    option.name ?? 'VENDOR NOT FOUND'
                                  }
                                  isOptionEqualToValue={(option, value) => {
                                    return option.id === value?.id
                                  }}
                                  fullWidth
                                  onBlur={handleBlur}
                                  onChange={(_e, value) => {
                                    setIsRatesScoringAddersTabDirty(true)
                                    setFieldValue(
                                      `ratesScoringAdders[${index}].company`,
                                      value
                                    )
                                  }}
                                  options={vendorsList
                                    ?.filter(
                                      vendor =>
                                        vendor.isActive ||
                                        vendor.id === ratesScoringAdder.company?.id
                                    )
                                    .map(vendor => ({
                                      id: vendor.id,
                                      name: vendor.name,
                                      isActive: vendor.isActive,
                                      specialIdentifier: vendor.specialIdentifier
                                    }))}
                                  renderInput={params => (
                                    <TextField
                                      {...params}
                                      error={Boolean(
                                        getIn(
                                          touched,
                                          `ratesScoringAdders[${index}].company`
                                        ) &&
                                          getIn(
                                            errors,
                                            `ratesScoringAdders[${index}].company`
                                          )
                                      )}
                                      helperText={
                                        getIn(
                                          touched,
                                          `ratesScoringAdders[${index}].company`
                                        ) &&
                                        getIn(
                                          errors,
                                          `ratesScoringAdders[${index}].company`
                                        )
                                      }
                                      label='Vendor'
                                      name={`ratesScoringAdders[${index}].company`}
                                    />
                                  )}
                                  renderOption={(props, option) => {
                                    return (
                                      <li {...props} key={option.id}>
                                        {option.name}
                                      </li>
                                    )
                                  }}
                                  value={
                                    ratesScoringAdder.company
                                      ? {
                                          id: ratesScoringAdder.company.id,
                                          name: ratesScoringAdder.company.name,
                                          isActive: ratesScoringAdder.company.isActive,
                                          specialIdentifier:
                                            ratesScoringAdder.company.specialIdentifier
                                        }
                                      : null
                                  }
                                />
                              ) : (
                                ratesScoringAdder?.company?.name ?? ''
                              )}
                            </TableCell>
                            <TableCell align='center'>
                              {ratesScoringAdder.FRONT_END_isEditing ||
                              isEditingAllRows ? (
                                <TextField
                                  disabled={isLoading || isSubmitting}
                                  error={Boolean(
                                    getIn(touched, `ratesScoringAdders[${index}].ect`) &&
                                      getIn(errors, `ratesScoringAdders[${index}].ect`)
                                  )}
                                  fullWidth
                                  helperText={
                                    getIn(touched, `ratesScoringAdders[${index}].ect`) &&
                                    getIn(errors, `ratesScoringAdders[${index}].ect`)
                                  }
                                  label='ECT / Mullen'
                                  name={`ratesScoringAdders[${index}].ect`}
                                  onBlur={handleBlur}
                                  onChange={e => {
                                    setIsRatesScoringAddersTabDirty(true)
                                    handleChange(e)
                                  }}
                                  value={ratesScoringAdder.ect}
                                />
                              ) : (
                                ratesScoringAdder.ect
                              )}
                            </TableCell>
                            <TableCell align='center'>
                              {ratesScoringAdder.FRONT_END_isEditing ||
                              isEditingAllRows ? (
                                <TextField
                                  disabled={isLoading || isSubmitting}
                                  error={Boolean(
                                    getIn(
                                      touched,
                                      `ratesScoringAdders[${index}].adder`
                                    ) &&
                                      getIn(errors, `ratesScoringAdders[${index}].adder`)
                                  )}
                                  fullWidth
                                  helperText={
                                    getIn(
                                      touched,
                                      `ratesScoringAdders[${index}].adder`
                                    ) &&
                                    getIn(errors, `ratesScoringAdders[${index}].adder`)
                                  }
                                  label='Adder'
                                  name={`ratesScoringAdders[${index}].adder`}
                                  onBlur={handleBlur}
                                  onChange={e => {
                                    setIsRatesScoringAddersTabDirty(true)
                                    handleChange(e)
                                  }}
                                  value={ratesScoringAdder.adder || ''}
                                />
                              ) : (
                                ratesScoringAdder.adder
                              )}
                            </TableCell>
                            <TableCell align='center'>
                              {ratesScoringAdder.FRONT_END_isEditing ||
                              isEditingAllRows ? (
                                <TextField
                                  disabled={isLoading || isSubmitting}
                                  error={Boolean(
                                    getIn(
                                      touched,
                                      `ratesScoringAdders[${index}].adderSetup`
                                    ) &&
                                      getIn(
                                        errors,
                                        `ratesScoringAdders[${index}].adderSetup`
                                      )
                                  )}
                                  fullWidth
                                  helperText={
                                    getIn(
                                      touched,
                                      `ratesScoringAdders[${index}].adderSetup`
                                    ) &&
                                    getIn(
                                      errors,
                                      `ratesScoringAdders[${index}].adderSetup`
                                    )
                                  }
                                  label='Adder Setup'
                                  name={`ratesScoringAdders[${index}].adderSetup`}
                                  onBlur={handleBlur}
                                  onChange={e => {
                                    setIsRatesScoringAddersTabDirty(true)
                                    handleChange(e)
                                  }}
                                  value={ratesScoringAdder.adderSetup}
                                />
                              ) : (
                                format(ratesScoringAdder.adderSetup, formats.currency)
                              )}
                            </TableCell>
                            <TableCell align='center'>
                              {ratesScoringAdder.FRONT_END_isEditing ||
                              isEditingAllRows ? (
                                <TextField
                                  disabled={isLoading || isSubmitting}
                                  error={Boolean(
                                    getIn(
                                      touched,
                                      `ratesScoringAdders[${index}].adderPerMsf`
                                    ) &&
                                      getIn(
                                        errors,
                                        `ratesScoringAdders[${index}].adderPerMsf`
                                      )
                                  )}
                                  fullWidth
                                  helperText={
                                    getIn(
                                      touched,
                                      `ratesScoringAdders[${index}].adderPerMsf`
                                    ) &&
                                    getIn(
                                      errors,
                                      `ratesScoringAdders[${index}].adderPerMsf`
                                    )
                                  }
                                  label='Adder Per MSF'
                                  name={`ratesScoringAdders[${index}].adderPerMsf`}
                                  onBlur={handleBlur}
                                  onChange={e => {
                                    setIsRatesScoringAddersTabDirty(true)
                                    handleChange(e)
                                  }}
                                  value={ratesScoringAdder.adderPerMsf}
                                />
                              ) : (
                                format(ratesScoringAdder.adderPerMsf, formats.currency)
                              )}
                            </TableCell>
                            <TableCell align='center'>
                              {ratesScoringAdder.FRONT_END_isEditing ||
                              isEditingAllRows ? (
                                <Checkbox
                                  checked={ratesScoringAdder.isActive}
                                  disabled={isLoading || isSubmitting}
                                  name={`ratesScoringAdders[${index}].isActive`}
                                  onBlur={handleBlur}
                                  onChange={e => {
                                    setIsRatesScoringAddersTabDirty(true)
                                    handleChange(e)
                                  }}
                                  value={ratesScoringAdder.isActive}
                                />
                              ) : ratesScoringAdder.isActive ? (
                                <CheckIcon />
                              ) : null}
                            </TableCell>
                            <TableCell align='center'>
                              <Tooltip
                                title={
                                  RATES_ADD_EDIT
                                    ? 'Edit'
                                    : 'Must have "Rates add/edit" permission'
                                }
                                placement='bottom'
                              >
                                <Grid item>
                                  <IconButton
                                    aria-label={'Edit'}
                                    disabled={
                                      !RATES_ADD_EDIT ||
                                      (ratesScoringAdder.FRONT_END_isEditing &&
                                        Boolean(ratesScoringAdder.id))
                                    }
                                    onClick={() => {
                                      setFieldValue(
                                        `ratesScoringAdders[${index}].FRONT_END_isEditing`,
                                        true
                                      )
                                    }}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Grid>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Grid item xs={12}>
                    <DetailsActions>
                      <Button
                        variant='text'
                        color='secondary'
                        disabled={isLoading || isSubmitting}
                        onClick={() => {
                          resetForm()
                        }}
                      >
                        CANCEL
                      </Button>

                      <Button
                        disabled={isLoading || isSubmitting || !RATES_ADD_EDIT}
                        onClick={() => {
                          // Due to an issue with Formik, the form fields need to be manually set as touched when there are errors on submit.
                          // Otherwise, sometimes the field errors won't display even though the validation throws them
                          validateForm().then(errors => {
                            const errorKeys = Object.keys(errors)
                            if (errorKeys.length === 0) {
                              submitForm()
                            } else {
                              setTouched(setNestedObjectValues(errors, true))
                              showFormErrorsPrompt(errors)
                            }
                          })
                        }}
                        variant='contained'
                      >
                        SAVE
                      </Button>
                    </DetailsActions>
                  </Grid>
                  <Grid item xs={12}>
                    <ExplanationAccordion>
                      The Vendors filter only shows vendors that have Scoring Adders in
                      the system.||Click the Enable Editing All Rows checkbox to access
                      form fields for all rows in the table.||Click the ADD SCORING ADDERS
                      button to open the dialog to add a new Scoring Adder.||To edit an
                      existing row, click the pencil icon edit button to access form
                      fields for the selected row. After clicking the pencil icon edit
                      button, it becomes disabled until saving or cancelling.||Click the
                      CANCEL button to clear all unsaved changes on this tab.||Click the
                      SAVE button to save all unsaved changes on this tab.
                    </ExplanationAccordion>
                  </Grid>
                </Grid>
              </DetailsTab>
              <UnsavedChangesPrompt when={dirty} />
            </form>
          </>
        )
      }}
    </Formik>
  )
}
