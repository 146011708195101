import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography
} from '@mui/material'
import { ExplanationAccordion } from 'components/_template/accordion'
import { JobStatusDto, jobStatusValidationSchema } from 'dtos'
import { Formik, setNestedObjectValues } from 'formik'
import { IntFormat } from './NumberFields'
import { showFormErrorsPrompt } from './_template/form/FormErrorsPrompt'
import { format } from 'theme'

interface JobCenter66RdcJobIsCompleteDialogProps {
  initialValues: JobStatusDto
  onClose: () => void
  onSave: (values: JobStatusDto) => void
  open: boolean
}

export default function JobCenter66RdcJobIsCompleteDialog({
  initialValues,
  onClose,
  onSave,
  open
}: JobCenter66RdcJobIsCompleteDialogProps) {
  return (
    <Dialog open={open}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validateOnBlur
        validateOnChange
        validationSchema={jobStatusValidationSchema}
        onSubmit={values => {
          onSave(values)
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setTouched,
          submitForm,
          touched,
          validateForm,
          values
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2} alignItems='center'>
                  <DialogTitle>Job Complete</DialogTitle>

                  <Grid item xs={12}>
                    <TextField
                      disabled={isSubmitting}
                      error={Boolean(
                        touched.lastBandingQuantity && errors.lastBandingQuantity
                      )}
                      fullWidth
                      helperText={
                        touched.lastBandingQuantity && errors.lastBandingQuantity
                      }
                      label='Last Banding Quantity'
                      name='lastBandingQuantity'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      InputProps={{
                        inputComponent: IntFormat as any
                      }}
                      value={values.lastBandingQuantity + ''}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                      Customer Order Quantity:{' '}
                      {format(initialValues.customerOrderQuantity)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={isSubmitting}
                      error={Boolean(
                        touched.totalQuantityProcessed && errors.totalQuantityProcessed
                      )}
                      fullWidth
                      helperText={
                        touched.totalQuantityProcessed && errors.totalQuantityProcessed
                      }
                      label='Total Quantity Processed'
                      name='totalQuantityProcessed'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      InputProps={{
                        inputComponent: IntFormat as any
                      }}
                      value={values.totalQuantityProcessed + ''}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={isSubmitting}
                      error={Boolean(
                        touched.quantityToReturnToRawInventory &&
                          errors.quantityToReturnToRawInventory
                      )}
                      fullWidth
                      helperText={
                        touched.quantityToReturnToRawInventory &&
                        errors.quantityToReturnToRawInventory
                      }
                      label='Quantity To Return To Raw Inventory'
                      name='quantityToReturnToRawInventory'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      InputProps={{
                        inputComponent: IntFormat as any
                      }}
                      value={values.quantityToReturnToRawInventory + ''}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={isSubmitting}
                      error={Boolean(touched.scrappedQuantity && errors.scrappedQuantity)}
                      fullWidth
                      helperText={touched.scrappedQuantity && errors.scrappedQuantity}
                      label='Scrapped Quantity'
                      name='scrappedQuantity'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      InputProps={{
                        inputComponent: IntFormat as any
                      }}
                      value={values.scrappedQuantity + ''}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={isSubmitting}
                      error={Boolean(touched.note && errors.note)}
                      fullWidth
                      helperText={touched.note && errors.note}
                      label='Notes'
                      name='note'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.note || ''}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ExplanationAccordion>
                      Generates a PDF of the final traveler with quantity entered by
                      user.||Total Quantity Processed is the total number of pieces
                      manufactured in all bundles on this job, per the job center machine
                      read-out.||If the Total Quantity Processed value is less than the
                      Customer Order Quantity value, the Notes value is required to
                      explain the difference.||At this time, raw inventory pallets
                      indicated by the Quantity To Return To Raw Inventory value need to
                      be picked up and put back into raw inventory by the forklift
                      operator and they will need to be notified outside of the software.
                      A future update will handle this scenario and automate forklift
                      driver notification.||This is only to support capturing scrap at the
                      end of a job.
                    </ExplanationAccordion>
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions sx={{ mb: 2, mr: 2 }}>
                <Button variant='text' color='secondary' onClick={onClose}>
                  CLOSE
                </Button>

                <Button
                  onClick={() => {
                    // Due to an issue with Formik, the form fields need to be manually set as touched when there are errors on submit.
                    // Otherwise, sometimes the field errors won't display even though the validation throws them
                    validateForm().then(errors => {
                      const errorKeys = Object.keys(errors)
                      if (errorKeys.length === 0) {
                        submitForm()
                      } else {
                        setTouched(setNestedObjectValues(errors, true))
                        showFormErrorsPrompt(errors)
                      }
                    })
                  }}
                  variant='contained'
                >
                  SAVE
                </Button>
              </DialogActions>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}
