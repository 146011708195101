import { ReadMoreOutlined, SearchOutlined } from '@mui/icons-material'
import {
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import * as api from 'api'
import { Layout } from 'components/_template'
import { ExplanationAccordion } from 'components/_template/accordion'
import { List, ListHeaderCard, ListItemCard } from 'components/_template/list'
import { JobDto } from 'dtos'
import { JobCenter66RdcToDosParameters } from 'parameters'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { format, formats } from 'theme'
import { useDebounce } from 'utils'
import { errorHandling } from 'constantValues'

const sessionStorageKey = 'jobCenter66RdcToDos:parameters'

export default function JobCenter66RdcToDos() {
  const navigate = useNavigate()

  const defaultParameters: JobCenter66RdcToDosParameters = {
    page: 0,
    pageSize: 10,
    search: '',
    includeCompleted: false
  }

  const [count, setCount] = useState<number>(0)
  const [isJobCenter66RdcToDosLoading, setIsJobCenter66RdcToDosLoading] =
    useState<boolean>(false)
  const [parameters, setParameters] = useState<JobCenter66RdcToDosParameters>(
    sessionStorage.getItem(sessionStorageKey)
      ? JSON.parse(sessionStorage.getItem(sessionStorageKey)!)
      : defaultParameters
  )
  const [jobCenter66RdcToDos, setJobCenter66RdcToDos] = useState<JobDto[]>([])

  const getJobCenter66RdcToDos = useDebounce(
    (parameters: JobCenter66RdcToDosParameters) => {
      setIsJobCenter66RdcToDosLoading(true)
      sessionStorage.setItem(sessionStorageKey, JSON.stringify(parameters))
      api
        .getJobCenter66RdcToDos(parameters)
        .then(res => {
          setJobCenter66RdcToDos(res.value)
          setCount(res.totalCount!)
        })
        .catch((errors: string[]) => {
          errorHandling(errors)
        })
        .finally(() => {
          setIsJobCenter66RdcToDosLoading(false)
        })
    },
    300
  )

  useEffect(() => {
    getJobCenter66RdcToDos(parameters)
  }, [parameters])

  return (
    <Layout title='66RDC To Dos' isLoading={isJobCenter66RdcToDosLoading}>
      <List
        pagination={{
          page: parameters.page,
          pageSize: parameters.pageSize,
          count,
          onPageChange: page => setParameters({ ...parameters, page }),
          onPageSizeChange: pageSize => setParameters({ ...parameters, pageSize })
        }}
        header={<ListHeaderCard title='66RDC To Dos' actions={[]} />}
      >
        <ListItemCard>
          <Grid container spacing={2} alignItems='center' justifyContent='space-between'>
            <Grid item xs={12} sm={2}>
              <TextField
                data-search
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <SearchOutlined fontSize='small' />
                    </InputAdornment>
                  )
                }}
                label='Search'
                onChange={e => setParameters({ ...parameters, search: e.target.value })}
                size='small'
                type='search'
                value={parameters.search}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={parameters.includeCompleted}
                    checked={parameters.includeCompleted}
                    onChange={e =>
                      setParameters({
                        ...parameters,
                        includeCompleted: e.target.checked
                      })
                    }
                  />
                }
                label='Include Completed'
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Button
                color='primary'
                size='medium'
                variant='text'
                onClick={() => {
                  setParameters(defaultParameters)
                }}
              >
                RESET FILTERS
              </Button>
            </Grid>
          </Grid>
        </ListItemCard>

        {jobCenter66RdcToDos.map(job => (
          <ListItemCard
            chips={[
              job.jobStatus && (
                <Chip
                  label={job.jobStatus.jobStatusType?.name}
                  color='primary'
                  variant='outlined'
                />
              )
            ]}
            key={job.id}
            title={
              job.vendorOrder?.company?.name +
              (job.priority?.toString()
                ? ' - Priority: ' + job.priority?.toString()
                : 'Not Set')
            }
            actions={[
              <Tooltip title='View Details'>
                <IconButton
                  onClick={() => navigate('/job-center-66rdc-to-dos/' + job.id)}
                  color='primary'
                >
                  <ReadMoreOutlined fontSize='large' />
                </IconButton>
              </Tooltip>
            ]}
          >
            <Typography variant='body2' sx={{ color: '#7F7F7F', fontWeight: 600 }}>
              Job #: {job.jobNumber}
            </Typography>

            <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
              Schedule Date: {format(job.scheduleDate, formats.dateNoTimeZone)}
            </Typography>

            <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
              Quantity Received: {job.vendorOrder?.receivedToDate} - Pallets:{' '}
              {job.palletCount} - Projected Bundles: {job.vendorOrder?.projectedBundles}
            </Typography>
          </ListItemCard>
        ))}

        <Grid item xs={12}>
          <ExplanationAccordion>
            Search checks if the search term is contained in any of the individual data
            values shown on the list card. To search by a date, ensure the date is
            formatted as shown on the list card. It does not enable searching by labels,
            chips, or special characters.||Include completed will show all jobs ever
            assigned to 66RDC. It is not checked by default.||Cards are listed in order of
            Schedule Date, then Priority, as selected on the Job Schedule Details
            page.||If the job has been started, a chip shows the status of "Started",
            "Paused", "Stopped", or "Completed".||Quantity Received is the Vendor Order
            Received To Date value.||Pallets is the number of pallets brought to 66RDC for
            the job.||Projected Bundles is the Projected Bundles value calculated on the
            Vendor Order Details.
          </ExplanationAccordion>
        </Grid>
      </List>
    </Layout>
  )
}
