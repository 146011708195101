import { Button, Link, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import apiClient, * as api from 'api'
import {
  JobCenter66RdcJobIsCompleteDialog,
  JobCenter66RdcPauseDialog,
  JobCenter66RdcStopDialog
} from 'components'
import { Layout } from 'components/_template'
import { ExplanationAccordion } from 'components/_template/accordion'
import {
  Details,
  DetailsForm,
  DetailsHeaderCard,
  DetailsTab
} from 'components/_template/details'
import {
  JobDto,
  JobPauseReasonTypeDto,
  JobStatusDto,
  JobStatusTypeDto,
  JobStopReasonTypeDto,
  jobCenter66RdcToDoValidationSchema
} from 'dtos'
import { Formik } from 'formik'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { useParams } from 'react-router-dom'
import { errorHandling } from 'constantValues'
import ReportTravelerLastPage from './ReportTravelerLastPage'
import { Download } from '@mui/icons-material'

export default function JobCenter66RdcToDoDetails() {
  const { enqueueSnackbar } = useSnackbar()
  const { id } = useParams()

  const [isJobCenter66RdcToDoLoading, setIsJobCenter66RdcToDoLoading] =
    useState<boolean>(false)
  const [isJobPauseReasonTypesLoading, setIsJobPauseReasonTypesLoading] =
    useState<boolean>(false)
  const [isJobStatusLoading, setIsJobStatusLoading] = useState<boolean>(false)
  const [isJobStatusTypesLoading, setIsJobStatusTypesLoading] = useState<boolean>(false)
  const [isJobStopReasonTypesLoading, setIsJobStopReasonTypesLoading] =
    useState<boolean>(false)
  const [isReportLoading, setIsReportLoading] = useState<boolean>(false)
  const [initialValues, setInitialValues] = useState<JobDto>(new JobDto())
  const [
    jobCenter66RdcJobIsCompleteDialogOpen,
    setJobCenter66RdcJobIsCompleteDialogOpen
  ] = useState<boolean>(false)
  const [jobCenter66RdcPauseDialogOpen, setJobCenter66RdcPauseDialogOpen] =
    useState<boolean>(false)
  const [jobCenter66RdcStopDialogOpen, setJobCenter66RdcStopDialogOpen] =
    useState<boolean>(false)
  const [jobPauseReasonTypes, setJobPauseReasonTypes] = useState<JobPauseReasonTypeDto[]>(
    []
  )
  const [jobStatusTypes, setJobStatusTypes] = useState<JobStatusTypeDto[]>([])
  const [jobStopReasonTypes, setJobStopReasonTypes] = useState<JobStopReasonTypeDto[]>([])

  useEffect(() => {
    setIsJobPauseReasonTypesLoading(true)
    api
      .getJobPauseReasonTypes()
      .then(({ value }) => {
        setJobPauseReasonTypes(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsJobPauseReasonTypesLoading(false)
      })
    setIsJobStatusTypesLoading(true)
    api
      .getJobStatusTypes()
      .then(({ value }) => {
        setJobStatusTypes(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsJobStatusTypesLoading(false)
      })
    setIsJobStopReasonTypesLoading(true)
    api
      .getJobStopReasonTypes()
      .then(({ value }) => {
        setJobStopReasonTypes(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsJobStopReasonTypesLoading(false)
      })
  }, [])

  useEffect(() => {
    if (id && id.toLowerCase() !== 'new' && id.toLowerCase() !== 'undefined') {
      setIsJobCenter66RdcToDoLoading(true)
      api
        .getJobCenter66RdcToDoById(id)
        .then(({ value }) => {
          setInitialValues(value)
        })
        .catch((errors: string[]) => {
          errorHandling(errors)
        })
        .finally(() => {
          setIsJobCenter66RdcToDoLoading(false)
        })
    } else {
    }
  }, [id])

  const onSaveJobStatusStarted = async (jobStatus: JobStatusDto) => {
    setIsJobStatusLoading(true)
    api
      .createJobStatus(jobStatus)
      .then(({ value }) => {
        enqueueSnackbar('Job Status Has Been Updated To "Started"!', {
          variant: 'success'
        })

        setIsJobCenter66RdcToDoLoading(true)
        api
          .getJobCenter66RdcToDoById(id!)
          .then(({ value }) => {
            setInitialValues(value)
          })
          .catch((errors: string[]) => {
            errorHandling(errors)
          })
          .finally(() => {
            setIsJobCenter66RdcToDoLoading(false)
          })
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsJobStatusLoading(false)
      })
  }

  const onSaveJobStatusPaused = async (jobStatus: JobStatusDto) => {
    setIsJobStatusLoading(true)
    api
      .createJobStatus(jobStatus)
      .then(({ value }) => {
        enqueueSnackbar('Job Status Has Been Updated To "Paused"!', {
          variant: 'success'
        })
        setJobCenter66RdcPauseDialogOpen(false)

        setIsJobCenter66RdcToDoLoading(true)
        api
          .getJobCenter66RdcToDoById(id!)
          .then(({ value }) => {
            setInitialValues(value)
          })
          .catch((errors: string[]) => {
            errorHandling(errors)
          })
          .finally(() => {
            setIsJobCenter66RdcToDoLoading(false)
          })
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsJobStatusLoading(false)
      })
  }

  const onSaveJobStatusStopped = async (jobStatus: JobStatusDto) => {
    setIsJobStatusLoading(true)
    api
      .createJobStatus(jobStatus)
      .then(({ value }) => {
        enqueueSnackbar('Job Status Has Been Updated To "Stopped"!', {
          variant: 'success'
        })
        setJobCenter66RdcStopDialogOpen(false)

        setIsJobCenter66RdcToDoLoading(true)
        api
          .getJobCenter66RdcToDoById(id!)
          .then(({ value }) => {
            setInitialValues(value)
            openTravelerLastPageReportWindow(
              value?.vendorOrder?.id ?? '',
              jobStatus.lastBandingQuantity
            )
          })
          .catch((errors: string[]) => {
            errorHandling(errors)
          })
          .finally(() => {
            setIsJobCenter66RdcToDoLoading(false)
          })
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsJobStatusLoading(false)
      })
  }

  const onSaveJobStatusCompleted = async (jobStatus: JobStatusDto) => {
    setIsJobStatusLoading(true)
    api
      .createJobStatus(jobStatus)
      .then(({ value }) => {
        enqueueSnackbar('Job Status Has Been Updated To "Completed"!', {
          variant: 'success'
        })
        setJobCenter66RdcJobIsCompleteDialogOpen(false)

        setIsJobCenter66RdcToDoLoading(true)
        api
          .getJobCenter66RdcToDoById(id!)
          .then(({ value }) => {
            setInitialValues(value)
            openTravelerLastPageReportWindow(
              value?.vendorOrder?.id ?? '',
              jobStatus.lastBandingQuantity
            )
          })
          .catch((errors: string[]) => {
            errorHandling(errors)
          })
          .finally(() => {
            setIsJobCenter66RdcToDoLoading(false)
          })
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsJobStatusLoading(false)
      })
  }

  const openTravelerLastPageReportWindow = (
    vendorOrderId: string,
    lastBandingQuantity?: number
  ) => {
    setIsReportLoading(true)
    api
      .getTravelerReport(id ?? '')
      .then(reportResponse => {
        api.getTravelerReportStopCount(id ?? '').then(stopCountResponse => {
          const newWindow = window.open('', '_blank', 'width=800,height=600')

          if (newWindow) {
            newWindow.document.body.innerHTML =
              '<div id="traveler-except-last-page-report-container"></div>'

            const root = createRoot(
              newWindow.document.getElementById(
                'traveler-except-last-page-report-container'
              ) as HTMLElement
            )

            root.render(
              <ReportTravelerLastPage
                opportunity={reportResponse.value}
                vendorOrderId={vendorOrderId}
                jobId={id ?? ''}
                lastBandingQuantity={lastBandingQuantity}
                stopCount={stopCountResponse.value}
              />
            )
          } else {
            console.error('Failed to open a new window.')
          }
        })
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsReportLoading(false)
      })
  }

  return (
    <Layout
      title='66RDC To Do Details'
      isLoading={
        isJobCenter66RdcToDoLoading ||
        isJobPauseReasonTypesLoading ||
        isJobStatusLoading ||
        isJobStatusTypesLoading ||
        isJobStopReasonTypesLoading ||
        isReportLoading
      }
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validateOnBlur
        validateOnChange
        validationSchema={jobCenter66RdcToDoValidationSchema}
        onSubmit={async (values, submitProps) => {
          await (values.id ? apiClient.put : apiClient.post)(
            '/api/job-center-66rdc-to-do',
            values
          )
            .then(res => {
              enqueueSnackbar('66RDC To Do Has Been Saved!', {
                variant: 'success'
              })

              setInitialValues(res.data.value)
              submitProps.resetForm({ values: res.data.value })
            })
            .catch((errors: string[]) => {
              errorHandling(errors)
            })
        }}
      >
        {({ values }) => {
          return (
            <>
              <JobCenter66RdcJobIsCompleteDialog
                initialValues={{
                  ...new JobStatusDto(),
                  job: values,
                  jobStatusType: jobStatusTypes.find(
                    jobStatusType => jobStatusType.specialIdentifier == 'COMPLETED'
                  ),
                  customerOrderQuantity:
                    values.vendorOrder?.quoteLineItem?.assemblyCustomerOrderQuantity
                }}
                open={jobCenter66RdcJobIsCompleteDialogOpen}
                onClose={() => {
                  setJobCenter66RdcJobIsCompleteDialogOpen(false)
                }}
                onSave={savedJobStatus => {
                  onSaveJobStatusCompleted(savedJobStatus)
                }}
              />
              <JobCenter66RdcPauseDialog
                initialValues={{
                  ...new JobStatusDto(),
                  job: values,
                  jobStatusType: jobStatusTypes.find(
                    jobStatusType => jobStatusType.specialIdentifier == 'PAUSED'
                  )
                }}
                jobPauseReasonTypes={jobPauseReasonTypes}
                open={jobCenter66RdcPauseDialogOpen}
                onClose={() => {
                  setJobCenter66RdcPauseDialogOpen(false)
                }}
                onSave={savedJobStatus => {
                  onSaveJobStatusPaused(savedJobStatus)
                }}
              />
              <JobCenter66RdcStopDialog
                initialValues={{
                  ...new JobStatusDto(),
                  job: values,
                  jobStatusType: jobStatusTypes.find(
                    jobStatusType => jobStatusType.specialIdentifier == 'STOPPED'
                  )
                }}
                jobStopReasonTypes={jobStopReasonTypes}
                open={jobCenter66RdcStopDialogOpen}
                onClose={() => {
                  setJobCenter66RdcStopDialogOpen(false)
                }}
                onSave={savedJobStatus => {
                  onSaveJobStatusStopped(savedJobStatus)
                }}
              />
              <Details
                header={
                  <DetailsHeaderCard
                    title={`66RDC To Do Details - Job#: ${values.jobNumber} - Item #: ${values.vendorOrder?.quoteLineItem?.itemNumber} - Quantity: ${values.vendorOrder?.finishedGoodsProjection}`}
                  />
                }
                tabs={[{ value: 'tab1', label: 'Details' }]}
                onSubmit={e => e.preventDefault()}
              >
                <DetailsTab value='tab1'>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <DetailsForm>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={3}>
                            <Grid item>
                              <Button
                                disabled={
                                  values.jobStatus?.jobStatusType?.specialIdentifier !==
                                    undefined &&
                                  values.jobStatus?.jobStatusType?.specialIdentifier !==
                                    'PAUSED' &&
                                  values.jobStatus?.jobStatusType?.specialIdentifier !==
                                    'STOPPED'
                                }
                                variant='outlined'
                                onClick={() => {
                                  onSaveJobStatusStarted({
                                    ...new JobStatusDto(),
                                    job: values,
                                    jobStatusType: jobStatusTypes.find(
                                      jobStatusType =>
                                        jobStatusType.specialIdentifier == 'STARTED'
                                    )
                                  })
                                }}
                              >
                                Start
                              </Button>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Grid item>
                              <Button
                                disabled={
                                  values.jobStatus?.jobStatusType?.specialIdentifier !==
                                  'STARTED'
                                }
                                variant='outlined'
                                onClick={() => {
                                  setJobCenter66RdcPauseDialogOpen(true)
                                }}
                              >
                                Pause
                              </Button>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Grid item>
                              <Button
                                disabled={
                                  values.jobStatus?.jobStatusType?.specialIdentifier !==
                                  'STARTED'
                                }
                                variant='outlined'
                                onClick={() => {
                                  setJobCenter66RdcStopDialogOpen(true)
                                }}
                              >
                                Stop
                              </Button>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Grid item>
                              <Button
                                disabled={
                                  values.jobStatus?.jobStatusType?.specialIdentifier !==
                                  'STARTED'
                                }
                                variant='outlined'
                                onClick={() => {
                                  setJobCenter66RdcJobIsCompleteDialogOpen(true)
                                }}
                              >
                                Job is complete
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </DetailsForm>
                    </Grid>

                    <Grid item xs={12}>
                      <DetailsForm>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#2780E3', fontWeight: 600 }}
                            >
                              Order Information
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              Customer Part #:{' '}
                              {values.vendorOrder?.quoteLineItem?.customerPartNumber}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              Contract Duration (Months):{' '}
                              {values.vendorOrder?.quoteLineItem?.contractDuration}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              Customer Order Quantity:{' '}
                              {
                                values.vendorOrder?.quoteLineItem
                                  ?.assemblyCustomerOrderQuantity
                              }
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              Vendor Order Quantity:{' '}
                              {
                                values.vendorOrder?.quoteLineItem
                                  ?.checkValuesVendorOrderQuantity
                              }
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              Finished Good Unit Quantity Per Bundle:{' '}
                              {
                                values.vendorOrder?.quoteLineItem
                                  ?.assemblyFinishedGoodUnitQuantityPerBundle
                              }
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              Outs: {values.vendorOrder?.quoteLineItem?.assemblyOuts}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              Test Run Quantity (Pieces):{' '}
                              {values.vendorOrder?.quoteLineItem?.testRunQuantity}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              Minimum Order Run Quantity (Pieces):{' '}
                              {values.vendorOrder?.quoteLineItem?.minimumOrderRunQuantity}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              Finished Goods Projection:{' '}
                              {values.vendorOrder?.finishedGoodsProjection}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              Projected Bundles: {values.vendorOrder?.projectedBundles}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              Job Center: {values.vendorOrder?.quoteLineItem?.jobCenter}
                            </Typography>
                          </Grid>
                          {values.vendorOrder?.quoteLineItem?.quoteLineItemDesignFiles &&
                            values.vendorOrder?.quoteLineItem.quoteLineItemDesignFiles
                              .length > 0 && (
                              <Grid item container xs={12} sm={6}>
                                <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                  Design File:{' '}
                                  <Link
                                    onClick={() => {
                                      api
                                        .downloadQuoteLineItemDesignFile(
                                          values.vendorOrder!.quoteLineItem!
                                            .quoteLineItemDesignFiles![0].fileName ?? ''
                                        )
                                        .catch((errors: string[]) => {
                                          errorHandling(errors)
                                        })
                                        .finally(() => {})
                                    }}
                                    sx={{
                                      alignItems: 'center',
                                      flexWrap: 'wrap',
                                      cursor: 'pointer'
                                    }}
                                  >
                                    <Download
                                      fontSize='small'
                                      sx={{ color: '#7F7F7F' }}
                                    />

                                    <Typography
                                      variant='body1'
                                      display='inline'
                                      sx={{ color: '#7F7F7F' }}
                                    >
                                      {
                                        values.vendorOrder.quoteLineItem
                                          .quoteLineItemDesignFiles[0].fileName
                                      }
                                    </Typography>
                                  </Link>
                                </Typography>
                              </Grid>
                            )}
                        </Grid>
                      </DetailsForm>
                    </Grid>

                    <Grid item xs={12}>
                      <ExplanationAccordion>
                        Pause button saves the time and prompts user for reason for
                        pausing.||Stop button saves the time and prompts user for reason
                        for stopping and last banding quantity.||Job is complete button
                        saves the time and prompts user for last banding quantity.||Start
                        is disabled if Job is in process or complete. Pause, Stop and Job
                        is complete are disabled if job isn't started. Start is enabled if
                        job is pause or stopped.||If a PDF has been uploaded to the quote
                        for this job, the row "Design File" appears on the Order
                        Information card here (not on the Order Information cards on other
                        screens). Clicking the filename downloads the PDF, the same way
                        the Quote allows downloading the most recent PDF.
                      </ExplanationAccordion>
                    </Grid>
                  </Grid>
                </DetailsTab>
              </Details>
            </>
          )
        }}
      </Formik>
    </Layout>
  )
}
