import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField
} from '@mui/material'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import { CompanyDto, RatesPaperAdderDto, ratesPaperAdderValidationSchema } from 'dtos'
import { Formik, setNestedObjectValues } from 'formik'
import { showFormErrorsPrompt } from '../../components/_template/form/FormErrorsPrompt'
import { useEffect, useState } from 'react'

interface RatesPaperAddersAddEditDialogProps {
  isLoading: boolean
  onClose: () => void
  onSave: (paper: RatesPaperAdderDto) => Promise<void | RatesPaperAdderDto>
  open: boolean
  values: RatesPaperAdderDto
  vendors: CompanyDto[]
}

export default function RatesPaperAddEditDialog({
  isLoading,
  onClose,
  onSave,
  open,
  values,
  vendors: apiVendors
}: RatesPaperAddersAddEditDialogProps) {
  const [vendors, setVendors] = useState<CompanyDto[]>([])

  useEffect(() => {
    if (
      values.company &&
      values.company.id &&
      !apiVendors.some(vendor => vendor.id === values.company!.id)
    ) {
      // The API only returns active vendors. When editing an existing Rates Paper Adder with an inactive vendor, the "Vendor" select was blank.
      // This will add the existing inactive vendor on the Rates Paper Adder to the Vendors array if needed.
      // Maxwell Sommer 8/14/2024
      setVendors([...apiVendors, values.company])
    } else {
      setVendors(apiVendors)
    }
  }, [values, values.company, apiVendors])

  return (
    <Dialog open={open}>
      <Formik
        enableReinitialize
        initialValues={values}
        validateOnBlur
        validateOnChange
        validationSchema={ratesPaperAdderValidationSchema}
        onSubmit={async (values, submitProps) => {
          await onSave(values).finally(() => {
            submitProps.setSubmitting(false)
          })
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setTouched,
          submitForm,
          touched,
          validateForm,
          values
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2} alignItems='center'>
                  <DialogTitle>Add Paper Adders</DialogTitle>

                  <Grid item xs={12}>
                    {/*
                      I could not fix a bug with inactive vendors without specifying the 'key={vendors.length}' attribute here.
                      Maxwell Sommer 8/14/2024
                     */}
                    <FormControl fullWidth key={vendors.length}>
                      <TextField
                        disabled={isLoading || isSubmitting}
                        error={Boolean(touched.company && errors.company)}
                        fullWidth
                        helperText={touched.company && errors.company}
                        label='Vendor'
                        name='name'
                        onBlur={handleBlur}
                        onChange={e => {
                          setFieldValue('company', JSON.parse(e.target.value))
                        }}
                        select
                        value={
                          values?.company
                            ? JSON.stringify(
                                vendors
                                  .filter(vendor => vendor.id === values.company!.id)
                                  .map(vendor => ({
                                    id: vendor.id,
                                    name: vendor.name
                                  }))[0]
                              )
                            : ''
                        }
                      >
                        {vendors
                          ?.sort((a, b) => a.name.localeCompare(b.name))
                          .map(vendor => (
                            <MenuItem
                              value={JSON.stringify({ id: vendor.id, name: vendor.name })}
                              key={vendor.id}
                            >
                              {vendor.name}
                            </MenuItem>
                          ))}
                      </TextField>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={isLoading || isSubmitting}
                      error={Boolean(touched.ect && errors.ect)}
                      fullWidth
                      helperText={touched.ect && errors.ect}
                      label='ECT / Mullen'
                      name='ect'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.ect}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={isLoading || isSubmitting}
                      error={Boolean(touched.adder && errors.adder)}
                      fullWidth
                      helperText={touched.adder && errors.adder}
                      label='Adder'
                      name='adder'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.adder || ''}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={isLoading || isSubmitting}
                      error={Boolean(touched.adderSetup && errors.adderSetup)}
                      fullWidth
                      helperText={touched.adderSetup && errors.adderSetup}
                      label='Adder Setup'
                      name='adderSetup'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.adderSetup}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={isLoading || isSubmitting}
                      error={Boolean(touched.adderPerMsf && errors.adderPerMsf)}
                      fullWidth
                      helperText={touched.adderPerMsf && errors.adderPerMsf}
                      label='Adder Per MSF'
                      name='adderPerMsf'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.adderPerMsf}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.requiresSesameTapeInches}
                          disabled={isLoading || isSubmitting}
                          name='requiresSesameTapeInches'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.requiresSesameTapeInches}
                        />
                      }
                      label='Requires Sesame Tape Inches'
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.isActive}
                          disabled={isLoading || isSubmitting}
                          name='isActive'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.isActive}
                        />
                      }
                      label='Active'
                    />
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions sx={{ mb: 2, mr: 2 }}>
                <Button
                  variant='text'
                  color='secondary'
                  disabled={isLoading || isSubmitting}
                  onClick={onClose}
                >
                  CLOSE
                </Button>

                <Button
                  disabled={isLoading || isSubmitting}
                  onClick={() => {
                    // Due to an issue with Formik, the form fields need to be manually set as touched when there are errors on submit.
                    // Otherwise, sometimes the field errors won't display even though the validation throws them
                    validateForm().then(errors => {
                      const errorKeys = Object.keys(errors)
                      if (errorKeys.length === 0) {
                        submitForm()
                      } else {
                        setTouched(setNestedObjectValues(errors, true))
                        showFormErrorsPrompt(errors)
                      }
                    })
                  }}
                  variant='contained'
                >
                  SAVE
                </Button>
              </DialogActions>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}
