import apiClient from 'api'
import { ApiResponse, RatesBlendAdderDto } from 'dtos'
import { RatesBlendAddersParameters } from 'parameters'
import { URIQuery } from 'utils'

export const getRatesBlendAdders = (parameters: RatesBlendAddersParameters) =>
  apiClient
    .get<ApiResponse<RatesBlendAdderDto[]>>(
      '/api/rates-blend-adders' + URIQuery({ ...parameters })
    )
    .then(({ data }) => data)

export const getRatesBlendAdderById = (id: string) =>
  apiClient
    .get<ApiResponse<RatesBlendAdderDto>>('/api/rates-blend-adders/' + id)
    .then(({ data }) => data)

export const createRatesBlendAdder = (values: RatesBlendAdderDto) =>
  apiClient
    .post<ApiResponse<RatesBlendAdderDto>>('/api/rates-blend-adders', values)
    .then(({ data }) => data)

export const updateRatesBlendAddersList = (values: RatesBlendAdderDto[]) =>
  apiClient
    .put<ApiResponse<boolean>>('/api/rates-blend-adders', values)
    .then(({ data }) => data)
