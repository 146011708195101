import * as api from 'api'
import { Layout } from 'components/_template'
import { Details, DetailsHeaderCard } from 'components/_template/details'
import { useAuthContext } from 'context'
import {
  BoardTypeDto,
  CompanyDto,
  RatesAssemblyAdderDto,
  RatesBlendAdderDto,
  RatesEndCostAdderDto,
  RatesGlobalDto,
  RatesInkDto,
  RatesPaperAdderDto,
  RatesPaperDto,
  RatesScoringAdderDto
} from 'dtos'
import { useSnackbar } from 'notistack'
import {
  RatesAssemblyAddersParameters,
  RatesBlendAddersParameters,
  RatesEndCostAddersParameters,
  RatesInksParameters,
  RatesPaperAddersParameters,
  RatesPaperParameters,
  RatesScoringAddersParameters
} from 'parameters'
import { Ref, useEffect, useRef, useState } from 'react'
import { errorHandling } from 'constantValues'
import RatesAssemblyAddersAddEditDialog from './RatesAssemblyAddersAddEditDialog'
import RatesAssemblyAddersTab from './RatesAssemblyAddersTab'
import RatesBlendAddersAddEditDialog from './RatesBlendAddersAddEditDialog'
import RatesBlendAddersTab from './RatesBlendAddersTab'
import RatesEndCostAddersTab from './RatesEndCostAddersTab'
import RatesGlobalAddEditDialog from './RatesGlobalAddEditDialog'
import RatesGlobalTab from './RatesGlobalTab'
import RatesInksAddEditDialog from './RatesInksAddEditDialog'
import RatesInksTab from './RatesInksTab'
import RatesPaperAddEditDialog from './RatesPaperAddEditDialog'
import RatesPaperAddersAddEditDialog from './RatesPaperAddersAddEditDialog'
import RatesPaperAddersTab from './RatesPaperAddersTab'
import RatesPaperTab from './RatesPaperTab'
import RatesScoringAddersAddEditDialog from './RatesScoringAddersAddEditDialog'
import RatesScoringAddersTab from './RatesScoringAddersTab'
import NonDetailsTabList from 'components/_template/NonDetailsTabList'
import { FormikProps } from 'formik'
import { Button } from '@mui/material'

const ratesAssemblyAddersSessionStorageKey = 'ratesAssemblyAdders:parameters'
const ratesBlendAddersSessionStorageKey = 'ratesBlendAdders:parameters'
const ratesEndCostAddersSessionStorageKey = 'ratesEndCostAdders:parameters'
const ratesInksSessionStorageKey = 'ratesInks:parameters'
const ratesPaperAddersSessionStorageKey = 'ratesPaperAdders:parameters'
const ratesPaperSessionStorageKey = 'ratesPaper:parameters'
const ratesScoringAddersSessionStorageKey = 'ratesScoringAdders:parameters'

export default function RateDetails() {
  // #region hooks
  const { enqueueSnackbar } = useSnackbar()
  const { RATES_READ_ONLY, RATES_ADD_EDIT } = useAuthContext()
  // #endregion

  const ratesAssemblyAddersDefaultParameters: RatesAssemblyAddersParameters = {
    page: 0,
    pageSize: 999,
    includeInactive: false
  }

  const ratesBlendAddersDefaultParameters: RatesBlendAddersParameters = {
    page: 0,
    pageSize: 999,
    includeInactive: false
  }

  const ratesEndCostAddersDefaultParameters: RatesEndCostAddersParameters = {
    page: 0,
    pageSize: 999
  }

  const ratesInksDefaultParameters: RatesInksParameters = {
    page: 0,
    pageSize: 999,
    includeInactive: false
  }

  const ratesPaperAddersDefaultParameters: RatesPaperAddersParameters = {
    page: 0,
    pageSize: 999,
    includeInactive: false
  }

  const ratesPaperDefaultParameters: RatesPaperParameters = {
    page: 0,
    pageSize: 999,
    includeInactive: false
  }

  const ratesScoringAddersDefaultParameters: RatesScoringAddersParameters = {
    page: 0,
    pageSize: 999,
    includeInactive: false
  }

  const ratesAssemblyAddersTabRef: Ref<
    FormikProps<{
      ratesAssemblyAdders: RatesAssemblyAdderDto[]
    }>
  > = useRef(null)

  const ratesBlendAddersTabRef: Ref<
    FormikProps<{
      ratesBlendAdders: RatesBlendAdderDto[]
    }>
  > = useRef(null)

  const ratesInksTabRef: Ref<
    FormikProps<{
      ratesInks: RatesInkDto[]
    }>
  > = useRef(null)

  const ratesPaperAddersTabRef: Ref<
    FormikProps<{
      ratesPaperAdders: RatesPaperAdderDto[]
    }>
  > = useRef(null)

  const ratesPaperTabRef: Ref<
    FormikProps<{
      ratesPapers: RatesPaperDto[]
    }>
  > = useRef(null)

  const ratesScoringAddersTabRef: Ref<
    FormikProps<{
      ratesScoringAdders: RatesScoringAdderDto[]
    }>
  > = useRef(null)

  // #region useState
  const [boardTypesList, setBoardTypes] = useState<BoardTypeDto[]>([])
  // #region LoadingState
  const [isCreatingRatesAssemblyAdders, setIsCreatingRatesAssemblyAdders] =
    useState<boolean>(false)
  const [isCreatingRatesBlendAdders, setIsCreatingRatesBlendAdders] =
    useState<boolean>(false)
  const [isCreatingRatesInks, setIsCreatingRatesInks] = useState<boolean>(false)
  const [isCreatingRatesPapers, setIsCreatingRatesPapers] = useState<boolean>(false)
  const [isCreatingRatesPaperAdders, setIsCreatingRatesPaperAdders] =
    useState<boolean>(false)
  const [isCreatingRatesScoringAdders, setIsCreatingRatesScoringAdders] =
    useState<boolean>(false)
  const [isGettingBoardTypes, setIsGettingBoardTypes] = useState<boolean>(false)
  const [isGettingRatesAssemblyAdders, setIsGettingRatesAssemblyAdders] =
    useState<boolean>(false)
  const [isGettingRatesBlendAdders, setIsGettingRatesBlendAdders] =
    useState<boolean>(false)
  const [isGettingRatesEndCostAdders, setIsGettingRatesEndCostAdders] =
    useState<boolean>(false)
  const [isGettingRatesGlobal, setIsGettingRatesGlobal] = useState<boolean>(false)
  const [isGettingRatesInks, setIsGettingRatesInks] = useState<boolean>(false)
  const [isGettingRatesPapers, setIsGettingRatesPapers] = useState<boolean>(false)
  const [isGettingRatesPaperAdders, setIsGettingRatesPaperAdders] =
    useState<boolean>(false)
  const [isGettingRatesScoringAdders, setIsGettingRatesScoringAdders] =
    useState<boolean>(false)
  const [isGettingVendors, setIsGettingVendors] = useState<boolean>(false)
  const [isUpdatingRatesAssemblyAdders, setIsUpdatingRatesAssemblyAdders] =
    useState<boolean>(false)
  const [isUpdatingRatesBlendAdders, setIsUpdatingRatesBlendAdders] =
    useState<boolean>(false)
  const [isUpdatingRatesGlobal, setIsUpdatingRatesGlobal] = useState<boolean>(false)
  const [isUpdatingRatesInks, setIsUpdatingRatesInks] = useState<boolean>(false)
  const [isUpdatingRatesPapers, setIsUpdatingRatesPapers] = useState<boolean>(false)
  const [isUpdatingRatesPaperAdders, setIsUpdatingRatesPaperAdders] =
    useState<boolean>(false)
  const [isUpdatingRatesScoringAdders, setIsUpdatingRatesScoringAdders] =
    useState<boolean>(false)
  // #endregion

  const [
    isRatesAssemblyAddersAddEditDialogOpen,
    setIsRatesAssemblyAddersAddEditDialogOpen
  ] = useState<boolean>(false)
  const [isRatesAssemblyAddersTabDirty, setIsRatesAssemblyAddersTabDirty] =
    useState<boolean>(false)
  const [ratesAssemblyAddersList, setRatesAssemblyAddersList] = useState<
    RatesAssemblyAdderDto[]
  >([])
  const [ratesAssemblyAddersParameters, setRatesAssemblyAddersParameters] =
    useState<RatesAssemblyAddersParameters>(
      sessionStorage.getItem(ratesAssemblyAddersSessionStorageKey)
        ? JSON.parse(sessionStorage.getItem(ratesAssemblyAddersSessionStorageKey)!)
        : ratesAssemblyAddersDefaultParameters
    )
  const [ratesAssemblyAddersDialogValues, setRatesAssemblyAddersDialogValues] =
    useState<RatesAssemblyAdderDto>(new RatesAssemblyAdderDto())

  const [isRatesBlendAddersAddEditDialogOpen, setIsRatesBlendAddersAddEditDialogOpen] =
    useState<boolean>(false)
  const [isRatesBlendAddersTabDirty, setIsRatesBlendAddersTabDirty] =
    useState<boolean>(false)
  const [ratesBlendAddersList, setRatesBlendAddersList] = useState<RatesBlendAdderDto[]>(
    []
  )
  const [ratesBlendAddersParameters, setRatesBlendAddersParameters] =
    useState<RatesBlendAddersParameters>(
      sessionStorage.getItem(ratesBlendAddersSessionStorageKey)
        ? JSON.parse(sessionStorage.getItem(ratesBlendAddersSessionStorageKey)!)
        : ratesBlendAddersDefaultParameters
    )
  const [ratesBlendAddersDialogValues, setRatesBlendAddersDialogValues] =
    useState<RatesBlendAdderDto>(new RatesBlendAdderDto())

  const [ratesEndCostAddersList, setRatesEndCostAddersList] =
    useState<RatesEndCostAdderDto[]>()
  const [ratesEndCostAddersParameters, setRatesEndCostAddersParameters] =
    useState<RatesEndCostAddersParameters>(
      sessionStorage.getItem(ratesEndCostAddersSessionStorageKey)
        ? JSON.parse(sessionStorage.getItem(ratesEndCostAddersSessionStorageKey)!)
        : ratesEndCostAddersDefaultParameters
    )

  const [isRatesGlobalAddEditDialogOpen, setIsRatesGlobalAddEditDialogOpen] =
    useState<boolean>(false)
  // There is no ratesGlobalList because there is only ever one set of Global data
  const [ratesGlobalValues, setRatesGlobalValues] = useState<RatesGlobalDto>(
    new RatesGlobalDto()
  )

  const [isRatesInksAddEditDialogOpen, setIsRatesInksAddEditDialogOpen] =
    useState<boolean>(false)
  const [isRatesInksTabDirty, setIsRatesInksTabDirty] = useState<boolean>(false)
  const [ratesInksList, setRatesInksList] = useState<RatesInkDto[]>([])
  const [ratesInksParameters, setRatesInksParameters] = useState<RatesInksParameters>(
    sessionStorage.getItem(ratesInksSessionStorageKey)
      ? JSON.parse(sessionStorage.getItem(ratesInksSessionStorageKey)!)
      : ratesInksDefaultParameters
  )
  const [ratesInksDialogValues, setRatesInksDialogValues] = useState<RatesInkDto>(
    new RatesInkDto()
  )

  const [isRatesPaperAddersAddEditDialogOpen, setIsRatesPaperAddersAddEditDialogOpen] =
    useState<boolean>(false)
  const [isRatesPaperAddersTabDirty, setIsRatesPaperAddersTabDirty] =
    useState<boolean>(false)
  const [ratesPaperAddersList, setRatesPaperAddersList] = useState<RatesPaperAdderDto[]>(
    []
  )
  const [ratesPaperAddersParameters, setRatesPaperAddersParameters] =
    useState<RatesPaperAddersParameters>(
      sessionStorage.getItem(ratesPaperAddersSessionStorageKey)
        ? JSON.parse(sessionStorage.getItem(ratesPaperAddersSessionStorageKey)!)
        : ratesPaperAddersDefaultParameters
    )
  const [ratesPaperAddersDialogValues, setRatesPaperAddersDialogValues] =
    useState<RatesPaperAdderDto>(new RatesPaperAdderDto())

  const [isRatesPaperAddEditDialogOpen, setIsRatesPaperAddEditDialogOpen] =
    useState<boolean>(false)
  const [isRatesPapersTabDirty, setIsRatesPapersTabDirty] = useState<boolean>(false)
  const [ratesPaperParameters, setRatesPaperParameters] = useState<RatesPaperParameters>(
    sessionStorage.getItem(ratesPaperSessionStorageKey)
      ? JSON.parse(sessionStorage.getItem(ratesPaperSessionStorageKey)!)
      : ratesPaperDefaultParameters
  )
  const [ratesPapersList, setRatesPapersList] = useState<RatesPaperDto[]>([])
  const [ratesPaperDialogValues, setRatesPaperDialogValues] = useState<RatesPaperDto>(
    new RatesPaperDto()
  )

  const [
    isRatesScoringAddersAddEditDialogOpen,
    setIsRatesScoringAddersAddEditDialogOpen
  ] = useState<boolean>(false)
  const [isRatesScoringAddersTabDirty, setIsRatesScoringAddersTabDirty] =
    useState<boolean>(false)
  const [ratesScoringAddersList, setRatesScoringAddersList] = useState<
    RatesScoringAdderDto[]
  >([])
  const [ratesScoringAddersParameters, setRatesScoringAddersParameters] =
    useState<RatesScoringAddersParameters>(
      sessionStorage.getItem(ratesScoringAddersSessionStorageKey)
        ? JSON.parse(sessionStorage.getItem(ratesScoringAddersSessionStorageKey)!)
        : ratesScoringAddersDefaultParameters
    )
  const [ratesScoringAddersDialogValues, setRatesScoringAddersDialogValues] =
    useState<RatesScoringAdderDto>(new RatesScoringAdderDto())
  const [vendorsList, setVendorsList] = useState<CompanyDto[]>([])
  // #endregion

  const isLoading =
    isCreatingRatesAssemblyAdders ||
    isCreatingRatesBlendAdders ||
    isCreatingRatesInks ||
    isCreatingRatesPapers ||
    isCreatingRatesPaperAdders ||
    isCreatingRatesScoringAdders ||
    isGettingBoardTypes ||
    isGettingRatesAssemblyAdders ||
    isGettingRatesBlendAdders ||
    isGettingRatesEndCostAdders ||
    isGettingRatesGlobal ||
    isGettingRatesInks ||
    isGettingRatesPaperAdders ||
    isGettingRatesPapers ||
    isGettingRatesScoringAdders ||
    isGettingVendors ||
    isUpdatingRatesAssemblyAdders ||
    isUpdatingRatesBlendAdders ||
    isUpdatingRatesGlobal ||
    isUpdatingRatesInks ||
    isUpdatingRatesPapers ||
    isUpdatingRatesPaperAdders ||
    isUpdatingRatesScoringAdders

  const isAnyTabDirty =
    isRatesAssemblyAddersTabDirty ||
    isRatesBlendAddersTabDirty ||
    isRatesInksTabDirty ||
    isRatesPaperAddersTabDirty ||
    isRatesPapersTabDirty ||
    isRatesScoringAddersTabDirty

  const onResetForms = () => {
    ratesAssemblyAddersTabRef.current?.resetForm()
    setIsRatesAssemblyAddersTabDirty(false)
    ratesBlendAddersTabRef.current?.resetForm()
    setIsRatesBlendAddersTabDirty(false)
    ratesInksTabRef.current?.resetForm()
    setIsRatesInksTabDirty(false)
    ratesPaperAddersTabRef.current?.resetForm()
    setIsRatesPaperAddersTabDirty(false)
    ratesPaperTabRef.current?.resetForm()
    setIsRatesPapersTabDirty(false)
    ratesScoringAddersTabRef.current?.resetForm()
    setIsRatesScoringAddersTabDirty(false)
  }

  useEffect(() => {
    getVendors()
    getBoardTypes()
    getRatesEndCostAdders()
    getRatesGlobal()
  }, [])

  useEffect(() => {
    getRatesAssemblyAdders()
  }, [ratesAssemblyAddersParameters])

  useEffect(() => {
    getRatesBlendAdders()
  }, [ratesBlendAddersParameters])

  useEffect(() => {
    getRatesInks()
  }, [ratesInksParameters])

  useEffect(() => {
    getRatesPaperAdders()
  }, [ratesPaperAddersParameters])

  useEffect(() => {
    getRatesPapers()
  }, [ratesPaperParameters])

  useEffect(() => {
    getRatesScoringAdders()
  }, [ratesScoringAddersParameters])

  // #region Methods (Alphabetical by method name)
  // #region Get Methods (Alphabetical by method name)

  const getBoardTypes = () => {
    setIsGettingBoardTypes(true)
    api
      .getBoardTypes()
      .then(({ value }) => {
        setBoardTypes(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsGettingBoardTypes(false)
      })
  }

  const getRatesAssemblyAdders = () => {
    setIsGettingRatesAssemblyAdders(true)
    api
      .getRatesAssemblyAdders(ratesAssemblyAddersParameters)
      .then(({ value }) => {
        setRatesAssemblyAddersList(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsGettingRatesAssemblyAdders(false)
      })
  }

  const getRatesBlendAdders = () => {
    setIsGettingRatesBlendAdders(true)
    api
      .getRatesBlendAdders(ratesBlendAddersParameters)
      .then(({ value }) => {
        setRatesBlendAddersList(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsGettingRatesBlendAdders(false)
      })
  }

  const getRatesEndCostAdders = () => {
    setIsGettingRatesEndCostAdders(true)
    api
      .getRatesEndCostAdders(ratesEndCostAddersParameters)
      .then(({ value }) => {
        setRatesEndCostAddersList(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsGettingRatesEndCostAdders(false)
      })
  }

  const getRatesGlobal = () => {
    setIsGettingRatesGlobal(true)
    api
      .getRatesGlobal()
      .then(({ value }) => {
        setRatesGlobalValues(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsGettingRatesGlobal(false)
      })
  }

  const getRatesInks = () => {
    setIsGettingRatesInks(true)
    api
      .getRatesInks(ratesInksParameters)
      .then(({ value }) => {
        setRatesInksList(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsGettingRatesInks(false)
      })
  }

  const getRatesPaperAdders = () => {
    setIsGettingRatesPaperAdders(true)
    api
      .getRatesPaperAdders(ratesPaperAddersParameters)
      .then(({ value }) => {
        setRatesPaperAddersList(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsGettingRatesPaperAdders(false)
      })
  }

  const getRatesPapers = () => {
    setIsGettingRatesPapers(true)
    api
      .getRatesPaper(ratesPaperParameters)
      .then(({ value }) => {
        setRatesPapersList(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsGettingRatesPapers(false)
      })
  }

  const getRatesScoringAdders = () => {
    setIsGettingRatesScoringAdders(true)
    api
      .getRatesScoringAdders(ratesScoringAddersParameters)
      .then(({ value }) => {
        setRatesScoringAddersList(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsGettingRatesScoringAdders(false)
      })
  }

  const getVendors = () => {
    setIsGettingVendors(true)
    api
      .getCompanyVendorsIncludeInactive()
      .then(({ value }) => {
        setVendorsList(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsGettingVendors(false)
      })
  }
  // #endregion

  // #region Save Methods (Alphabetical by method name)
  const onSaveAssemblyAddersAddEditDialog = async (
    assemblyAdder: RatesAssemblyAdderDto
  ) => {
    setIsCreatingRatesAssemblyAdders(true)
    api
      .createRatesAssemblyAdder(assemblyAdder)
      .then(({ value }) => {
        enqueueSnackbar(`Assembly Adder Has Been Created!`, {
          variant: 'success'
        })

        // Because sorting is primarily handled on the back-end get the whole list again upon saving
        getRatesAssemblyAdders()

        setIsRatesAssemblyAddersAddEditDialogOpen(false)
        return value
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsCreatingRatesAssemblyAdders(false)
      })
  }

  const onSaveBlendAddersAddEditDialog = async (blendAdder: RatesBlendAdderDto) => {
    setIsCreatingRatesBlendAdders(true)
    api
      .createRatesBlendAdder(blendAdder)
      .then(({ value }) => {
        enqueueSnackbar(`Blend Adder Has Been Created!`, {
          variant: 'success'
        })

        // Because sorting is primarily handled on the back-end get the whole list again upon saving
        getRatesBlendAdders()

        setIsRatesBlendAddersAddEditDialogOpen(false)
        return value
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsCreatingRatesBlendAdders(false)
      })
  }

  const onSaveGlobalAddEditDialog = async (global: RatesGlobalDto) => {
    setIsUpdatingRatesGlobal(true)
    api
      .updateRatesGlobal(global)
      .then(({ value }) => {
        enqueueSnackbar(`Global Rates Have Been Updated!`, {
          variant: 'success'
        })

        getRatesGlobal()

        setIsRatesGlobalAddEditDialogOpen(false)
        return value
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsUpdatingRatesGlobal(false)
      })
  }

  const onSaveInksAddEditDialog = async (ink: RatesInkDto) => {
    setIsCreatingRatesInks(true)
    api
      .createRatesInk(ink)
      .then(({ value }) => {
        enqueueSnackbar(`Ink Has Been Created!`, {
          variant: 'success'
        })

        // Because sorting is primarily handled on the back-end get the whole list again upon saving
        getRatesInks()

        setIsRatesInksAddEditDialogOpen(false)
        return value
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsCreatingRatesInks(false)
      })
  }

  const onSavePaperAddEditDialog = async (paper: RatesPaperDto) => {
    setIsCreatingRatesPapers(true)
    api
      .createRatesPaper(paper)
      .then(({ value }) => {
        enqueueSnackbar(`Paper Has Been Created!`, {
          variant: 'success'
        })

        // Because sorting is primarily handled on the back-end get the whole list again upon saving
        getRatesPapers()

        setIsRatesPaperAddEditDialogOpen(false)
        return value
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsCreatingRatesPapers(false)
      })
  }

  const onSavePaperAddersAddEditDialog = async (paperAdder: RatesPaperAdderDto) => {
    setIsCreatingRatesPaperAdders(true)
    api
      .createRatesPaperAdder(paperAdder)
      .then(({ value }) => {
        enqueueSnackbar(`Paper Adder Has Been Created!`, {
          variant: 'success'
        })

        // Because sorting is primarily handled on the back-end get the whole list again upon saving
        getRatesPaperAdders()

        setIsRatesPaperAddersAddEditDialogOpen(false)
        return value
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsCreatingRatesPaperAdders(false)
      })
  }

  const onSaveScoringAddersAddEditDialog = async (scoringAdder: RatesScoringAdderDto) => {
    setIsCreatingRatesScoringAdders(true)
    api
      .createRatesScoringAdder(scoringAdder)
      .then(({ value }) => {
        enqueueSnackbar(`Scoring Adder Has Been Created!`, {
          variant: 'success'
        })

        // Because sorting is primarily handled on the back-end get the whole list again upon saving
        getRatesScoringAdders()

        setIsRatesScoringAddersAddEditDialogOpen(false)
        return value
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsCreatingRatesScoringAdders(false)
      })
  }
  // #endregion
  // #endregion

  return (
    <Layout
      title='Rates'
      // Alphabetize this list of loading properties
      isLoading={isLoading}
    >
      <NonDetailsTabList
        header={<DetailsHeaderCard title='Rates' />}
        onResetForms={onResetForms}
        tabs={[
          { value: 'tab1', label: 'Paper' },
          { value: 'tab2', label: 'Paper Adders' },
          { value: 'tab3', label: 'Blend Adders' },
          { value: 'tab4', label: 'Assembly Adders' },
          { value: 'tab5', label: 'Scoring Adders' },
          { value: 'tab6', label: 'Inks' },
          { value: 'tab7', label: 'End Cost Adders' },
          { value: 'tab8', label: 'Global' }
        ]}
        useUnsavedChangesPromptOnTabChange
        isAnyTabDirty={isAnyTabDirty}
      >
        <RatesPaperAddEditDialog
          boardTypesList={boardTypesList}
          isLoading={isLoading}
          open={isRatesPaperAddEditDialogOpen}
          onClose={() => {
            setIsRatesPaperAddEditDialogOpen(false)
          }}
          onSave={onSavePaperAddEditDialog}
          values={ratesPaperDialogValues}
          vendors={vendorsList}
        />

        <RatesPaperTab
          boardTypesList={boardTypesList}
          getRatesPapers={getRatesPapers}
          isLoading={isLoading}
          RATES_ADD_EDIT={RATES_ADD_EDIT}
          ratesPapersList={ratesPapersList}
          ratesPaperParameters={ratesPaperParameters}
          ratesPapersTabRef={ratesPaperTabRef}
          setIsRatesPapersAddEditDialogOpen={setIsRatesPaperAddEditDialogOpen}
          setIsRatesPapersTabDirty={setIsRatesPapersTabDirty}
          setIsUpdatingRatesPapers={setIsUpdatingRatesPapers}
          setRatesPapersDialogValues={setRatesPaperDialogValues}
          setRatesPaperParameters={setRatesPaperParameters}
          vendorsList={vendorsList}
        />

        <RatesPaperAddersAddEditDialog
          isLoading={isLoading}
          open={isRatesPaperAddersAddEditDialogOpen}
          onClose={() => {
            setIsRatesPaperAddersAddEditDialogOpen(false)
          }}
          onSave={onSavePaperAddersAddEditDialog}
          values={ratesPaperAddersDialogValues}
          vendors={vendorsList}
        />

        <RatesPaperAddersTab
          getRatesPaperAdders={getRatesPaperAdders}
          isLoading={isLoading}
          RATES_ADD_EDIT={RATES_ADD_EDIT}
          ratesPaperAddersList={ratesPaperAddersList}
          ratesPaperAddersParameters={ratesPaperAddersParameters}
          ratesPaperAddersTabRef={ratesPaperAddersTabRef}
          setIsRatesPaperAddersAddEditDialogOpen={setIsRatesPaperAddersAddEditDialogOpen}
          setIsRatesPaperAddersTabDirty={setIsRatesPaperAddersTabDirty}
          setIsUpdatingRatesPaperAdders={setIsUpdatingRatesPaperAdders}
          setRatesPaperAddersDialogValues={setRatesPaperAddersDialogValues}
          setRatesPaperAddersParameters={setRatesPaperAddersParameters}
          vendorsList={vendorsList}
        />

        <RatesBlendAddersAddEditDialog
          isLoading={isLoading}
          open={isRatesBlendAddersAddEditDialogOpen}
          onClose={() => {
            setIsRatesBlendAddersAddEditDialogOpen(false)
          }}
          onSave={onSaveBlendAddersAddEditDialog}
          values={ratesBlendAddersDialogValues}
          vendors={vendorsList}
        />

        <RatesBlendAddersTab
          getRatesBlendAdders={getRatesBlendAdders}
          isLoading={isLoading}
          RATES_ADD_EDIT={RATES_ADD_EDIT}
          ratesBlendAddersList={ratesBlendAddersList}
          ratesBlendAddersParameters={ratesBlendAddersParameters}
          ratesBlendAddersTabRef={ratesBlendAddersTabRef}
          setIsRatesBlendAddersAddEditDialogOpen={setIsRatesBlendAddersAddEditDialogOpen}
          setIsRatesBlendAddersTabDirty={setIsRatesBlendAddersTabDirty}
          setIsUpdatingRatesBlendAdders={setIsUpdatingRatesBlendAdders}
          setRatesBlendAddersDialogValues={setRatesBlendAddersDialogValues}
          setRatesBlendAddersParameters={setRatesBlendAddersParameters}
          vendorsList={vendorsList}
        />

        <RatesAssemblyAddersAddEditDialog
          isLoading={isLoading}
          open={isRatesAssemblyAddersAddEditDialogOpen}
          onClose={() => {
            setIsRatesAssemblyAddersAddEditDialogOpen(false)
          }}
          onSave={onSaveAssemblyAddersAddEditDialog}
          values={ratesAssemblyAddersDialogValues}
          vendors={vendorsList}
        />

        <RatesAssemblyAddersTab
          getRatesAssemblyAdders={getRatesAssemblyAdders}
          isLoading={isLoading}
          RATES_ADD_EDIT={RATES_ADD_EDIT}
          ratesAssemblyAddersList={ratesAssemblyAddersList}
          ratesAssemblyAddersParameters={ratesAssemblyAddersParameters}
          ratesAssemblyAddersTabRef={ratesAssemblyAddersTabRef}
          setIsRatesAssemblyAddersAddEditDialogOpen={
            setIsRatesAssemblyAddersAddEditDialogOpen
          }
          setIsRatesAssemblyAddersTabDirty={setIsRatesAssemblyAddersTabDirty}
          setIsUpdatingRatesAssemblyAdders={setIsUpdatingRatesAssemblyAdders}
          setRatesAssemblyAddersDialogValues={setRatesAssemblyAddersDialogValues}
          setRatesAssemblyAddersParameters={setRatesAssemblyAddersParameters}
          vendorsList={vendorsList}
        />

        <RatesScoringAddersAddEditDialog
          isLoading={isLoading}
          open={isRatesScoringAddersAddEditDialogOpen}
          onClose={() => {
            setIsRatesScoringAddersAddEditDialogOpen(false)
          }}
          onSave={onSaveScoringAddersAddEditDialog}
          values={ratesScoringAddersDialogValues}
          vendors={vendorsList}
        />

        <RatesScoringAddersTab
          getRatesScoringAdders={getRatesScoringAdders}
          isLoading={isLoading}
          RATES_ADD_EDIT={RATES_ADD_EDIT}
          ratesScoringAddersList={ratesScoringAddersList}
          ratesScoringAddersParameters={ratesScoringAddersParameters}
          ratesScoringAddersTabRef={ratesScoringAddersTabRef}
          setIsRatesScoringAddersAddEditDialogOpen={
            setIsRatesScoringAddersAddEditDialogOpen
          }
          setIsRatesScoringAddersTabDirty={setIsRatesScoringAddersTabDirty}
          setIsUpdatingRatesScoringAdders={setIsUpdatingRatesScoringAdders}
          setRatesScoringAddersDialogValues={setRatesScoringAddersDialogValues}
          setRatesScoringAddersParameters={setRatesScoringAddersParameters}
          vendorsList={vendorsList}
        />

        <RatesInksAddEditDialog
          isLoading={isLoading}
          open={isRatesInksAddEditDialogOpen}
          onClose={() => {
            setIsRatesInksAddEditDialogOpen(false)
          }}
          onSave={onSaveInksAddEditDialog}
          values={ratesInksDialogValues}
        />

        <RatesInksTab
          getRatesInks={getRatesInks}
          isLoading={isLoading}
          RATES_ADD_EDIT={RATES_ADD_EDIT}
          ratesInksList={ratesInksList}
          ratesInksParameters={ratesInksParameters}
          ratesInksTabRef={ratesInksTabRef}
          setIsRatesInksAddEditDialogOpen={setIsRatesInksAddEditDialogOpen}
          setIsRatesInksTabDirty={setIsRatesInksTabDirty}
          setIsUpdatingRatesInks={setIsUpdatingRatesInks}
          setRatesInksDialogValues={setRatesInksDialogValues}
          setRatesInksParameters={setRatesInksParameters}
        />

        <RatesEndCostAddersTab ratesEndCostAddersList={ratesEndCostAddersList} />

        <RatesGlobalAddEditDialog
          isLoading={isLoading}
          open={isRatesGlobalAddEditDialogOpen}
          onClose={() => {
            setIsRatesGlobalAddEditDialogOpen(false)
          }}
          onSave={onSaveGlobalAddEditDialog}
          values={ratesGlobalValues}
        />

        <RatesGlobalTab
          RATES_ADD_EDIT={RATES_ADD_EDIT}
          ratesGlobalValues={ratesGlobalValues}
          setIsRatesGlobalAddEditDialogOpen={setIsRatesGlobalAddEditDialogOpen}
        />
      </NonDetailsTabList>
    </Layout>
  )
}
