import { ApiResponse, CompanyDto } from 'dtos'
import { CompaniesParameters } from 'parameters'
import { URIQuery } from 'utils'
import apiClient from '.'

export const getCompanies = (parameters: CompaniesParameters) =>
  apiClient
    .get<ApiResponse<CompanyDto[]>>('/api/companies' + URIQuery({ ...parameters }))
    .then(({ data }) => data)

export const getCompanyTargetCompanies = () =>
  apiClient
    .get<ApiResponse<CompanyDto[]>>('/api/companies/target-companies')
    .then(({ data }) => data)

export const getCompanyShippingCompanies = () =>
  apiClient
    .get<ApiResponse<CompanyDto[]>>('/api/companies/shipping-companies')
    .then(({ data }) => data)

export const getCompanyVendorsActiveOnly = () =>
  apiClient
    .get<ApiResponse<CompanyDto[]>>('/api/companies/vendors/active-only')
    .then(({ data }) => data)

// Including inactive vendors is a separate route here because it's controlled by system logic, not user search parameters
export const getCompanyVendorsIncludeInactive = () =>
  apiClient
    .get<ApiResponse<CompanyDto[]>>('/api/companies/vendors/include-inactive')
    .then(({ data }) => data)

export const getCompanyById = (id: string) =>
  apiClient.get<ApiResponse<CompanyDto>>('/api/companies/' + id).then(({ data }) => data)
