import { ReadMoreOutlined, SearchOutlined } from '@mui/icons-material'
import {
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import * as api from 'api'
import { Layout } from 'components/_template'
import { ExplanationAccordion } from 'components/_template/accordion'
import { List, ListHeaderCard, ListItemCard } from 'components/_template/list'
import { useAuthContext } from 'context'
import { CompanyDto } from 'dtos'
import { CompaniesParameters } from 'parameters'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { format, formats } from 'theme'
import { useDebounce } from 'utils'
import { errorHandling } from 'constantValues'

const sessionStorageKey = 'companies:parameters'

export default function Companies() {
  // #region hooks
  const navigate = useNavigate()
  const { COMPANIES_AND_PEOPLE_ADD_EDIT } = useAuthContext()

  const defaultParameters: CompaniesParameters = {
    page: 0,
    pageSize: 10,
    search: '',
    activeOnly: true
  }

  const getCompanies = useDebounce((parameters: CompaniesParameters) => {
    setIsGettingCompanies(true)
    sessionStorage.setItem(sessionStorageKey, JSON.stringify(parameters))
    api
      .getCompanies(parameters)
      .then(res => {
        setCompanies(res.value)
        setCount(res.totalCount!)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsGettingCompanies(false)
      })
  }, 300)
  // #endregion

  // #region useState
  const [companies, setCompanies] = useState<CompanyDto[]>([])
  const [count, setCount] = useState<number>(0)
  const [isGettingCompanies, setIsGettingCompanies] = useState<boolean>(false)
  const [parameters, setParameters] = useState<CompaniesParameters>(
    sessionStorage.getItem(sessionStorageKey)
      ? JSON.parse(sessionStorage.getItem(sessionStorageKey)!)
      : defaultParameters
  )
  // #endregion

  // #region useEffect
  useEffect(() => {
    getCompanies(parameters)
  }, [parameters])
  // #endregion

  return (
    <Layout title='Companies' isLoading={isGettingCompanies}>
      <List
        pagination={{
          page: parameters.page,
          pageSize: parameters.pageSize,
          count,
          onPageChange: page => setParameters({ ...parameters, page }),
          onPageSizeChange: pageSize => setParameters({ ...parameters, pageSize })
        }}
        header={
          <ListHeaderCard
            title='Companies'
            filters={[
              <TextField
                data-search
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <SearchOutlined fontSize='small' />
                    </InputAdornment>
                  )
                }}
                label='Search'
                name='search'
                onChange={e => setParameters({ ...parameters, search: e.target.value })}
                size='small'
                type='search'
                value={parameters.search}
              />,
              <FormControlLabel
                control={
                  <Checkbox
                    value={parameters.activeOnly}
                    checked={parameters.activeOnly}
                    onChange={e =>
                      setParameters({ ...parameters, activeOnly: e.target.checked })
                    }
                  />
                }
                label='Active only'
              />,
              <Button
                color='primary'
                size='medium'
                variant='text'
                onClick={() => {
                  setParameters(defaultParameters)
                }}
              >
                RESET FILTERS
              </Button>
            ]}
            actions={[
              <Tooltip
                title={
                  COMPANIES_AND_PEOPLE_ADD_EDIT
                    ? 'Add company'
                    : 'Must have "Companies and people add/edit" permission'
                }
                placement='bottom'
              >
                <span>
                  <Button
                    disabled={!COMPANIES_AND_PEOPLE_ADD_EDIT}
                    onClick={() => navigate('/companies/new')}
                    variant='contained'
                    size='medium'
                  >
                    ADD NEW
                  </Button>
                </span>
              </Tooltip>
            ]}
          />
        }
      >
        {companies.map(company => (
          <ListItemCard
            chips={[
              ...(!company.isActive
                ? [<Chip label='Inactive' color='default' variant='outlined' />]
                : []),
              ...(company.isVendor
                ? [<Chip label='Vendor' color='success' variant='outlined' />]
                : [])
            ]}
            key={company.id}
            title={company.name}
            subtitle={company.customerNumber}
            actions={[
              <Tooltip title={`View ${company.name}`}>
                <IconButton
                  onClick={() => navigate('/companies/' + company.id)}
                  color='primary'
                  // size='large'
                >
                  <ReadMoreOutlined fontSize='large' />
                </IconButton>
              </Tooltip>
            ]}
          >
            <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
              {company.website}
            </Typography>
            <Typography variant='body2' sx={{ color: '#7F7F7F', whiteSpace: 'pre-wrap' }}>
              {format(
                company.addresses.find(
                  address => address.addressType?.name === 'Physical'
                ),
                formats.address,
                ''
              )}
            </Typography>
          </ListItemCard>
        ))}
      </List>
      <ExplanationAccordion>
        Search checks if the search term is contained in any of the individual data values
        shown on the list card. It does not enable searching by labels, chips, or special
        characters.
      </ExplanationAccordion>
    </Layout>
  )
}
