import { ReadMoreOutlined, SearchOutlined } from '@mui/icons-material'
import {
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import * as api from 'api'
import { Layout } from 'components/_template'
import { ExplanationAccordion } from 'components/_template/accordion'
import { List, ListHeaderCard, ListItemCard } from 'components/_template/list'
import { useAuthContext } from 'context'
import { ShippingDto } from 'dtos'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { ShippingParameters } from 'parameters'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { formats } from 'theme'
import { formatTime, useDebounce } from 'utils'
import { errorHandling } from 'constantValues'

const sessionStorageKey = 'shipping:parameters'

export default function Shipping() {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { SHIPPING_ADD_EDIT } = useAuthContext()

  const defaultParameters: ShippingParameters = {
    page: 0,
    pageSize: 10,
    search: '',
    includeShipped: false
  }

  const [count, setCount] = useState<number>(0)
  const [isShippingLoading, setIsShippingLoading] = useState<boolean>(false)
  const [parameters, setParameters] = useState<ShippingParameters>(
    sessionStorage.getItem(sessionStorageKey)
      ? JSON.parse(sessionStorage.getItem(sessionStorageKey)!)
      : defaultParameters
  )
  const [shipping, setShipping] = useState<ShippingDto[]>([])

  const getShipping = useDebounce((parameters: ShippingParameters) => {
    setIsShippingLoading(true)
    sessionStorage.setItem(sessionStorageKey, JSON.stringify(parameters))
    api
      .getShipping(parameters)
      .then(res => {
        setShipping(res.value)
        setCount(res.totalCount!)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsShippingLoading(false)
      })
  }, 300)

  useEffect(() => {
    getShipping(parameters)
  }, [parameters])

  return (
    <Layout title='Shipping' isLoading={isShippingLoading}>
      <List
        pagination={{
          page: parameters.page,
          pageSize: parameters.pageSize,
          count,
          onPageChange: page => setParameters({ ...parameters, page }),
          onPageSizeChange: pageSize => setParameters({ ...parameters, pageSize })
        }}
        header={
          <ListHeaderCard
            title='Shipping'
            actions={[
              <Button
                disabled={!SHIPPING_ADD_EDIT}
                onClick={() => {
                  navigate('/shipping/new')
                }}
                variant='contained'
                size='medium'
              >
                ADD NEW
              </Button>
            ]}
          />
        }
      >
        <ListItemCard>
          <Grid container spacing={2} alignItems='center' justifyContent='space-between'>
            <Grid item xs={12} sm={3}>
              <TextField
                data-search
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <SearchOutlined fontSize='small' />
                    </InputAdornment>
                  )
                }}
                label='Search'
                name='search'
                onChange={e => setParameters({ ...parameters, search: e.target.value })}
                size='small'
                type='search'
                value={parameters.search}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <FormControlLabel
                control={<Checkbox />}
                label='Include shipped'
                checked={parameters.includeShipped}
                onChange={e => {
                  setParameters({
                    ...parameters,
                    includeShipped: !parameters.includeShipped
                  })
                }}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Button
                color='primary'
                size='medium'
                variant='text'
                onClick={() => {
                  setParameters(defaultParameters)
                }}
              >
                RESET FILTERS
              </Button>
            </Grid>
          </Grid>
        </ListItemCard>
        {shipping.map(shippingListItem => (
          <ListItemCard
            chips={[
              ...(shippingListItem.shippingJobs !== undefined &&
              shippingListItem.shippingJobs.length !== 0 &&
              !shippingListItem.shippingJobs?.some(shippingJob =>
                shippingJob.shippingItems?.some(
                  shippingItem =>
                    shippingItem.finishedGoodsInventory?.forkliftPalletLocation
                      ?.specialIdentifier != 'TRUCK'
                )
              )
                ? [<Chip label='Shipped' color='primary' variant='outlined' />]
                : [])
            ]}
            key={shippingListItem.id}
            title={
              moment(shippingListItem.scheduleDate).format(formats.date) +
              ' - ' +
              formatTime(shippingListItem.scheduleTime!) +
              ' - ' +
              shippingListItem.company?.name
            }
            actions={[
              <Tooltip title={'Details'}>
                <IconButton
                  onClick={() => navigate(`/shipping/${shippingListItem.id}`)}
                  color='primary'
                >
                  <ReadMoreOutlined fontSize='large' />
                </IconButton>
              </Tooltip>
            ]}
          >
            {shippingListItem?.shippingJobs?.map((shippingJob, index) => (
              <Typography
                variant='body2'
                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                key={index}
              >
                {'Job#: ' +
                  shippingJob.job?.jobNumber +
                  ' - ' +
                  shippingJob.bundlesToShip +
                  ' Bundles'}
              </Typography>
            ))}
          </ListItemCard>
        ))}

        <Grid item xs={12}>
          <ExplanationAccordion>
            Search checks if the search term is contained in any of the individual data
            values shown on the list card. To search by a date or time, ensure the date or
            time is formatted as shown on the list card. It does not enable searching by
            labels, chips, or special characters.||A "Shipped" chip shows for shipments
            that have already been shipped.||Cards are listed in order of scheduled date /
            time for shipments that have not been shipped, then shipments that have been
            shipped by company name then Job #(s).||Include shipped is not checked by
            default.
          </ExplanationAccordion>
        </Grid>
      </List>
    </Layout>
  )
}
