import { LoadingButton } from '@mui/lab'
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  TextField,
  Typography
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import Grid from '@mui/material/Grid'
import apiClient, * as api from 'api'
import { IntFormat } from 'components'
import { Layout } from 'components/_template'
import { ExplanationAccordion } from 'components/_template/accordion'
import {
  Details,
  DetailsActions,
  DetailsForm,
  DetailsHeaderCard,
  DetailsTab
} from 'components/_template/details'
import { showFormErrorsPrompt } from 'components/_template/form/FormErrorsPrompt'
import { AddressDto, CompanyDto, ShippingDto, shippingValidationSchema } from 'dtos'
import { Formik, getIn, setNestedObjectValues } from 'formik'
import { enqueueSnackbar } from 'notistack'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { useNavigate, useParams } from 'react-router-dom'
import { format } from 'theme'
import { errorHandling } from 'constantValues'
import ReportBillOfLading from './ReportBillOfLading'
import { useDebounce } from 'utils'

export default function ShippingDetails() {
  const navigate = useNavigate()
  const [companies, setCompanies] = useState<CompanyDto[]>([])
  const [company, setCompany] = useState<CompanyDto>(new CompanyDto())
  const [initialValues, setInitialValues] = useState<ShippingDto>(new ShippingDto())
  const [isGettingBillOfLadingReport, setIsGettingBillOfLadingReport] =
    useState<boolean>(false)
  const [isGettingCompanies, setIsGettingCompanies] = useState<boolean>(false)
  const [isGettingCompany, setIsGettingCompany] = useState<boolean>(false)
  const [isGettingShipping, setIsGettingShipping] = useState<boolean>(false)
  const [isSavingShippingDetails, setIsSavingShippingDetails] = useState<boolean>(false)
  const { id } = useParams()

  const isLoading =
    isGettingBillOfLadingReport ||
    isGettingCompanies ||
    isGettingCompany ||
    isGettingShipping ||
    isSavingShippingDetails

  useEffect(() => {
    getCompanyShippingCompanies()
  }, [])

  useEffect(() => {
    if (id && id.toLowerCase() !== 'new' && id.toLowerCase() !== 'undefined') {
      getShipping(id)
    } else {
      setInitialValues(new ShippingDto())
    }
  }, [id])

  const getShipping = useDebounce((id: string) => {
    setIsGettingShipping(true)
    api
      .getShippingById(id)
      .then(response => {
        setInitialValues(response.value)
        setCompany(response.value.company ?? new CompanyDto())
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsGettingShipping(false)
      })
  }, 300)

  const getCompanyShippingCompanies = useDebounce(() => {
    setIsGettingCompanies(true)
    api
      .getCompanyShippingCompanies()
      .then(({ value }) => {
        setCompanies(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsGettingCompanies(false)
      })
  }, 300)

  const getCompany = useDebounce((id: string) => {
    setIsGettingCompany(true)
    api
      .getCompanyById(id ?? '')
      .then(response => {
        setCompany(response.value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsGettingCompany(false)
      })
  }, 300)

  const openReportBillOfLadingWindow = () => {
    setIsGettingBillOfLadingReport(true)
    api
      .getBillOfLadingReportByShippingId(initialValues.id!)
      .then(res => {
        const newWindow = window.open('', '_blank', 'width=800,height=600')
        if (newWindow) {
          newWindow.document.body.innerHTML =
            '<div id="bill-of-lading-report-container"></div>'
          const root = createRoot(
            newWindow.document.getElementById(
              'bill-of-lading-report-container'
            ) as HTMLElement
          )

          root.render(<ReportBillOfLading reportBillOfLadingDto={res.value} />)
        } else {
          console.error('Failed to open a new window.')
        }
      })
      .finally(() => {
        setIsGettingBillOfLadingReport(false)
      })
  }

  return (
    <Layout title='Shipping Details' isLoading={isLoading}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validateOnBlur
        validateOnChange
        validationSchema={shippingValidationSchema}
        onSubmit={async (values, submitProps) => {
          setIsSavingShippingDetails(true)
          await (values.id ? apiClient.put : apiClient.post)('/api/shipping', values)
            .then(res => {
              enqueueSnackbar('Shipping Details Saved Successfully!', {
                variant: 'success'
              })

              setInitialValues(res.data.value)
              submitProps.resetForm({ values: res.data.value })
            })
            .catch((errors: string[]) => {
              errorHandling(errors)
            })
            .finally(() => {
              setIsSavingShippingDetails(false)
            })
        }}
      >
        {({
          dirty,
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setTouched,
          submitForm,
          touched,
          validateForm,
          values
        }) => {
          const clearWhenDirty = (value: any) => {
            return dirty ? 'TBD' : value
          }

          const clearLaterSections = (section: 'jobDetails' | 'bundles') => {
            if (section === 'jobDetails') {
              setFieldValue('shippingJobs', [])
            }

            if (section === 'jobDetails' || 'bundles') {
              setFieldValue('trailerNumber', undefined)
              setFieldValue('door', undefined)
              setFieldValue('sealNumber', undefined)
            }
          }

          const isSectionDisabled = (section: 'bundles' | 'loading' | 'billOfLading') => {
            // If values have been changed (I.e. values is not equal to initialValues,) OR if there
            // are missing values, disable the section.
            if (section === 'bundles') {
              return (
                initialValues.company?.id !== values.company?.id ||
                initialValues.scheduleDate !== values.scheduleDate ||
                initialValues.scheduleTime !== values.scheduleTime ||
                initialValues.carrier !== values.carrier ||
                initialValues.movementNumber !== values.movementNumber ||
                !values.company ||
                !values.scheduleDate ||
                !values.scheduleTime ||
                !values.carrier ||
                !values.movementNumber
              )
            }

            if (section === 'loading') {
              return (
                initialValues.shippingJobs?.length !== values.shippingJobs?.length ||
                values.shippingJobs?.length === 0 ||
                !values.shippingJobs?.some(
                  shippingJob =>
                    shippingJob.bundlesToShip !== 0 ||
                    shippingJob.bundlesToShip.toString() !== '0'
                )
              )
            }

            if (section === 'billOfLading') {
              return (
                initialValues.trailerNumber !== values.trailerNumber ||
                initialValues.door !== values.door ||
                initialValues.sealNumber !== values.sealNumber ||
                !values.trailerNumber ||
                !values.door ||
                !values.sealNumber
              )
            }
          }

          return (
            <Details
              header={<DetailsHeaderCard title='Shipping Details' />}
              tabs={[{ value: 'tab1', label: 'Details' }]}
              onSubmit={e => e.preventDefault()}
            >
              <DetailsTab value='tab1'>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <DetailsForm>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          container
                          alignItems='center'
                          justifyContent='space-between'
                        >
                          <Grid item xs={12}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#2780E3', fontWeight: 600 }}
                            >
                              Job Details
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <Typography
                            variant='body1'
                            sx={{ color: '#7F7F7F', fontWeight: 600 }}
                          >
                            BOL #: {values.displayBillOfLadingNumber}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <Typography
                            variant='body1'
                            sx={{ color: '#7F7F7F', fontWeight: 600 }}
                          >
                            Total Bundles To Ship:{' '}
                            {clearWhenDirty(values.totalBundles ?? 'TBD')}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <Typography
                            variant='body1'
                            sx={{ color: '#7F7F7F', fontWeight: 600 }}
                          >
                            Total Weight: {clearWhenDirty(values.totalWeight ?? 'TBD')}
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <Divider />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Autocomplete
                            disabled={isSubmitting}
                            disableListWrap
                            getOptionLabel={option => option.name}
                            isOptionEqualToValue={(option, value) => {
                              return option.id === value.id
                            }}
                            onChange={(_e, value) => {
                              setFieldValue('shippingAddress', null)
                              setFieldValue('company', value)
                              getCompany(value?.id)
                              clearLaterSections('jobDetails')
                            }}
                            options={companies}
                            renderInput={params => (
                              <TextField
                                {...params}
                                label='Customer'
                                error={Boolean(touched.company && errors.company)}
                                helperText={touched.company && errors.company}
                              />
                            )}
                            // The Autocomplete is only blank when the value is null, NOT undefined, so this must be "values.company ?? null"
                            value={values.company ?? null}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            disabled={isSubmitting}
                            error={Boolean(touched.scheduleDate && errors.scheduleDate)}
                            fullWidth
                            helperText={touched.scheduleDate && errors.scheduleDate}
                            InputLabelProps={{ shrink: true }}
                            label='Service Date'
                            name='scheduleDate'
                            onBlur={handleBlur}
                            onChange={e => {
                              handleChange(e)
                              clearLaterSections('jobDetails')
                            }}
                            type='date'
                            value={values.scheduleDate}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            disabled={isSubmitting}
                            error={Boolean(touched.scheduleTime && errors.scheduleTime)}
                            fullWidth
                            helperText={touched.scheduleTime && errors.scheduleTime}
                            InputLabelProps={{ shrink: true }}
                            label='Service Time'
                            name='scheduleTime'
                            onBlur={handleBlur}
                            onChange={e => {
                              handleChange(e)
                              clearLaterSections('jobDetails')
                            }}
                            type='time'
                            value={values.scheduleTime}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            disabled={isSubmitting}
                            error={Boolean(touched.carrier && errors.carrier)}
                            fullWidth
                            helperText={touched.carrier && errors.carrier}
                            label='Carrier'
                            name='carrier'
                            onBlur={handleBlur}
                            onChange={e => {
                              handleChange(e)
                              clearLaterSections('jobDetails')
                            }}
                            value={values.carrier || ''}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            disabled={isSubmitting}
                            error={Boolean(
                              touched.movementNumber && errors.movementNumber
                            )}
                            fullWidth
                            helperText={touched.movementNumber && errors.movementNumber}
                            label='Movement #'
                            name='movementNumber'
                            onBlur={handleBlur}
                            onChange={e => {
                              handleChange(e)
                              clearLaterSections('jobDetails')
                            }}
                            value={values.movementNumber || ''}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Autocomplete
                            disabled={isSubmitting}
                            disableListWrap
                            getOptionLabel={option => {
                              return (
                                option.address1 +
                                (option.address2 ? ', ' + option.address2 : '') +
                                ', ' +
                                option.city +
                                ', ' +
                                option.state +
                                option.postalCode
                              )
                            }}
                            isOptionEqualToValue={(option, value) => {
                              return option.id === value.id
                            }}
                            onChange={(_e, value) => {
                              setFieldValue('shippingAddress', value)
                              clearLaterSections('jobDetails')
                            }}
                            options={
                              company?.addresses?.filter(
                                address => address.addressType?.name == 'Shipping'
                              ) ?? []
                            }
                            renderInput={params => (
                              <TextField
                                {...params}
                                label='Shipping Address'
                                error={Boolean(
                                  touched.shippingAddress && errors.shippingAddress
                                )}
                                helperText={
                                  touched.shippingAddress && errors.shippingAddress
                                }
                              />
                            )}
                            // The Autocomplete is only blank when the value is null, NOT undefined, so this must be "values.shippingAddress ?? null"
                            value={values.shippingAddress ?? null}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.isThirdPartyFreightBilling}
                                name='isThirdPartyFreightBilling'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.isThirdPartyFreightBilling}
                                size='small'
                              />
                            }
                            label={'Third Party Freight Billing'}
                          />
                        </Grid>

                        {values.isThirdPartyFreightBilling ? (
                          <Grid item xs={12}>
                            <TextField
                              disabled={isSubmitting}
                              error={Boolean(
                                touched.thirdPartyFreightBillingAddress &&
                                  errors.thirdPartyFreightBillingAddress
                              )}
                              fullWidth
                              helperText={
                                touched.thirdPartyFreightBillingAddress &&
                                errors.thirdPartyFreightBillingAddress
                              }
                              label='Third Party Freight Bill To Address'
                              name='thirdPartyFreightBillingAddress'
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.thirdPartyFreightBillingAddress || ''}
                              maxRows={4}
                              minRows={4}
                              multiline
                            />
                          </Grid>
                        ) : null}

                        <Grid item xs={12}>
                          <TextField
                            disabled={isSubmitting}
                            error={Boolean(
                              touched.specialInstructions && errors.specialInstructions
                            )}
                            fullWidth
                            helperText={
                              touched.specialInstructions && errors.specialInstructions
                            }
                            label='Special Instructions'
                            name='specialInstructions'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.specialInstructions || ''}
                            maxRows={4}
                            minRows={4}
                            multiline
                          />
                        </Grid>
                      </Grid>
                    </DetailsForm>
                  </Grid>

                  <Grid item xs={12}>
                    <DetailsForm>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          container
                          alignItems='center'
                          justifyContent='space-between'
                        >
                          <Grid item xs={12}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#2780E3', fontWeight: 600 }}
                            >
                              Bundles
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          <Typography
                            variant='body1'
                            sx={{ color: '#7F7F7F', fontWeight: 600 }}
                          >
                            Job #
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          <Typography
                            variant='body1'
                            sx={{ color: '#7F7F7F', fontWeight: 600 }}
                          >
                            Available Bundles
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          <Typography
                            variant='body1'
                            sx={{ color: '#7F7F7F', fontWeight: 600 }}
                          >
                            Bundles on Truck
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          <Typography
                            variant='body1'
                            sx={{ color: '#7F7F7F', fontWeight: 600 }}
                          >
                            Bundles to Ship
                          </Typography>
                        </Grid>

                        {values.shippingJobs?.map((shippingJob, index) => (
                          <React.Fragment key={index}>
                            <Grid item xs={12} sm={3}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', mt: '16px' }}
                              >
                                {shippingJob.job?.jobNumber}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', mt: '16px' }}
                              >
                                {/* Available Bundles only counts bundles that do not have a forklift pallet location of Truck that are not on another Shipping Details Bundles section */}
                                {format(
                                  shippingJob.job?.finishedGoodsInventory?.filter(
                                    finishedGoodsInventory =>
                                      finishedGoodsInventory.forkliftPalletLocation
                                        ?.specialIdentifier !== 'TRUCK' &&
                                      (finishedGoodsInventory.shippingItem == null ||
                                        shippingJob.shippingItems?.some(
                                          shippingItem =>
                                            shippingItem.id ==
                                            finishedGoodsInventory.shippingItem?.id
                                        ))
                                  ).length
                                )}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', mt: '16px' }}
                              >
                                {/* Bundles on Truck only counts bundles that have a forklift pallet location of Truck that are not on another Shipping Details Bundles section */}
                                {format(
                                  shippingJob.shippingItems?.filter(
                                    shippingItem =>
                                      shippingItem.finishedGoodsInventory
                                        ?.forkliftPalletLocation?.specialIdentifier ==
                                      'TRUCK'
                                  ).length
                                )}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <TextField
                                disabled={isSubmitting || isSectionDisabled('bundles')}
                                error={Boolean(
                                  getIn(
                                    touched,
                                    `shippingJobs[${index}].bundlesToShip`
                                  ) &&
                                    getIn(errors, `shippingJobs[${index}].bundlesToShip`)
                                )}
                                fullWidth
                                helperText={
                                  getIn(
                                    touched,
                                    `shippingJobs[${index}].bundlesToShip`
                                  ) &&
                                  getIn(errors, `shippingJobs[${index}].bundlesToShip`)
                                }
                                label='Bundles to Ship'
                                name={`shippingJobs[${index}].bundlesToShip`}
                                size='small'
                                margin='dense'
                                onBlur={handleBlur}
                                onChange={e => {
                                  clearLaterSections('bundles')
                                  handleChange(e)
                                }}
                                InputProps={{ inputComponent: IntFormat as any }}
                                value={values.shippingJobs![index].bundlesToShip || 0}
                              />
                            </Grid>
                          </React.Fragment>
                        ))}
                      </Grid>
                    </DetailsForm>
                  </Grid>

                  <Grid item xs={12}>
                    <DetailsForm>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          container
                          alignItems='center'
                          justifyContent='space-between'
                        >
                          <Grid item xs={12}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#2780E3', fontWeight: 600 }}
                            >
                              Loading
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            disabled={isSubmitting || isSectionDisabled('loading')}
                            error={Boolean(touched.trailerNumber && errors.trailerNumber)}
                            fullWidth
                            helperText={touched.trailerNumber && errors.trailerNumber}
                            label='Trailer #'
                            name='trailerNumber'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.trailerNumber || ''}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            disabled={isSubmitting || isSectionDisabled('loading')}
                            error={Boolean(touched.door && errors.door)}
                            fullWidth
                            helperText={touched.door && errors.door}
                            label='Door'
                            name='door'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.door || ''}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            disabled={isSubmitting || isSectionDisabled('loading')}
                            error={Boolean(touched.sealNumber && errors.sealNumber)}
                            fullWidth
                            helperText={touched.sealNumber && errors.sealNumber}
                            label='Seal #'
                            name='sealNumber'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.sealNumber || ''}
                          />
                        </Grid>
                      </Grid>
                    </DetailsForm>
                  </Grid>

                  <Grid item xs={12}>
                    <DetailsForm>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          container
                          alignItems='center'
                          justifyContent='space-between'
                        >
                          <Grid item xs={12}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#2780E3', fontWeight: 600 }}
                            >
                              Bill of Lading
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Button
                            disabled={isSubmitting || isSectionDisabled('billOfLading')}
                            variant='outlined'
                            onClick={openReportBillOfLadingWindow}
                          >
                            Print Bill of Lading
                          </Button>
                        </Grid>
                      </Grid>
                    </DetailsForm>
                  </Grid>

                  <Grid item xs={12}>
                    <ExplanationAccordion>
                      The Shipping Details form has four sections, which are filled out
                      separately, in order.||The first section, "Job Details", contains
                      the Customer, Service Date, Service Time, Carrier, Movement #,
                      Shipping Address, Special Instructions, and optional Third Party
                      Freight Billing fields. All fields in this section are required,
                      except Third Party Freight Billing and Special Instructions.||The
                      Shipping Address field will allow selecting any shipping address on
                      the selected customer's Company Details page. If no shipping address
                      is set "No Options" will display and an address must be created on
                      the Company Details page to save.||The second section, "Bundles",
                      lists all open jobs for the selected customer where there are
                      bundles in finished goods or staging that are not already scheduled
                      for another shipment. It contains a Quantity to Ship field for each
                      job. All Quantity to Ship fields default to 0.||The third section,
                      "Loading", contains the Trailer # field and the Seal # field.||The
                      fourth section, "Bill of Lading", contains the Print Bill of Lading
                      button.||Users must enter in the Job Details section and then save
                      which enables the Bundles section.||Users must enter in the Bundles
                      section and then save which enables the Loading section.||Users must
                      enter in the Loading section and then save which enables the Print
                      Bill of Lading section.||In a future phase, users will be prevented
                      from changing Customer on Shipments. Users should not change
                      customers on shipments after a shipment has been created.||After the
                      Bundles section is filled in and the form is saved, weight is
                      calculated for the selected bundles as (Finished Good Weight Per
                      Piece (From Finished Goods Inventory Details) x Quantity to Ship)
                      for each job and a Forklift To Do is created for each bundle to be
                      put in staging.||After the Trailer # field is filled in and the form
                      is saved, any remaining Forklift To Dos for the bundles to be put in
                      staging are removed and a Forklift To Do is created for each bundle
                      to be put on the truck.||The user can save the Trailer # field
                      without the Seal # field filled in, but the Seal # field must be
                      filled in to enable the Print Bill of Lading section.||Clicking the
                      Print Bill of Lading button generates a PDF. The user can open the
                      PDF to print if needed.||The Total Weight value here is the sum of
                      weights for each job, where each Job/Item # is calculated as
                      Finished Good Weight Per Piece (from Finished Goods Inventory
                      Details) x Finished Good Unit Quantity Per Bundle (from Quote
                      Details) x Bundles to Ship (from the Bundles card here, including
                      bundles already on the truck).||When the Bill of Lading PDF is
                      generated, the Weight value for each Item # on the PDF is calculated
                      individually.||The Total Weight value on the Bill of Lading PDF is a
                      copy of the Total Weight value here.
                    </ExplanationAccordion>
                  </Grid>
                </Grid>
              </DetailsTab>

              <DetailsActions>
                <Button
                  color='secondary'
                  onClick={() => {
                    navigate('/shipping')
                  }}
                  variant='text'
                >
                  BACK
                </Button>

                <LoadingButton
                  color='primary'
                  onClick={() => {
                    // Due to an issue with Formik, the form fields need to be manually set as touched when there are errors on submit.
                    // Otherwise, sometimes the field errors won't display even though the validation throws them
                    validateForm().then(errors => {
                      const errorKeys = Object.keys(errors)
                      if (errorKeys.length === 0) {
                        submitForm()
                      } else {
                        setTouched(setNestedObjectValues(errors, true))
                        showFormErrorsPrompt(errors)
                      }
                    })
                  }}
                  variant='contained'
                >
                  SAVE
                </LoadingButton>
              </DetailsActions>
            </Details>
          )
        }}
      </Formik>
    </Layout>
  )
}
