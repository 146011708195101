import { ReadMoreOutlined, SearchOutlined } from '@mui/icons-material'
import {
  Avatar,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import apiClient from 'api'
import { Layout } from 'components/_template'
import { ExplanationAccordion } from 'components/_template/accordion'
import { List, ListHeaderCard, ListItemCard } from 'components/_template/list'
import { useAuthContext } from 'context'
import { PersonDto } from 'dtos'
import { PeopleParameters } from 'parameters'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { URIQuery, useDebounce } from 'utils'
import { errorHandling } from 'constantValues'

const sessionStorageKey = 'people:parameters'

function stringToColor(string: string) {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name)
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
  }
}

export default function People() {
  // #region hooks
  const navigate = useNavigate()
  const { COMPANIES_AND_PEOPLE_ADD_EDIT } = useAuthContext()

  const defaultParameters: PeopleParameters = {
    page: 0,
    pageSize: 10,
    search: '',
    activeOnly: true
  }

  const getPeople = useDebounce((parameters: PeopleParameters) => {
    setIsGettingPeople(true)
    sessionStorage.setItem(sessionStorageKey, JSON.stringify(parameters))
    apiClient
      .get('/api/people' + URIQuery(parameters))
      .then(res => {
        setPeople(res.data.value)
        setCount(res.data.totalCount)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsGettingPeople(false)
      })
  }, 300)
  // #endregion

  // #region useState
  const [count, setCount] = useState<number>(0)
  const [isGettingPeople, setIsGettingPeople] = useState<boolean>(false)
  const [parameters, setParameters] = useState<PeopleParameters>(
    sessionStorage.getItem(sessionStorageKey)
      ? JSON.parse(sessionStorage.getItem(sessionStorageKey)!)
      : defaultParameters
  )
  const [people, setPeople] = useState<PersonDto[]>([])
  // #endregion

  // #region useEffect
  useEffect(() => {
    getPeople(parameters)
  }, [parameters])
  // #endregion

  return (
    <Layout title='People' isLoading={isGettingPeople}>
      <List
        pagination={{
          page: parameters.page,
          pageSize: parameters.pageSize,
          count,
          onPageChange: page => setParameters({ ...parameters, page }),
          onPageSizeChange: pageSize => setParameters({ ...parameters, pageSize })
        }}
        header={
          <ListHeaderCard
            title='People'
            filters={[
              <TextField
                data-search
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <SearchOutlined fontSize='small' />
                    </InputAdornment>
                  )
                }}
                label='Search'
                name='search'
                onChange={e => setParameters({ ...parameters, search: e.target.value })}
                size='small'
                type='search'
                value={parameters.search}
              />,
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked
                    value={parameters.activeOnly}
                    checked={parameters.activeOnly}
                    onChange={e =>
                      setParameters({ ...parameters, activeOnly: e.target.checked })
                    }
                  />
                }
                label='Active only'
              />,
              <Button
                color='primary'
                size='medium'
                variant='text'
                onClick={() => {
                  setParameters(defaultParameters)
                }}
              >
                RESET FILTERS
              </Button>
            ]}
            actions={[
              <Tooltip
                title={
                  COMPANIES_AND_PEOPLE_ADD_EDIT
                    ? 'Add person'
                    : 'Must have "Companies and people add/edit" permission'
                }
                placement='bottom'
              >
                <span>
                  <Button
                    disabled={!COMPANIES_AND_PEOPLE_ADD_EDIT}
                    onClick={() => navigate('/people/new')}
                    variant='contained'
                    size='medium'
                  >
                    ADD NEW
                  </Button>
                </span>
              </Tooltip>
            ]}
          />
        }
      >
        {people.map(person => (
          <ListItemCard
            chips={[
              ...(!person.isActive
                ? [<Chip label='Inactive' color='default' variant='outlined' />]
                : []),
              ...(person.isUser
                ? [<Chip label='User' color='primary' variant='outlined' />]
                : [])
            ]}
            avatar={
              <Avatar
                {...stringAvatar(person.firstName.trim() + ' ' + person.lastName.trim())}
              />
            }
            title={person.firstName + ' ' + person.lastName}
            key={person.id}
            subtitle={`${person.company?.name ?? ''}${
              person.company?.name && person.title ? ' - ' : ''
            }${person.title ?? ''}`}
            actions={[
              <Tooltip title={`View ${person.firstName} ${person.lastName}`}>
                <IconButton
                  onClick={() => navigate('/people/' + person.id)}
                  color='primary'
                  // size='large'
                >
                  <ReadMoreOutlined fontSize='large' />
                </IconButton>
              </Tooltip>
            ]}
          >
            {person.mobilePhone && (
              <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
                Mobile: {person.mobilePhone}
              </Typography>
            )}

            {person.workPhone && (
              <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
                Work: {person.workPhone}
              </Typography>
            )}

            <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
              {person.email}
            </Typography>
          </ListItemCard>
        ))}
      </List>
      <ExplanationAccordion>
        Search checks if the search term is contained in any of the individual data values
        shown on the list card. It does not enable searching by labels, chips, or special
        characters.
      </ExplanationAccordion>
    </Layout>
  )
}
