import { Button, Card, CardContent, Tooltip, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import { ExplanationAccordion } from 'components/_template/accordion'
import { DetailsTab } from 'components/_template/details'
import { RatesGlobalDto } from 'dtos'
import { format, formats } from 'theme'

interface RatesGlobalProps {
  RATES_ADD_EDIT: boolean
  ratesGlobalValues: RatesGlobalDto
  setIsRatesGlobalAddEditDialogOpen: (isRatesGlobalAddEditDialogOpen: boolean) => void
}

export default function RatesGlobalTab({
  RATES_ADD_EDIT,
  ratesGlobalValues,
  setIsRatesGlobalAddEditDialogOpen
}: RatesGlobalProps) {
  return (
    <DetailsTab value='tab8'>
      <Grid item xs={12} container alignItems='center' justifyContent='space-between'>
        <Grid item xs={12} sm={6}>
          <Typography variant='h6' sx={{ color: '#7F7F7F' }}></Typography>
        </Grid>

        <Grid item>
          <Tooltip
            title={RATES_ADD_EDIT ? 'Edit' : 'Must have "Rates add/edit" permission'}
            placement='bottom'
          >
            <Button
              variant='outlined'
              disabled={!RATES_ADD_EDIT}
              onClick={() => {
                setIsRatesGlobalAddEditDialogOpen(true)
              }}
              sx={{ mb: 2 }}
            >
              Edit Global
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card variant='outlined'>
            <CardContent>
              <Typography variant='body1' sx={{ color: '#2780E3', fontWeight: 600 }}>
                Ink Cost
              </Typography>
              <Grid container spacing={2} alignItems='center'>
                <Grid item xs={12} sm={6}>
                  <Typography>
                    Setup Cost:{' '}
                    {format(ratesGlobalValues?.inkCostSetupCost, formats.currency)}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography>Value: {ratesGlobalValues?.inkCostValue}</Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card variant='outlined'>
            <CardContent>
              <Typography variant='body1' sx={{ color: '#2780E3', fontWeight: 600 }}>
                86RDC
              </Typography>
              <Grid container spacing={2} alignItems='center'>
                <Grid item xs={12} sm={4}>
                  <Typography>
                    Piece Per Hour: {ratesGlobalValues?.jobCenter86RdcPiecePerHour}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography>
                    Cost Per Hour:{' '}
                    {format(
                      ratesGlobalValues?.jobCenter86RdcCostPerHour,
                      formats.currency
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography>
                    Setup Cost:{' '}
                    {format(ratesGlobalValues?.jobCenter86RdcSetupCost, formats.currency)}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card variant='outlined'>
            <CardContent>
              <Typography variant='body1' sx={{ color: '#2780E3', fontWeight: 600 }}>
                66RDC
              </Typography>
              <Grid container spacing={2} alignItems='center'>
                <Grid item xs={12} sm={4}>
                  <Typography>
                    Piece Per Hour: {ratesGlobalValues?.jobCenter66RdcPiecePerHour}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography>
                    Cost Per Hour:{' '}
                    {format(
                      ratesGlobalValues?.jobCenter66RdcCostPerHour,
                      formats.currency
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography>
                    Setup Cost:{' '}
                    {format(ratesGlobalValues?.jobCenter66RdcSetupCost, formats.currency)}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card variant='outlined'>
            <CardContent>
              <Typography variant='body1' sx={{ color: '#2780E3', fontWeight: 600 }}>
                Sesame Tape
              </Typography>

              <Grid container spacing={2} alignItems='center'>
                <Grid item xs={12} sm={6}>
                  <Typography>
                    Coastal Container Sesame Tape 1 Side Cost Per Inch:{' '}
                    {format(
                      ratesGlobalValues?.coastalContainerSesameTape1SideCostPerInch,
                      formats.currencyFourDecimalPlaces
                    )}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography>
                    Coastal Container Sesame Tape 2 Side Cost Per Inch:{' '}
                    {format(
                      ratesGlobalValues?.coastalContainerSesameTape2SideCostPerInch,
                      formats.currencyFourDecimalPlaces
                    )}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography>
                    Georgia Pacific Sesame Tape 1 Side Cost Per Inch:{' '}
                    {format(
                      ratesGlobalValues?.georgiaPacificSesameTape1SideCostPerInch,
                      formats.currencyFourDecimalPlaces
                    )}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography>
                    Georgia Pacific Sesame Tape 2 Side Cost Per Inch:{' '}
                    {format(
                      ratesGlobalValues?.georgiaPacificSesameTape2SideCostPerInch,
                      formats.currencyFourDecimalPlaces
                    )}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography>
                    Viking 1 Sesame Tape 1 Side Cost Per Inch:{' '}
                    {format(
                      ratesGlobalValues?.viking1SesameTape1SideCostPerInch,
                      formats.currencyFourDecimalPlaces
                    )}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography>
                    Viking 1 Sesame Tape 2 Side Cost Per Inch:{' '}
                    {format(
                      ratesGlobalValues?.viking1SesameTape2SideCostPerInch,
                      formats.currencyFourDecimalPlaces
                    )}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography>
                    Viking 2 Sesame Tape 1 Side Cost Per Inch:{' '}
                    {format(
                      ratesGlobalValues?.viking2SesameTape1SideCostPerInch,
                      formats.currencyFourDecimalPlaces
                    )}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography>
                    Viking 2 Sesame Tape 2 Side Cost Per Inch:{' '}
                    {format(
                      ratesGlobalValues?.viking2SesameTape2SideCostPerInch,
                      formats.currencyFourDecimalPlaces
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card variant='outlined'>
            <CardContent>
              <Typography variant='body1' sx={{ color: '#2780E3', fontWeight: 600 }}>
                Stitcher
              </Typography>
              <Grid container spacing={2} alignItems='center'>
                <Grid item xs={12} sm={4}>
                  <Typography>
                    Piece Per Hour: {ratesGlobalValues?.stitcherPiecePerHour}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography>
                    Piece Per Hour:{' '}
                    {format(ratesGlobalValues?.stitcherCostPerHour, formats.currency)}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography>
                    Setup Cost:{' '}
                    {format(ratesGlobalValues?.stitcherSetupCost, formats.currency)}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card variant='outlined'>
            <CardContent>
              <Typography variant='body1' sx={{ color: '#2780E3', fontWeight: 600 }}>
                Bander
              </Typography>
              <Grid container spacing={2} alignItems='center'>
                <Grid item xs={12} sm={4}>
                  <Typography>
                    Cost: {format(ratesGlobalValues?.banderCost, formats.currency)}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card variant='outlined'>
            <CardContent>
              <Typography variant='body1' sx={{ color: '#2780E3', fontWeight: 600 }}>
                Unitized Cost
              </Typography>
              <Grid container spacing={2} alignItems='center'>
                <Grid item xs={12} sm={4}>
                  <Typography>
                    Bale: {format(ratesGlobalValues?.unitizedCostBale, formats.currency)}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography>
                    Small Wood Pallet:{' '}
                    {format(
                      ratesGlobalValues?.unitizedCostSmallWoodPallet,
                      formats.currency
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography>
                    Large Wood Pallet:{' '}
                    {format(
                      ratesGlobalValues?.unitizedCostLargeWoodPallet,
                      formats.currency
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography>
                    Cores:{' '}
                    {format(ratesGlobalValues?.unitizedCostCores, formats.currency)}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card variant='outlined'>
            <CardContent>
              <Typography variant='body1' sx={{ color: '#2780E3', fontWeight: 600 }}>
                Waste %
              </Typography>
              <Grid container spacing={2} alignItems='center'>
                <Grid item xs={12} sm={4}>
                  <Typography>
                    Percentage:{' '}
                    {format(ratesGlobalValues?.wastePercentage, formats.percent)}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card variant='outlined'>
            <CardContent>
              <Typography variant='body1' sx={{ color: '#2780E3', fontWeight: 600 }}>
                Material Handling
              </Typography>
              <Grid container spacing={2} alignItems='center'>
                <Grid item xs={12} sm={4}>
                  <Typography>
                    Cost:{' '}
                    {format(ratesGlobalValues?.materialHandlingCost, formats.currency)}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card variant='outlined'>
            <CardContent>
              <Typography variant='body1' sx={{ color: '#2780E3', fontWeight: 600 }}>
                Warehousing
              </Typography>
              <Grid container spacing={2} alignItems='center'>
                <Grid item xs={12} sm={4}>
                  <Typography>
                    Cost: {format(ratesGlobalValues?.warehousingCost, formats.currency)}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card variant='outlined'>
            <CardContent>
              <Typography variant='body1' sx={{ color: '#2780E3', fontWeight: 600 }}>
                SGA %
              </Typography>
              <Grid container spacing={2} alignItems='center'>
                <Grid item xs={12} sm={4}>
                  <Typography>
                    Percentage: {format(ratesGlobalValues?.sgaPercent, formats.percent)}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <ExplanationAccordion>
            || The dollar amount entered for "Bander" cost is a flat fee per Finished Good
            bundle added to the quote on the Variable Costs card. || For percentage fields
            enter in 25 for 25%, not 0.25. || Global fields are hard coded, only the
            values can be changed (no additional items may be added without code updates).
            || Stitcher is a piece of plant machinery that will not exist until a future
            project, but we are storing these values now. || Ink cost is calculated
            without considering ink color, cost per pound (weight), etc. "setup cost" is
            based on time needed per Job center to load and prepare the print plates and
            inks. "value" is based on an average cost of ink and ink consumption per job.
          </ExplanationAccordion>
        </Grid>
      </Grid>
    </DetailsTab>
  )
}
