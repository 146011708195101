import { LoadingButton } from '@mui/lab'
import { Button, Divider, TextField, Typography } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import * as api from 'api'
import RawInventoryReconciliationDialog from 'components/RawInventoryReconciliationDialog'
import RawInventoryScrapDialog from 'components/RawInventoryScrapDialog'
import { Layout } from 'components/_template'
import { ExplanationAccordion } from 'components/_template/accordion'
import {
  Details,
  DetailsActions,
  DetailsForm,
  DetailsHeaderCard,
  DetailsTab
} from 'components/_template/details'
import { showFormErrorsPrompt } from 'components/_template/form/FormErrorsPrompt'
import { useAuthContext } from 'context'
import {
  FinishedGoodItemTypeDto,
  RawInventoryDto,
  VendorOrderDto,
  rawInventoryValidationSchema
} from 'dtos'
import { Formik, setNestedObjectValues } from 'formik'
import { enqueueSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { format, formats } from 'theme'
import { errorHandling } from 'constantValues'

export default function RawInventoryDetails() {
  const navigate = useNavigate()
  const { id } = useParams()
  const { CAN_RECONCILE_RAW_INVENTORY, CAN_SCRAP_RAW_INVENTORY } = useAuthContext()

  const [initialValues, setInitialValues] = useState<VendorOrderDto>(new VendorOrderDto())
  const [isFinishedGoodItemTypesLoading, setIsFinishedGoodItemTypesLoading] =
    useState<boolean>(false)
  const [isRawInventoryLoading, setIsRawInventoryLoading] = useState<boolean>(false)
  const [finishedGoodItemTypesList, setFinishedGoodItemTypesList] = useState<
    FinishedGoodItemTypeDto[]
  >([])
  const [rawInventory, setRawInventory] = useState<RawInventoryDto>(new RawInventoryDto())
  const [rawInventoryReconciliationDialogOpen, setRawInventoryReconciliationDialogOpen] =
    useState<boolean>(false)
  const [rawInventoryScrapDialogOpen, setRawInventoryScrapDialogOpen] =
    useState<boolean>(false)

  useEffect(() => {
    getFinishedGoodItemTypes()
  }, [])

  useEffect(() => {
    if (id && id.toLowerCase() !== 'new' && id.toLowerCase() !== 'undefined') {
      getRawInventory(id)
    }
  }, [id])

  const getFinishedGoodItemTypes = () => {
    setIsFinishedGoodItemTypesLoading(true)
    api
      .getFinishedGoodItemTypes()
      .then(({ value }) => {
        setFinishedGoodItemTypesList(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsFinishedGoodItemTypesLoading(false)
      })
  }

  const getRawInventory = (vendorOrderId: string) => {
    setIsRawInventoryLoading(true)
    api
      .getRawInventoryByVendorOrderId(vendorOrderId)
      .then(({ value }) => {
        // This form uses the VendorOrderDto because it only modifies metadata present on the vendor order, not the RawInventoryDto
        setInitialValues(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsRawInventoryLoading(false)
      })
  }

  return (
    <Layout
      title='Raw Inventory Details'
      isLoading={isFinishedGoodItemTypesLoading || isRawInventoryLoading}
    >
      <RawInventoryScrapDialog
        rawInventory={rawInventory}
        vendorOrder={initialValues}
        open={rawInventoryScrapDialogOpen}
        onClose={() => {
          setRawInventory(new RawInventoryDto())
          setRawInventoryScrapDialogOpen(false)
        }}
        onSave={rawInventoryAdjustment => {
          setIsRawInventoryLoading(true)
          return api
            .createRawInventoryScrapAdjustment(rawInventoryAdjustment)
            .then(({ value }) => {
              enqueueSnackbar(`Raw Inventory Scrap Added Successfully!`, {
                variant: 'success'
              })
              getRawInventory(id ?? '')
            })
            .catch((errors: string[]) => {
              errorHandling(errors)
            })
            .finally(() => {
              setRawInventory(new RawInventoryDto())
              setIsRawInventoryLoading(false)
              setRawInventoryScrapDialogOpen(false)
            })
        }}
      />
      <RawInventoryReconciliationDialog
        rawInventory={rawInventory}
        vendorOrder={initialValues}
        open={rawInventoryReconciliationDialogOpen}
        onClose={() => {
          setRawInventory(new RawInventoryDto())
          setRawInventoryReconciliationDialogOpen(false)
        }}
        onSave={rawInventoryAdjustment => {
          setIsRawInventoryLoading(true)
          return api
            .createRawInventoryReconciliationAdjustment(rawInventoryAdjustment)
            .then(({ value }) => {
              enqueueSnackbar(`Raw Inventory Reconciliation Added Successfully!`, {
                variant: 'success'
              })
              getRawInventory(id ?? '')
            })
            .catch((errors: string[]) => {
              errorHandling(errors)
            })
            .finally(() => {
              setRawInventory(new RawInventoryDto())
              setIsRawInventoryLoading(false)
              setRawInventoryReconciliationDialogOpen(false)
            })
        }}
      />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validateOnBlur
        validateOnChange
        validationSchema={rawInventoryValidationSchema}
        onSubmit={async (values, submitProps) => {
          await api
            .updateRawInventory(values)
            .then(({ value }) => {
              enqueueSnackbar('Raw Inventory Has Been Saved!', {
                variant: 'success'
              })

              // This form uses the VendorOrderDto because it only modifies metadata present on the vendor order, not the RawInventoryDto
              setInitialValues(value)
            })
            .catch((errors: string[]) => {
              errorHandling(errors)
            })
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setTouched,
          submitForm,
          touched,
          validateForm,
          values
        }) => {
          return (
            <Details
              header={<DetailsHeaderCard title='Raw Inventory Details ' />}
              tabs={[
                { value: 'tab1', label: 'Details' },
                { value: 'tab2', label: 'Pallets' }
              ]}
              onSubmit={e => e.preventDefault()}
            >
              <DetailsTab value='tab1'>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <DetailsForm>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          container
                          alignItems='center'
                          justifyContent='space-between'
                        >
                          <Grid item xs={12} sm={6}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#2780E3', fontWeight: 600 }}
                            >
                              Sheet
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Vendor item #: {format(values?.vendorItemNumber)}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            disabled={isSubmitting}
                            error={Boolean(
                              touched.sheetItemDescription && errors.sheetItemDescription
                            )}
                            fullWidth
                            helperText={
                              touched.sheetItemDescription && errors.sheetItemDescription
                            }
                            label='Item Description'
                            name='sheetItemDescription'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.sheetItemDescription || ''}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            disabled={isSubmitting}
                            error={Boolean(
                              touched.sheetGenericDescription &&
                                errors.sheetGenericDescription
                            )}
                            fullWidth
                            helperText={
                              touched.sheetGenericDescription &&
                              errors.sheetGenericDescription
                            }
                            label='Generic Description'
                            name='sheetGenericDescription'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.sheetGenericDescription || ''}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth>
                            <TextField
                              disabled={isSubmitting}
                              error={Boolean(
                                touched.sheetItemType && errors.sheetItemType
                              )}
                              fullWidth
                              helperText={touched.sheetItemType && errors.sheetItemType}
                              label='Item Type'
                              name='sheetItemType'
                              onBlur={handleBlur}
                              onChange={e => {
                                setFieldValue('sheetItemType', JSON.parse(e.target.value))
                              }}
                              select
                              value={
                                values?.sheetItemType
                                  ? JSON.stringify(
                                      finishedGoodItemTypesList.filter(
                                        finishedGoodItemType =>
                                          finishedGoodItemType?.id ===
                                          values?.sheetItemType?.id
                                      )[0]
                                    )
                                  : ''
                              }
                            >
                              {finishedGoodItemTypesList.map(finishedGoodItemType => (
                                <MenuItem
                                  value={JSON.stringify(finishedGoodItemType)}
                                  key={finishedGoodItemType?.id ?? ''}
                                >
                                  {finishedGoodItemType?.name ?? ''}
                                </MenuItem>
                              ))}
                            </TextField>
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            In stock:{' '}
                            {format(
                              values?.rawInventory
                                ?.filter(
                                  rawInventoryItem =>
                                    rawInventoryItem.forkliftPalletLocation
                                      ?.specialIdentifier !== 'JOB_CENTER_86RDC' &&
                                    rawInventoryItem.forkliftPalletLocation
                                      ?.specialIdentifier !== 'JOB_CENTER_66RDC'
                                )
                                .reduce(
                                  (accumulator, currentValue) =>
                                    accumulator + currentValue.quantityReceived!,
                                  0
                                )
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </DetailsForm>
                  </Grid>

                  <Grid item xs={12}>
                    <ExplanationAccordion>
                      Updating this form updates these values for the associated vendor
                      order, not individual Raw Inventory items.
                    </ExplanationAccordion>
                  </Grid>
                </Grid>
              </DetailsTab>

              <DetailsTab value='tab2'>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <DetailsForm>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          container
                          alignItems='center'
                          justifyContent='space-between'
                        >
                          <Grid item xs={12}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#2780E3', fontWeight: 600 }}
                            >
                              In Stock
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                          <Grid item xs={12} sm={1}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#7F7F7F', fontWeight: 600 }}
                            >
                              Pallet #
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={1}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#7F7F7F', fontWeight: 600 }}
                            >
                              Quantity
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#7F7F7F', fontWeight: 600 }}
                            >
                              Received Date
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#7F7F7F', fontWeight: 600 }}
                            >
                              Location
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={4} />
                        </Grid>
                        {values.rawInventory
                          ?.filter(
                            rawInventoryItem =>
                              rawInventoryItem.forkliftPalletLocation
                                ?.specialIdentifier !== 'JOB_CENTER_86RDC' &&
                              rawInventoryItem.forkliftPalletLocation
                                ?.specialIdentifier !== 'JOB_CENTER_66RDC'
                          )
                          .map((rawInventoryItem, index) => (
                            <Grid container item xs={12}>
                              <Grid item xs={12} sm={1}>
                                <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                  {rawInventoryItem.palletNumber}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={1}>
                                <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                  {rawInventoryItem.quantityReceived}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                  {format(
                                    rawInventoryItem.createdDateTimeUtc,
                                    formats.dateTime
                                  )}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                  {rawInventoryItem.forkliftPalletLocation?.description}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={2}>
                                <Button
                                  variant='outlined'
                                  onClick={() => {
                                    setRawInventory(rawInventoryItem)
                                    setRawInventoryReconciliationDialogOpen(true)
                                  }}
                                  disabled={
                                    !CAN_RECONCILE_RAW_INVENTORY ||
                                    values.vendorOrderStatus?.specialIdentifier ===
                                      'RECEIVED_IN_FULL'
                                  }
                                >
                                  Reconcile
                                </Button>
                              </Grid>
                              <Grid item xs={12} sm={2}>
                                <Button
                                  variant='outlined'
                                  onClick={() => {
                                    setRawInventory(rawInventoryItem)
                                    setRawInventoryScrapDialogOpen(true)
                                  }}
                                  disabled={
                                    !CAN_SCRAP_RAW_INVENTORY ||
                                    values.vendorOrderStatus?.specialIdentifier ===
                                      'RECEIVED_IN_FULL'
                                  }
                                >
                                  Scrap
                                </Button>
                              </Grid>
                            </Grid>
                          ))}
                      </Grid>
                    </DetailsForm>
                  </Grid>

                  <Grid item xs={12}>
                    <DetailsForm>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          container
                          alignItems='center'
                          justifyContent='space-between'
                        >
                          <Grid item xs={12} sm={6}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#2780E3', fontWeight: 600 }}
                            >
                              At Job Center
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                          <Grid item xs={12} sm={1}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#7F7F7F', fontWeight: 600 }}
                            >
                              Pallet #
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={1}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#7F7F7F', fontWeight: 600 }}
                            >
                              Quantity
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#7F7F7F', fontWeight: 600 }}
                            >
                              Received Date
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#7F7F7F', fontWeight: 600 }}
                            >
                              Location
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={4} />
                        </Grid>
                        {values.rawInventory
                          ?.filter(
                            rawInventoryItem =>
                              rawInventoryItem.forkliftPalletLocation
                                ?.specialIdentifier == 'JOB_CENTER_86RDC' ||
                              rawInventoryItem.forkliftPalletLocation
                                ?.specialIdentifier == 'JOB_CENTER_66RDC'
                          )
                          .map((rawInventoryItem, index) => (
                            <Grid container item xs={12}>
                              <Grid item xs={12} sm={1}>
                                <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                  {rawInventoryItem.palletNumber}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={1}>
                                <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                  {rawInventoryItem.quantityReceived}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                  {format(
                                    rawInventoryItem.createdDateTimeUtc,
                                    formats.dateTime
                                  )}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                  {rawInventoryItem.forkliftPalletLocation?.description}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={4} />
                            </Grid>
                          ))}
                      </Grid>
                    </DetailsForm>
                  </Grid>

                  <Grid item xs={12}>
                    <ExplanationAccordion>
                      All pallets associated with this vendor order are listed here.||Raw
                      Inventory pallets cannot be reconciled or scrapped after the vendor
                      order is Ready To Schedule.
                    </ExplanationAccordion>
                  </Grid>
                </Grid>
              </DetailsTab>
              <DetailsActions>
                <Button
                  color='secondary'
                  onClick={() => {
                    navigate('/finished-goods-inventory')
                  }}
                  variant='text'
                >
                  BACK
                </Button>

                <LoadingButton
                  color='primary'
                  onClick={() => {
                    // Due to an issue with Formik, the form fields need to be manually set as touched when there are errors on submit.
                    // Otherwise, sometimes the field errors won't display even though the validation throws them
                    validateForm().then(errors => {
                      const errorKeys = Object.keys(errors)
                      if (errorKeys.length === 0) {
                        submitForm()
                      } else {
                        setTouched(setNestedObjectValues(errors, true))
                        showFormErrorsPrompt(errors)
                      }
                    })
                  }}
                  variant='contained'
                >
                  SAVE
                </LoadingButton>
              </DetailsActions>
            </Details>
          )
        }}
      </Formik>
    </Layout>
  )
}
