import { ApiResponse, RatesGlobalDto } from 'dtos'
import { RatesGlobalParameters } from 'parameters'
import { URIQuery } from 'utils'
import apiClient from '.'

export const getRatesGlobal = () =>
  apiClient.get<ApiResponse<RatesGlobalDto>>('/api/rates-global').then(({ data }) => data)

export const createRatesGlobal = (values: RatesGlobalDto) =>
  apiClient
    .post<ApiResponse<RatesGlobalDto>>('/api/rates-global', values)
    .then(({ data }) => data)

export const updateRatesGlobal = (values: RatesGlobalDto) =>
  apiClient
    .put<ApiResponse<RatesGlobalDto>>('/api/rates-global', values)
    .then(({ data }) => data)

export const getJobCenter86RdcPiecesPerHour = () =>
  apiClient
    .get<ApiResponse<number>>('/api/rates-global/job-center-86rdc-pieces-per-hour')
    .then(({ data }) => data)

export const getJobCenter66RdcPiecesPerHour = () =>
  apiClient
    .get<ApiResponse<number>>('/api/rates-global/job-center-66rdc-pieces-per-hour')
    .then(({ data }) => data)
