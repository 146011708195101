import * as yup from 'yup'
import {
  fieldTypeMessage,
  maxTextFieldMessage,
  requiredFieldMessage
} from 'constantValues'

export class SignInDto {
  emailOrUsername: string = ''
  passwordOrPin: string = ''
}

export const signInValidationSchema = yup.object({
  emailOrUsername: yup
    .string()
    .max(320, maxTextFieldMessage(320))
    .required(requiredFieldMessage),
  passwordOrPin: yup
    .string()
    .max(128, maxTextFieldMessage(128))
    .required(requiredFieldMessage)
})
