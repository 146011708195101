import { ApiResponse, RatesScoringAdderDto } from 'dtos'
import { RatesScoringAddersParameters } from 'parameters'
import { URIQuery } from 'utils'
import apiClient from '.'

export const getRatesScoringAdders = (parameters: RatesScoringAddersParameters) =>
  apiClient
    .get<ApiResponse<RatesScoringAdderDto[]>>(
      '/api/rates-scoring-adders' + URIQuery({ ...parameters })
    )
    .then(({ data }) => data)

export const getRatesScoringAdderById = (id: string) =>
  apiClient
    .get<ApiResponse<RatesScoringAdderDto>>('/api/rates-scoring-adders/' + id)
    .then(({ data }) => data)

export const createRatesScoringAdder = (values: RatesScoringAdderDto) =>
  apiClient
    .post<ApiResponse<RatesScoringAdderDto>>('/api/rates-scoring-adders', values)
    .then(({ data }) => data)

export const updateRatesScoringAddersList = (values: RatesScoringAdderDto[]) =>
  apiClient
    .put<ApiResponse<boolean>>('/api/rates-scoring-adders', values)
    .then(({ data }) => data)
